import { checkPermission } from "../utilities/commonUtilities";
import { module_submodule_permission,permission_key_values_patient,
    permission_key_values_tasks,permission_key_values_claim,
    permission_key_values_payments,permission_key_values_messages,
    permission_key_values_accountsetup, permission_key_values_auditLogs, customer_admin_privilege, super_admin_privileges, pms_staff_user_privilege, permission_key_values_practice_dictionaries, super_admin_permission_key_value, permission_key_values_practice_user_management, permission_key_values_super_Admin,
    permission_key_values_batch_status
} from "../utilities/permissions";

export default function checkPermissionMiddleware(pathname) {
    let permissionKey;
    
    switch (pathname) {
        case '/dashboard':
            permissionKey = [module_submodule_permission?.dashboard_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            pms_staff_user_privilege,
            permission_key_values_super_Admin.dasboard_admin_view];
            break;
        case '/change-password':
            permissionKey = [];
            break;
        case '/dictionaries':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view,
            permission_key_values_super_Admin?.settings_admin_view];
            break;
        case '/dictionaries/policy-type':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/payer-type':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/cpt-code':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.code_management_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
                pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/cpt-code':
            permissionKey = [
                permission_key_values_accountsetup.account_setup_code_management_add,
                permission_key_values_accountsetup.account_setup_code_management_modify,
                permission_key_values_accountsetup.account_setup_code_management_view,
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/icd10':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.code_management_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
            pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/icd10':
            permissionKey = [
                permission_key_values_accountsetup.account_setup_code_management_view,
                permission_key_values_accountsetup.account_setup_code_management_add,
                permission_key_values_accountsetup.account_setup_code_management_modify,
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/pos-codes':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/modifiers':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/revenue-code':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/remittance-remark-code':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.code_management_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
                pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/remittance-remark-code':
            permissionKey = [
                permission_key_values_accountsetup.account_setup_code_management_view,
                permission_key_values_accountsetup.account_setup_code_management_add,
                permission_key_values_accountsetup.account_setup_code_management_modify,
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/adjustment-reason-code':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view,
                module_submodule_permission?.code_management_sub_module_view,
                super_admin_privileges.super_admin_full_privilege,
                pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/adjustment-reason-code':
            permissionKey = [
                permission_key_values_accountsetup.account_setup_code_management_view,
                permission_key_values_accountsetup.account_setup_code_management_add,
                permission_key_values_accountsetup.account_setup_code_management_modify,
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add
            ];
            break;
        case '/user/custom-fee-schedule-management':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/financial-class':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.code_management_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
                pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/financial-class':
            permissionKey = [
                permission_key_values_accountsetup.account_setup_code_management_add,
                permission_key_values_accountsetup.account_setup_code_management_modify,
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add,
                permission_key_values_accountsetup.account_setup_code_management_view
            ]
            break;
        case '/dictionaries/payer-class':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.code_management_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
            pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/payer-class':
            permissionKey = [permission_key_values_accountsetup.account_setup_code_management_add,
            permission_key_values_accountsetup.account_setup_code_management_modify,
            permission_key_values_accountsetup.account_setup_code_management_view,
            super_admin_privileges.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/delay-reason-code':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/payment-adjustment-type':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/assignment-benefits':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/accident-types':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/claim-frequency':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/claim-status':
            permissionKey = [module_submodule_permission?.practice_dictionaries_sub_module_view,
            module_submodule_permission?.admin_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/claim-status':
            permissionKey = [permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/claim-sub-status':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/claim-sub-status':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/ar-action-code':
            permissionKey = [module_submodule_permission?.practice_dictionaries_sub_module_view,
            module_submodule_permission?.admin_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/ar-action-code':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/ar-status':
            permissionKey = [module_submodule_permission?.practice_dictionaries_sub_module_view,
            module_submodule_permission?.admin_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/ar-status':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/documentation-method':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/document-type':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/document-type':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/service-exception':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/service-exception':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/trasport-reason':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/tracking-status-type':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/tracking-status-source':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/service-types':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/taxonomies':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/appointment-status':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/appointment-status':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/appointment-type':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/appointment-type':
            permissionKey = [permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries?.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/patient-language':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
            pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/patient-language':
            permissionKey = [permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, super_admin_permission_key_value.dictionary_add,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/relationship':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/referral-source':
            permissionKey = [module_submodule_permission?.admin_dictionaries_sub_module_view,
            module_submodule_permission?.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege];
            break;
        case '/practice-dictionaries/referral-source':
            permissionKey = [permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
            module_submodule_permission.practice_dictionaries_sub_module_view,
            super_admin_privileges.super_admin_full_privilege,
            super_admin_permission_key_value.dictionary_add];
            break;
        case '/dictionaries/provider-group':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/practice-types':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/facility-types':
            permissionKey =  [module_submodule_permission?.admin_dictionaries_sub_module_view];
            break;
        case '/dictionaries/referring-provider-group':
            permissionKey = [module_submodule_permission?.practice_dictionaries_sub_module_view];
            break;
        case '/practice-dictionaries':
            permissionKey = [module_submodule_permission?.practice_dictionaries_sub_module_view];
            break;
        case '/user/code-management':
            permissionKey =  [module_submodule_permission?.code_management_sub_module_view];
            break;
        case '/patient/patients':
            permissionKey =  [permission_key_values_patient?.patient_list_sub_module_view];
            break;
        case '/patient/run-eligibility':
            permissionKey =  [module_submodule_permission?.patient_run_eligibility_sub_module_view];
            break;
        case '/patient/statement-management':
            permissionKey =  [module_submodule_permission?.patients_statement_management_sub_module_view];
            break;
        case '/patient/payment-plan':
            permissionKey =  [module_submodule_permission?.payment_plans_sub_module_view];
            break;
        case '/patient/ledger-list':
            permissionKey =  [module_submodule_permission?.patient_ledger_sub_module_view];
            break;  
        case '/patient/ledger':
            permissionKey =  [permission_key_values_patient?.patient_ledger_sub_module_modify,
            module_submodule_permission.patient_ledger_sub_module_view];
            break;
        case '/claims/search-claims':
            permissionKey =  [permission_key_values_claim?.claims_search_claims_view];
            break;
        case '/claims/claims-filter':
            permissionKey =  [permission_key_values_claim?.claims_claim_filter_view];
            break;
        case '/claims/ar-claims':
            permissionKey =  [permission_key_values_claim?.ar_claims_sub_module_view];
            break;
        case '/payment/post_payments':
            permissionKey =  [permission_key_values_payments?.post_payments];
            break;   
        case '/claims/ar-paper-claims':
            permissionKey =  [permission_key_values_claim?.claims_paper_claims_view];
            break;  
        case '/payment/view_payments':
            permissionKey =  [module_submodule_permission?.payments_module_view];
            break;   
        case '/era_posting':
            permissionKey =  [permission_key_values_payments?.era_posting];
            break;
        case '/compose-messages':
            permissionKey =  [module_submodule_permission?.compose_message_sub_module_add];
            break; 
        case '/inbox-list':
            permissionKey =  [module_submodule_permission?.inbox_message_sub_module_view];
            break; 
        case '/send-inbox-messages-list':
            permissionKey =  [permission_key_values_messages?.sent_message_sub_module_view];
            break; 
        case '/send-inbox-messages':
            permissionKey =  [permission_key_values_messages?.sent_message_sub_module_view];
            break; 
        case '/draft-inbox-messages-list':
            permissionKey =  [permission_key_values_messages?.draft_message_sub_module_view];
            break;     
        case '/templates-list':
            permissionKey =  [module_submodule_permission?.message_template_sub_module_view];
            break;
        case '/add-template':
            permissionKey =  [permission_key_values_messages?.can_add_templates_messages];
            break;     
            
        case '/my-tasks/list':
            permissionKey =  [module_submodule_permission?.task_module_view];
            break; 
        case '/my-tasks/add':
            permissionKey =  [permission_key_values_tasks?.task_add];
            break; 
        case '/reports/chargedebit':
            permissionKey =  [module_submodule_permission?.charges_and_debit_sub_module_view];
            break;
        case '/reports/paymentadjustment':
            permissionKey =  [module_submodule_permission?.payments_and_adjustments_sub_module_view];
            break;
        case '/reports/openarreport':
            permissionKey =  [module_submodule_permission?.open_ar_sub_module_view];
            break;
        case '/user/practices':
            permissionKey =  [module_submodule_permission?.practice_management_sub_module_view, customer_admin_privilege];
            break;
        case '/user/practices-edit':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_practice_management_modify,customer_admin_privilege];
            break;
        case '/user/practices-add':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_practice_management_add,customer_admin_privilege];
            break;
        case '/user/provider-management':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_provider_management_add,
            permission_key_values_auditLogs?.can_view_providerManagement];
            break;
        case '/user/provider-edit':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_provider_management_modify];
            break;    
        case '/user/provider-add':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_provider_management_add];
            break;
        case '/user/referring-provider':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_referring_provider_add,
            permission_key_values_auditLogs?.can_view_referringProvider];
            break;  
        case '/user/referring-provider-add':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_referring_provider_add];
            break; 
        case '/user/referring-provider-edit':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_referring_provider_modify];
            break; 
        case '/user/facilitiesList':
            permissionKey =  [permission_key_values_auditLogs?.can_view_facility];
            break;
        case '/user/facilities-add':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_facility_management_add];
            break;
        case '/user/facilities-edit':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_facility_management_modify];
            break;
        case '/user/insurance-companies':
            permissionKey = [permission_key_values_accountsetup?.account_setup_insurance_management_view, customer_admin_privilege];
            break;
        case '/user/insurance-companies-add':
            permissionKey = [permission_key_values_accountsetup?.account_setup_insurance_management_add, customer_admin_privilege];
            break;
        case '/user/insurance-companies-edit':
            permissionKey = [permission_key_values_accountsetup?.account_setup_insurance_management_modify, customer_admin_privilege];
            break;
        case '/dictionaries/insurance-companies':
            permissionKey = [pms_staff_user_privilege, super_admin_privileges?.super_admin_full_privilege];
            break;
        case '/dictionaries/insurance-companies-add':
            permissionKey = [pms_staff_user_privilege, super_admin_privileges?.super_admin_full_privilege];
            break;
        case '/dictionaries/insurance-companies-edit':
            permissionKey = [pms_staff_user_privilege, super_admin_privileges?.super_admin_full_privilege];
            break;
        case '/user/fee-schedule-management':
            permissionKey =  [permission_key_values_accountsetup?.account_setup_fee_schedule_management_add,
            permission_key_values_accountsetup?.account_setup_fee_schedule_management_view];
            break;
        case '/user/associate-user':
            permissionKey = [
            permission_key_values_auditLogs?.can_view_userManagement,
            super_admin_privileges?.super_admin_full_privilege,
            pms_staff_user_privilege,
            module_submodule_permission?.admin_user_management_sub_module_view];
        break
        case '/user/associate-user-edit':
            permissionKey = [
            super_admin_privileges?.super_admin_full_privilege,
            pms_staff_user_privilege,
            permission_key_values_practice_user_management.user_management_modify];
        break
        case '/practice-user/associate-user':
            permissionKey = [
            super_admin_privileges?.super_admin_full_privilege,
            customer_admin_privilege,
            permission_key_values_practice_user_management.user_management_view]
            break;
        case '/practice-user/associate-user-add':
            permissionKey = [
            super_admin_privileges?.super_admin_full_privilege,
            customer_admin_privilege,
            permission_key_values_practice_user_management.user_management_modify]
            break;
        case '/user/page-locks':
            permissionKey =  [module_submodule_permission?.page_lock_sub_module_view];
            break;
        case '/user/roles-and-permissions':
            permissionKey =  [module_submodule_permission?.roles_and_permissions_sub_module_view];
            break;
        case '/logs':
            permissionKey =  [permission_key_values_claim?.audit_logs_module_view,
            super_admin_privileges?.super_admin_full_privilege,
            pms_staff_user_privilege,
            permission_key_values_super_Admin.audit_logs_admin_view];
            break;
        case '/csvtemplates':
            permissionKey =  [module_submodule_permission?.csv_import_template_sub_module_view];
            break;
        case '/user/customer-profile':
            permissionKey =  [
            super_admin_privileges?.super_admin_full_privilege,
            module_submodule_permission?.admin_customer_management_sub_module_view
            ];
            break;
        case '/billi-admin/customer-profile':
            permissionKey = [super_admin_privileges?.super_admin_full_privilege,
            module_submodule_permission?.admin_customer_management_sub_module_view
            ];
            break;
        case '/billi-admin/associate-user':
            permissionKey = [super_admin_privileges?.super_admin_full_privilege,
            module_submodule_permission?.admin_user_management_sub_module_view
            ];
            break;
        case '/billi-admin/roles-permission':
            permissionKey = [super_admin_privileges?.super_admin_full_privilege,
            module_submodule_permission?.admin_roles_and_permission_view 
            ];
            break;
        case '/claims/batch-status':
            permissionKey = [super_admin_privileges?.super_admin_full_privilege,
            permission_key_values_batch_status.pms_batch_status_sub_module_view
            ];
            break;
        case '/reports/check-level-report':
            permissionKey = [module_submodule_permission.check_level_report_view,
            super_admin_privileges?.super_admin_full_privilege,
                customer_admin_privilege
            ];
            break;

        default:
            permissionKey = '';
            
    }
    return checkPermission(...permissionKey);
}
