import { format, startOfDay, isBefore } from 'date-fns';
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import actionImg from "../../../assets/images/action.png";
import LoadingContext from "../../../container/loadingContext";
import { ALERT_TYPES } from "../../../utilities/dictionaryConstants";
import i18n from "../../../utilities/i18n";
import { ADD_ERROR, ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from "../../../utilities/labelConfigs";
import TextInput from "../../commons/input/input";
import Notify from "../../commons/notify";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import service from "./service";
import CalendarInput from "../../commons/input/CalendarInput";
import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { checkPermission } from "../../../utilities/commonUtilities";
import { permission_key_values_patient } from "../../../utilities/permissions";
import CommonButton from "../../commons/Buttons";

function PatientAddAlert(props) {
    const patientPK = props.patientPK;
    const alertEditable = props.patientEditable;
    const [header, setHeader] = useState("");
    const setShowLoadingBar = useContext(LoadingContext);
    const [patientAddAlertMessage, setPatientAddAlertMessage] = useState("");
    const [effectiveDate, setEffectiveDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [showAddAlertModalWindow, setShowAddAlertModalWindow] = useState(false);
    const [alertList, setAlertList] = useState([]);
    const [listOfAlerts, setListOfAlerts] = useState([]);
    const [activeRowId, setActiveRowId] = useState(0);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [edit, setEdit] = useState(false);
    const [selectedID, setSelectedID] = useState("");
    const [deleteAlertMessage, setDeleteAlertMessage] = useState("");
    const [deleteAlertId, setDeleteAlertId] = useState("");
    const stripTime = (date) => startOfDay(new Date(date));

    const data = ALERT_TYPES;
    const [selectedAlertType, setselectedAlertType] = useState([]);

    function onAlertList() {
        setAlertList(data);
    }

    function onHandleMultiselectAlert(e) {
        let value = e.target.value;
        setselectedAlertType(value);
    }

    function getListOfAlerts(patient_pk) {
        const result = service.ListPatientAlert(patient_pk);
        result.then((response) => {
            setListOfAlerts(response.data);
        });
    }

    useEffect(() => {
        if(patientPK){
            getListOfAlerts(patientPK);
            onAlertList();
        }
    }, [patientPK]);

    function onShowActionButtons(event, rowId) {
        let dropdown = document.getElementById("dropdown-insurance" + rowId);
        let dropdownMenu = document.getElementById("dropdown-menu-insurance" + rowId);
        dropdown.classList.toggle("show");
        dropdownMenu.classList.toggle("show");
        if (activeRowId === 0) {
            setActiveRowId(rowId);
        } else if (activeRowId === rowId) {
            setActiveRowId(0);
        } else if (this.state.activeRowId !== rowId) {
            dropdown = document.getElementById("dropdown-insurance" + activeRowId);
            dropdownMenu = document.getElementById("dropdown-menu-insurance" + activeRowId);
            dropdown.classList.toggle("show");
            dropdownMenu.classList.toggle("show");
            setActiveRowId(rowId);
        }
    }

    function onHideActionButtons(event, rowId) {
        let dropdown = document.getElementById("dropdown-insurance" + rowId);
        let dropdownMenu = document.getElementById("dropdown-menu-insurance" + rowId);
        dropdown.classList.remove("show");
        dropdownMenu.classList.remove("show");
        setActiveRowId(0);
    }
    function onEditAlerts(ID) {
        if (checkPermission(permission_key_values_patient.can_change_patient_alerts)) {
            setEdit(true);
            setHeader(i18n.t("patientAddAlert.editAlert"));
            setSelectedID(ID);
            let result = service.GetPatientAlert(ID);
            result.then((response) => {
                setPatientAddAlertMessage(response.data.message);
                setselectedAlertType(response.data.alert_type)
                setAlertList(data);
                setEffectiveDate(response.data.effective_date ? new Date(response.data.effective_date + " 00:00:00") : '');
                setDueDate(response.data.due_date ? new Date(response.data.due_date + " 00:00:00") : '');
                setShowAddAlertModalWindow(true);
            });
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function onDeleteAlerts(ID) {
        if (checkPermission(permission_key_values_patient.can_delete_patient_alerts)) {
            listOfAlerts.map((item) => {
                if (item.id === parseInt(ID)) {
                    setDeleteAlertMessage(item.message);
                    setDeleteAlertId(item.id);
                    setShowDeleteModalWindow(true);
                }
                return null;
            });
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function onDeleteAction() {
        setShowLoadingBar(true);
        let result = service.DeletePatientAlert(deleteAlertId);
        result.then(() => {
            setShowLoadingBar(false);
            getListOfAlerts(patientPK);
            setDeleteAlertMessage("");
            setDeleteAlertId("");
            setShowDeleteModalWindow(false);
            showNotifyWindow("show", "success", DELETE_SUCCESS);
        });
    }
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    function addalert() {
        if (checkPermission(permission_key_values_patient.patients_patients_list_modify,permission_key_values_patient.can_add_patient_alert)) {
            setHeader(i18n.t("patientAddAlert.addalert"));
            setShowAddAlertModalWindow(true);
            onAlertList();
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
       
    }
    function onResetAddAlert() {
        setselectedAlertType([]);
        setPatientAddAlertMessage("");
        setEffectiveDate("");
        setDueDate("");
        setEdit(false);
        onAlertList();
    }
    function onHandleEffectiveDate(selected) {
        setEffectiveDate(selected);
    }
    function onHandleDueDate(selected) {
        setDueDate(selected);
    }
    function onHandleChange(e) {
        const name = e.target.name;
        let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
        let trimmedValue = "";
        let inputFields = ["patientAddAlertMessage", "alertType", "effectiveDate", "dueDate"];
        if (inputFields.includes(name)) {
            trimmedValue = value;
        }
        if (name === "patientAddAlertMessage") setPatientAddAlertMessage(trimmedValue);
    }
    const onSaveAlert = (e) => {
        e.preventDefault();
        let has_error = false;
        const today = stripTime(new Date());
        if (!patientAddAlertMessage.trim() || selectedAlertType.length === 0) {
            has_error = true;
        }
        try {
            if (effectiveDate) {
                const effectiveDateOnly = stripTime(effectiveDate);
                if (isBefore(effectiveDateOnly, today)) {
                    has_error = true;
                } else {
                    format(effectiveDate, "yyyy-MM-dd");
                }
            }
            if (dueDate) {
                const dueDateOnly = stripTime(dueDate);
                const effectiveDateOnly = effectiveDate ? stripTime(effectiveDate) : null;
                if (isBefore(dueDateOnly, today) || (effectiveDateOnly && isBefore(dueDateOnly, effectiveDateOnly))) {
                    has_error = true;
                } else {
                    format(dueDate, "yyyy-MM-dd");
                }
            }
        } catch {
            has_error = true;
        }
        if (!has_error) {
            setShowLoadingBar(true);
            let result = null;
            if (edit) {
                let pathData = { patient: patientPK, alert_type: selectedAlertType, message: patientAddAlertMessage };
                let effective_date = effectiveDate ? format(effectiveDate, "yyyy-MM-dd") : '';
                pathData['effective_date'] = effective_date
                let due_date = dueDate ? format(dueDate, "yyyy-MM-dd") : '';
                pathData['due_date'] = due_date;
                result = service.UpdatePatientAlert(selectedID, pathData);
            } else {
                let pathData = { patient: patientPK, alert_type: selectedAlertType, message: patientAddAlertMessage };
                let effective_date = effectiveDate ? format(effectiveDate, "yyyy-MM-dd") : '';
                if (effective_date) {
                    pathData['effective_date'] = effective_date
                }
                let due_date = dueDate ? format(dueDate, "yyyy-MM-dd") : '';
                if (due_date) {
                    pathData['due_date'] = due_date;
                }
                result = service.SavePatientAlert(pathData);
            }
            result.then((response) => {
                setShowLoadingBar(false);
                setShowAddAlertModalWindow(false);
                getListOfAlerts(patientPK);
                onResetAddAlert();
                if (response.status === 201 || response.status === 200) {
                    setEdit(false);
                    if (response.status === 201) {
                        showNotifyWindow("show", "success", ADD_SUCCESS);
                    } else {
                        showNotifyWindow("show", "success", UPDATE_SUCCESS);
                    }
                    setShowAddAlertModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow("show", "error", i18n.t("errorMessages.record_exists"));
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
            });
        }
    };
    return (
        <div>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <Form id="form_patient_info" autoComplete="off" className="padding-bottom60" >
                {listOfAlerts?.length === 0 &&
                    <div className="row mt-3 mb-2">
                    { alertEditable &&                            
                            <div className="width-60-per justify-right mt-2 pb-2">
                                <CommonButton variant="contained" onClick={addalert} label={i18n.t("patientAddAlert.addalertPlus")} />
                            </div>
                        }
                    </div>
                }
                <div className="row padding-left10 mt-1">
                    <table className="table width-60-per">
                        <tbody>
                            {listOfAlerts?.length !== 0 &&
                                listOfAlerts.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="padding-left15 row">
                                                    <div className="div-float-left"
                                                        style={{ whiteSpace: 'pre-line' }}
                                                    >
                                                        {item.message}
                                                    </div>
                                                </div>
                                                <div className="padding-left15 row">
                                                    <div>
                                                        {i18n.t("patientAddAlert.label.created")}
                                                        {item.created_by_name}
                                                        {i18n.t("patientAddAlert.label.on")}
                                                        <Moment format="MM/DD/yyyy hh:mm A">{item.created_on}</Moment>{item.alert_type.length > 0 ? '(' + i18n.t("patientAddAlert.label.alertType") : ''}
                                                        {item.alert_type.map((ite, ind) => {
                                                            let comma = "";
                                                            let alertArray = [];
                                                            alertArray.push(data[ite - 1].name);
                                                            if (ind > 0) {
                                                                comma = ",";
                                                            }
                                                            return comma + " @" + alertArray;
                                                        })}           {item.alert_type.length > 0 ? ')' : ''}
                                                    </div>
                                                </div>
                                            </td>
                                            <td align="center">
                                                <div className="">
                                                    <div className="dropdown" id={"dropdown-insurance" + item.id}>
                                                        <button id={"dropdownMenuButton-" + item.id} data-testid={"dropdownMenuButton-" + item.id} className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" onClick={(e) => onShowActionButtons(e, item.id)} onBlur={(e) => onHideActionButtons(e, item.id)}>
                                                            <img id="test" src={actionImg} alt="Action" />
                                                        </button>
                                                        <div className="dropdown-menu" id={"dropdown-menu-insurance" + item.id}>
                                                            <Link className="dropdown-item edit" data-testid={"dropdown-item edit" + item.id} to="#" onMouseDown={() => onEditAlerts(item.id)}>
                                                                {i18n.t("patientAddAlert.edit")}
                                                            </Link>
                                                            <Link className="dropdown-item edit" to="#" data-testid={"dropdown-item delete" + item.id} onMouseDown={() => onDeleteAlerts(item.id)}>
                                                                {i18n.t("patientAddAlert.delete")}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            {listOfAlerts.length === 0 &&
                                <tr>
                                    <td>{i18n.t('commons.noRecords')}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                </div>
            </Form>
            <CustomizedDialogs showModal={showAddAlertModalWindow} type="save" header={header} setShowModalWindow={setShowAddAlertModalWindow} resetForm={onResetAddAlert}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveAlert(e)} encType="multipart/form-data" autoComplete="off">
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="patientAddAlertMessage" name="patientAddAlertMessage" label={i18n.t("patientAddAlert.alertmessage")} onValueChange={onHandleChange} value={patientAddAlertMessage} required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4">
                                <div className="">
                                    <MaterialMultiselect
                                        required={true} name="alertType" label={i18n.t("patientAddAlert.alerttype")}
                                        options={alertList}
                                        value={selectedAlertType}
                                        id="MaterialMultiselect-Type"
                                        onValueChange={onHandleMultiselectAlert}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <CalendarInput
                                 label={i18n.t("patientAddAlert.effectivedate")}
                                 id="effectiveDate" 
                                 selected={effectiveDate} 
                                 minDate={new Date()}
                                 maxDate={dueDate ? dueDate : undefined}
                                 onValueChange={(selected) => onHandleEffectiveDate(selected)}
                                 name="effectiveDate" />
                            </div>
                            <div className="col-4 pl-0">
                                <CalendarInput 
                                label={i18n.t("patientAddAlert.duedate")}
                                 selected={dueDate} id="duedate" 
                                 onValueChange={(selected) => onHandleDueDate(selected)}
                                 minDate={effectiveDate ? effectiveDate : new Date()} 
                                 name="dueDate"
                                   />
                            </div>
                        </div>
                    </>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={onResetAddAlert} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("patientAddAlert.deleteConfirmation") + " '" + deleteAlertMessage + "'?"}
            </CustomizedSmallDialogs>
        </div>
    );
}

export default PatientAddAlert;
