import { useEffect, useRef, useMemo } from 'react';

/**
 * Custom hook to handle dynamic column resizing for amount filter elements
 * @param {Object[]} filterConfig - Array of filter configuration objects
 * @param {string} filterConfig[].type - Type of the filter (e.g., 'amountFilter')
 * @param {string} filterConfig[].fieldPrefix - Prefix used to identify the filter field
 * @param {Object} filterValues - Current values of all filters
 * @param {boolean} advancedFilterExpanded - Whether the advanced filter section is expanded
 * @param {boolean} fieldValueChanged - Flag indicating if any field value has changed
 * @returns {void}
 */
const useColumnResize = (filterConfig, filterValues, advancedFilterExpanded) => {
    const prevValuesRef = useRef([]);

    // Memoize amount filters to avoid recalculation on every render
    const amountFilters = useMemo(() =>
        filterConfig.filter(item => item.type === 'amountFilter'),
        [filterConfig]
    );

    // Memoize current values to avoid recalculation unless filterValues change
    const currentValues = useMemo(() =>
        amountFilters.map(item => filterValues[item.fieldPrefix + 'Type']),
        [amountFilters, filterValues]
    );

    // Memoize the change detection
    const hasChanged = useMemo(() => {
        if (amountFilters.length === 0) return false;

        return currentValues.some((value, index) =>
            value !== prevValuesRef.current[index]
        ) || advancedFilterExpanded !== prevValuesRef.current.advancedFilter;
    }, [amountFilters.length, currentValues, advancedFilterExpanded]);

    useEffect(() => {
        // Early return if nothing changed or no filters
        if (!hasChanged || amountFilters.length === 0) return;

        // Update the elements
        amountFilters.forEach(item => {
            const elementId = `report-filter-${item.fieldPrefix}-type-selection`;
            const element = document.getElementById(elementId);
            const parent = element?.parentElement;

            if (!parent) return;

            const hasType = Boolean(filterValues[item.fieldPrefix + 'Type']);
            parent.classList.remove(hasType ? 'col-3' : 'col-6');
            parent.classList.add(hasType ? 'col-6' : 'col-3');
        });

        // Store new values for next comparison
        prevValuesRef.current = [...currentValues];
        prevValuesRef.current.advancedFilter = advancedFilterExpanded;

    }, [hasChanged, amountFilters, filterValues, currentValues, advancedFilterExpanded]);
};

export default useColumnResize;

