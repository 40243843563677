import React from 'react';
import PropTypes from 'prop-types';
import i18n from "../../../utilities/i18n";
import SelectInput from "../../commons/input/SelectInput";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import RangeInput from "../../commons/input/RangeInput";
import { claimAmountType } from '../../../utilities/dictionaryConstants';

/**
 * A generic filter component for handling amount-based inputs
 * Supports single amount and range inputs based on selected type
 * 
 * @component
 * @param {Object} props
 * @param {Object} props.commonProps - Common props for the select input
 * @param {Object} props.filterValues - Current filter values
 * @param {Function} props.handleValueChange - Callback for value changes
 * @param {string} props.label - Label for the filter
 * @param {string} props.fieldPrefix - Prefix for the field names. Used to create unique field identifiers:
 *                                    - `${fieldPrefix}Type` for the type selector (e.g., 'balanceType', 'checkType')
 *                                    - `${fieldPrefix}From` for the 'from' amount (e.g., 'balanceFrom', 'checkFrom')
 *                                    - `${fieldPrefix}To` for the 'to' amount in range inputs (e.g., 'balanceTo', 'checkTo')
 * @notes
 * If you want to have this filter in the report, you need to add these value fields it to the reportSlice.js file.
 * Filter Config: 
 * {
 *  fieldName: 'balanceAmount',
 *  type: 'amountFilter',
 *  fieldPrefix: 'balanceAmountTotals',
 * }
 * Filter Values:
 * {
 *  balanceAmountTotalsType: '',
 *  balanceAmountTotalsFrom: '',
 *  balanceAmountTotalsTo: '',
 * }
 * 
 * Here the fieldPrefix is 'balanceAmountTotals'
 */
const AmountFilter = ({
    commonProps,
    filterValues,
    handleValueChange,
    label,
    fieldPrefix
}) => {
    const typeField = `${fieldPrefix}Type`;
    const fromField = `${fieldPrefix}From`;
    const toField = `${fieldPrefix}To`;

    /**
     * Validates and formats amount with decimal and negative handling
     * @param {string|number} input - Amount to validate
     * @returns {string} Formatted amount or original input for special cases
     */
    const formatAmount = (input) => {
        if (!input && input !== 0) return '';

        try {
            // Clean and limit input while preserving negative and decimal
            let value = String(input)
                .trim()
                .replace(/[^\-\d.]/g, '')  // Keep negative, digits, and decimal
                .substring(0, 9);          // Limit length to 9

            // Check if it's a valid number
            if (!isNaN(Number(value))) {
                let parts = String(value).split('.');
                // Handle decimal places
                if (parts.length > 1 && parts[1].length > 2) {
                    return parseFloat(value).toFixed(2);
                }
                return value;
            }

            // Allow partial inputs for negative and decimal starts
            return input.startsWith('-') || input.startsWith('.') ? input : '';

        } catch (error) {
            console.warn('Amount format error:', error);
            return '';
        }
    };

    /**
     * Handles amount value changes with proper formatting
     * @param {string} value - New amount value
     * @param {string} field - Field name to update
     */
    const handleAmountChange = (value, field) => {
        const formattedValue = formatAmount(value);
        handleValueChange(formattedValue, field);
    };

    return (
        <div id={`report-filter-${fieldPrefix}-type-selection`} className="row">
            <div className={!filterValues[typeField] ? "col-12" : "col-6"}>
                <SelectInput
                    {...commonProps}
                    value={filterValues[typeField]}
                    data={claimAmountType || []}
                    onValueChange={(e) => handleValueChange(e.target.value, typeField)}
                />
            </div>
            {filterValues[typeField] && (
                <div className="col-6">
                    {filterValues[typeField] !== "Between" ? (
                        <CurrencyInputs
                            id={`report-filter-${fieldPrefix}-amnt-from`}
                            name={fromField}
                            value={formatAmount(filterValues[fromField])}
                            onValueChange={(e) => handleAmountChange(e.target.value, fromField)}
                            label={`${label} ${filterValues[typeField]}`}
                            placeholder={i18n.t("reports.filters.dollar")}
                        />
                    ) : (
                        <RangeInput
                            name1={fromField}
                            name2={toField}
                                value1={formatAmount(filterValues[fromField])}
                                value2={formatAmount(filterValues[toField])}
                                onValueChange1={(e) => handleAmountChange(e.target.value, fromField)}
                                onValueChange2={(e) => handleAmountChange(e.target.value, toField)}
                            placeholder={i18n.t("reports.filters.dollar")}
                            label={`${label} ${filterValues[typeField]}`}
                            ValidationMsg={i18n.t("commons.balanceError")}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

AmountFilter.propTypes = {
    commonProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.any
    }).isRequired,
    filterValues: PropTypes.object.isRequired,
    handleValueChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    fieldPrefix: PropTypes.string.isRequired
};

export default AmountFilter;