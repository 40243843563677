import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Link, useHistory } from "react-router-dom";
import { Form } from 'react-bootstrap'

import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input'
import SelectInput from '../../commons/input/SelectInput'
import NPISearch from '../../commons/NPISearch/NPISearch'
import { CityInput } from '../../commons/input/CityInput';
import PhoneInput from '../../commons/PhoneInput/PhoneInput'
import EmailInput from '../../commons/EmailInput/EmailInput'
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch'
import LoadingContext from '../../../container/loadingContext'

import i18n from '../../../utilities/i18n'
import { US_STATES } from '../../../utilities/usStates';
import { getStorage } from '../../../utilities/browserStorage'
import { permission_key_values_accountsetup } from '../../../utilities/permissions';
import { ValidateEmail,checkPermission } from '../../../utilities/commonUtilities'; 
import { ADD_ERROR, ADD_SUCCESS, UPDATE_SUCCESS } from '../../../utilities/labelConfigs'
import { DEFAULT_COUNTRY, DEFAULT_PAGING_SIZE, ROUTE_REFERRING_PROVIDER } from '../../../utilities/staticConfigs'
import { PROVIDER_TYPES, PROVIDER_TYPE_INDIVIDUAL, PROVIDER_TYPE_ORGANIZATION } from "../../../utilities/dictionaryConstants";
import Multiselect from '../../../multiselect/src/index'; // NEW COMPONENT FOR SEARCHABLE SINGLE/MULTI SELECT https://github.com/rowyio/multiselect
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'

import service from './service'

export const ReferringProviderAdd = (props) => {
    const history = useHistory();
    const practicePK = getStorage('practice');
    const [showNPIModalWindow, setShowNPIModalWindow] = useState(false);
    const [editForm, setEditForm] = useState(false);

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    const [editReferringProviderID, setEditReferringProviderID] = useState('');
    const [mSelTaxonomyList, setMSelTaxonomyList] = useState([]);

    const providerTypeList = PROVIDER_TYPES;
    const [providerType, setProviderType] = useState(PROVIDER_TYPE_INDIVIDUAL);
    const [providerTypeLabel, setProviderTypeLabel] = useState(i18n.t('userPages.referringProvider.labelLastName'));
    const [referringProviderID, setReferringProviderID] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [credentials, setCredentials] = useState('');
    const [npi, setNPI] = useState('');
    const [referringProviderGroup, setReferringProviderGroup] = useState('');
    const [referringProviderGroupList, setReferringProviderGroupList] = useState([]);
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState(DEFAULT_COUNTRY);
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [clea, setClea] = useState('');
    const [NPICode, setNPICode] = useState([]);
    const [taxonomy, setTaxonomy] = useState('');
    const [medicareID, setMedicareID] = useState('');
    const [medicaidID, setMedicaidID] = useState('');
    const [stateLicense, setStateLicense] = useState('');
    const [bcbsProviderID, setBcbsProviderID] = useState('');
    const [tricareProviderID, setTricareProviderID] = useState('');
    const [uhcProviderID, setUhcProviderID] = useState('');
    const [commercialProviderID, setCommercialProviderID] = useState('');
    const [stateList, setStateList] = useState([]);
    const [gotoList, setGotoList] = useState(false);
    const [referringProviderInactive, setReferringProviderInactive] = useState(false);
    const [inactiveReferringProviderModal, setInactiveReferringProviderModal] = useState(false);
    const [form_error, setFormError] = useState({
        'lastName': '', 'firstName': '', 'credentials': '', 'npi': '', 'referringProviderGroup': '',
        'street1': '', 'city': '', 'state': '', 'zip': ''
    });

    const setShowLoadingBar = useContext(LoadingContext);
    const activePage=0;

    const getTaxonomy = (pageSize, page) => {
        const result = service.ListTaxonomies(pageSize, page);
        result.then(response => {
            let tmpObjArr = [];
            if (Array.isArray(response.data) && response.data.length) {
                response.data.forEach((item) => {
                    tmpObjArr.push({ "value": item.id, "label": item.full_name, "name": item.name });
                })
                setMSelTaxonomyList(tmpObjArr);
            }
            else {
                setMSelTaxonomyList(tmpObjArr);
            }
        });
    }

    useEffect(() => {
        getTaxonomy(20, 0);
    }, []);


    /**
     * To set the value of taxonomy automatically by filtering with the taxonomy list
     */
    useEffect(() => {
        if (npi.length > 0) {
            let filteredNPI = NPICode.filter(d => d.code && d.primary == true)[0]?.code;

            const matchedTaxonomies = mSelTaxonomyList.find((item) => item.name == filteredNPI)?.value

            if (matchedTaxonomies) {
                setTaxonomy(matchedTaxonomies)
            } else if (mSelTaxonomyList.length > 0) {
                setTaxonomy(mSelTaxonomyList[0].value)
            }
        }
    }, [npi]);

    function getStatesData(pageSize, page) {
        setShowLoadingBar(true);
        const result = service.ListStates(pageSize, page);
        result.then(response => {
            setShowLoadingBar(false);
            setStateList(response.data);
        });
    }

    useEffect(() => {
        getReferringProviderGroupData(DEFAULT_PAGING_SIZE, activePage);
        getStatesData(DEFAULT_PAGING_SIZE, activePage);

        let getReferringID = '';

        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            getReferringID = props.location.state.selectedID;
        }
        else if (history.location.state) {
            getReferringID = history.location.state.referringProviderID;
        }

        if (getReferringID) {
            setShowLoadingBar(true);
            const data = service.GetReferringProvider(getReferringID);
            data.then(response => {
                setShowLoadingBar(false);
                setReferringProviderID(response.data.custom_referring_provider_id);
                setFirstName(response.data.first_name);
                setMiddleName(response.data.middle_name);
                setLastName(response.data.last_name);
                setCredentials(response.data.credentials);
                setNPI(response.data?.npi);
                setReferringProviderGroup(response.data.referral_provider_group);
                if (response.data.location_data) {
                    setStreet1(response.data.location_data.street1);
                    setStreet2(response.data.location_data.street2);
                    setCity(response.data.location_data.city);
                    setState(response.data.location_data.state);
                    setZip(response.data.location_data.zip_code);
                    setCountry(response.data.location_data.country);
                }
                setPhone(response.data.phone);
                setFax(response.data.fax);
                setEmail(response.data.email);
                setProviderType(response.data.provider_type);
                if (response.data.provider_type === PROVIDER_TYPE_ORGANIZATION) {
                    setProviderTypeLabel(i18n.t("userPages.referringProvider.labelProviderTypeOrg"));
                }
                else {
                    setProviderTypeLabel(i18n.t("userPages.referringProvider.labelLastName"));
                }
                setClea(response.data.clea);
                setTaxonomy(response.data.taxonomy);
                setMedicareID(response.data.medicare_id);
                setMedicaidID(response.data.medicaid_id);
                setStateLicense(response.data.state_license);
                setBcbsProviderID(response.data.bcbs_provider_id);
                setTricareProviderID(response.data.tricare_provider_id);
                setUhcProviderID(response.data.uhc_provider_id);
                setCommercialProviderID(response.data.commercial_provider_id);
                setEditForm(true);
                setEditReferringProviderID(getReferringID);
                setReferringProviderInactive(!response.data.active);
            });
        }


    }, []);

    function getReferringProviderGroupData(pageSize, page) {
        const result = service.ListReferringProviderGroups(pageSize, page, practicePK);
        result.then(response => {
            setReferringProviderGroupList(response.data);
        });
    }

    const fields = {
        firstName: setFirstName,
        middleName: setMiddleName,
        lastName: (trimmedValue) => {
          setLastName(trimmedValue);
          if (!trimmedValue) {
            setNPI('');
          }
        },
        credentials: setCredentials,
        npi: setNPI,
        referringProviderGroup: setReferringProviderGroup,
        street1: setStreet1,
        street2: setStreet2,
        city: setCity,
        state: setState,
        zip: setZip,
        country: setCountry,
        phone: setPhone,
        fax: setFax,
        email: setEmail,
        providerType: (trimmedValue) => {
          setProviderType(trimmedValue);
            if (trimmedValue === PROVIDER_TYPE_ORGANIZATION) {
            setProviderTypeLabel(i18n.t("userPages.referringProvider.labelProviderTypeOrg"));
          }
          else {
            setProviderTypeLabel(i18n.t("userPages.referringProvider.labelLastName"));
          }
        },
        clea: setClea,
        medicareID: setMedicareID,
        medicaidID: setMedicaidID,
        stateLicense: setStateLicense,
        bcbsProviderID: setBcbsProviderID,
        tricareProviderID: setTricareProviderID,
        uhcProviderID: setUhcProviderID,
        commercialProviderID: setCommercialProviderID,
        referringProviderInactive: setReferringProviderInactive,
      };

    function onHandleChange(e) {
        if (e.target) {
            const name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let trimmedValue = '';
            let inputFields = ['firstName', 'middleName', 'lastName', 'credentials',
                'npi', 'referringProviderGroup', 'street1', 'street2', 'city', 'state',
                'country', 'phone', 'zip', 'fax', 'email', 'clea', 'taxonomy', 'medicareID',
                'medicaidID', 'stateLicense', 'bcbsProviderID', 'tricareProviderID',
                'uhcProviderID', 'commercialProviderID', 'referringProviderInactive',"providerType",
                "referringProviderID"
            ]
            let mandatoryFields = ['firstName', 'lastName', 'npi',
                'referringProviderGroup', 'street1', 'city', 'state', 'zip'
            ]

            const validNames = [
                'firstName', 'middleName', 'lastName','credentials','city','clea','medicaidID',
                'medicareID','bcbsProviderID', 'tricareProviderID', 'uhcProviderID',
                'commercialProviderID'
            ];
    
            if (validNames.includes(name)) {
                value = value.replace(/[^A-Za-z0-9.\s]/g, '');
            }
            if (inputFields.includes(name))
                trimmedValue = value
            if ((!trimmedValue || !('' + trimmedValue).trim()) && (mandatoryFields.includes(name))) {
                setFormError({
                    ...form_error,
                    [name]: 'error'
                });
            }
            else {
                setFormError({
                    ...form_error,
                    [name]: ''
                });
            }
           setField(name,trimmedValue) 
        }
    }

    const setField = (name, trimmedValue) => {
        const setter = fields[name];
        if (setter) {
          setter(trimmedValue);
        }
      };
    
    const handleMultiselect = (value) => {
        if (value) {
            setTaxonomy(value);
        }
        else {
            setTaxonomy('');
        }
    };

    function onSaveFormData(e) {
        e.preventDefault();
        
        /**
         * checking field error and mandatory fields are empty here
         */
        if (shouldDisplayErrorMessage()) {
          return;
        }
        
        setShowLoadingBar(true);
        
        let data = createFormDataObject();
        
        let result = editForm 
          ? service.UpdateReferringProvider(editReferringProviderID, data)
          : service.AddReferringProvider(data);
        
        result.then(response => {
          setShowLoadingBar(false);
          
          if (response.status === 201) {
            showNotifyWindow('show', 'success', ADD_SUCCESS);
          } else if (response.status === 200) {
            showNotifyWindow('show', 'success', UPDATE_SUCCESS);
          } else if (response.status === 400 && response.data.message!== undefined) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
           return
          } else {
            showNotifyWindow('show', 'error', ADD_ERROR);
          }
          
          setTimeout(() => {
            setShowLoadingBar(false);
            setGotoList(true);
          }, 1000)
        });
        
        /**
         * 
         * @returns checking for possible save error
         */
        function shouldDisplayErrorMessage() {
            if ((providerType === PROVIDER_TYPE_INDIVIDUAL && !firstName.trim()) || !lastName.trim() || !npi.trim() || !taxonomy ||
            !street1.trim() || !city.trim() || !state.trim() || !zip.trim()) {
            return true;
          }

          if (email?.length !== 0) {
            if (email.trim()) {
                const email_validation = ValidateEmail(email.trim());
                if (!email_validation) {
                  return true;
                }
              } else {
                return true;
              }
          }
          return false;
        }

        /**
         * creating form Data
         * @returns 
         */
        
        function createFormDataObject() {
          return {
                    practice: practicePK,
                    first_name: firstName, middle_name: middleName, last_name: lastName,
                    credentials: credentials, npi: npi, referral_provider_group: referringProviderGroup,
                    location: { street1: street1, street2: street2, city: city, state: state, zip_code: zip, country: country },
                    phone: phone, fax: fax, email: email.trim(), provider_type: providerType, clea: clea, taxonomy: taxonomy,
                    medicare_id: medicareID, medicaid_id: medicaidID, state_license: stateLicense,
                    bcbs_provider_id: bcbsProviderID, tricare_provider_id: tricareProviderID,
                    uhc_provider_id: uhcProviderID, commercial_provider_id: commercialProviderID,
                    active: !referringProviderInactive
          };
        }
      }
      

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onHideNPI() {
        setShowNPIModalWindow(false);
    }

    function showSearchNPI() {
        setShowNPIModalWindow(true);
    }

    function setSelectedNPI(npiData) {
        const {
            NPINum,
            NPIMiddleName,
            NPILastName,
            NPIFirstName,
            NPIOrgName,
            NPIAddress1,
            NPIAddress2,
            NPICity,
            NPIEmail,
            NPIPhone,
            NPIPostalCode,
            NPIState,
            NPCountry,
            NPICode
        } = npiData;

        setNPI(NPINum);

        if (providerType === PROVIDER_TYPE_INDIVIDUAL) {
            if (NPIFirstName && NPIFirstName.length > 0) setFirstName(NPIFirstName);
            if (NPIMiddleName && NPIMiddleName.length > 0) setMiddleName(NPIMiddleName);
            if (NPILastName && NPILastName.length > 0) setLastName(NPILastName);
        }
        else {
            setFirstName('');
            setMiddleName('');
            setLastName((NPIOrgName && NPIOrgName.length) ? NPIOrgName : '');
        }
        if( NPICode && NPICode.length > 0)setNPICode(NPICode);
        if (NPIAddress1 && NPIAddress1.length > 0) setStreet1(NPIAddress1);
        if (NPIAddress2 && NPIAddress2.length > 0) setStreet2(NPIAddress2);
        if (NPICity && NPICity.length > 0) setCity(NPICity);
        if (NPIState && NPIState.length > 0) setState(NPIState);
        if (NPCountry && NPCountry.length > 0) setCountry(NPCountry);
        if (NPIPostalCode && NPIPostalCode.length > 0) setZip(NPIPostalCode);
        if (NPIPhone && NPIPhone.length > 0) setPhone(NPIPhone);
        if (NPIEmail && NPIEmail.length > 0) setEmail(NPIEmail);

        setShowNPIModalWindow(false);
    }


    function onInactiveReferringProvider(e) {
        e.preventDefault();
        setInactiveReferringProviderModal(true);
    }

    if (gotoList) {
        return <Redirect to={{ pathname: ROUTE_REFERRING_PROVIDER }} />;
    }

    function backToRefering() {
        history.push(ROUTE_REFERRING_PROVIDER)
    }

    return (
        <div className="col-md-12  padding-bottom20">
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="box padding-bottom20  margin-right15">
                <div className="box-content-white padding-bottom20">
                    <div className="box-content box-content-white">
                        <Form id="form_dataEntry" autoComplete="off" onSubmit={referringProviderInactive ? (e) => onInactiveReferringProvider(e) : (e) => onSaveFormData(e)} encType="multipart/form-data">
                        <Form.Group>
                            <div className="row">
                                {referringProviderID &&
                                    <div className="col-2">
                                        <div className="form-group ">
                                            <TextInput type="text" id="referringProviderID" name="referringProviderID" label={i18n.t('userPages.referringProvider.labelReferringProviderID')} onValueChange={onHandleChange} disabled={true} className={'read-only'} value={referringProviderID} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group ">
                                        <SelectInput data={providerTypeList} required={true} name="providerType" id="providerType" setValue={setProviderType} value={providerType} onValueChange={selected => onHandleChange(selected)} label={i18n.t('userPages.referringProvider.labelProviderType')} selectOptionRemove={true} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group ">
                                        <TextInput type="text" id="lastName" name="lastName" required={true} label={providerTypeLabel} onValueChange={onHandleChange} className={form_error.lastName ? 'input-error' : ""} value={lastName} />
                                    </div>
                                </div>
                                {providerType === PROVIDER_TYPE_INDIVIDUAL && <div className="col-3">
                                    <div className="form-group ">
                                        <TextInput type="text" id="firstName" name="firstName" required={true} label={i18n.t('userPages.referringProvider.labelFirstName')} onValueChange={onHandleChange} className={form_error.firstName ? 'input-error' : ""} value={firstName} />
                                    </div>
                                </div>}

                                {providerType === PROVIDER_TYPE_INDIVIDUAL && <div className="col-3">
                                    <div className="form-group ">
                                        <TextInput type="text" id="middleName" name="middleName" label={i18n.t('userPages.referringProvider.labelMiddleName')} onValueChange={onHandleChange} className={form_error.middleName ? 'input-error' : ""} value={middleName} />
                                    </div>
                                </div>}
                            </div>
                            
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="credentials" name="credentials" label={i18n.t('userPages.referringProvider.labelCredentials')} onValueChange={onHandleChange}  value={credentials} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                        <div className="div-float-right searchNpi" style={{ position: "absolute", right: "4px" }} onClick={showSearchNPI} >
                                            <Link to="#">{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                        </div>
                                    {(!lastName || lastName.length === 0 || !providerType) &&
                                        <div className="div-float-right disabledLabel" style={{ position: "absolute", right: "4px" }}>
                                            <Link to="#" className="disabledLabel" disabled={true} >{i18n.t("userPages.practices.labelSearchNPI")}</Link>
                                        </div>
                                    }
                                    <div className="form-group ">
                                        <TextInput type="text" id="npi" name="npi" required={true} label={i18n.t('userPages.referringProvider.labelNPI')} onValueChange={onHandleChange} className={form_error.npi ? 'input-error' : ""} value={npi} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="padding-top">
                                        <SelectInput data={referringProviderGroupList} name="referringProviderGroup" id="referringProviderGroup" setValue={setReferringProviderGroup} value={referringProviderGroup} onValueChange={selected => onHandleChange(selected)} className={form_error.referringProviderGroup ? 'input-error' : ""} label={i18n.t('userPages.referringProvider.labelReferringProviderGroup')} />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="street1" name="street1" required={true} label={i18n.t('userPages.referringProvider.labelStreet1')} onValueChange={onHandleChange} className={form_error.street1 ? 'input-error' : ""} value={street1} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="street2" name="street2" label={i18n.t('userPages.referringProvider.labelStreet2')} onValueChange={onHandleChange} value={street2} />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <CityInput type="text" id="city" name="city" required={true} label={i18n.t('userPages.referringProvider.labelCity')} onValueChange={onHandleChange} className={form_error.city ? 'input-error' : ""}
                                            value={city} setValue={setCity} zip={zip} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <SelectInput listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={state} onValueChange={onHandleChange} label={i18n.t('patientPages.patientInfo.labelState')} className={form_error.state ? 'input-error' : ""}/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <GeoCodeSearch id="zip" name="zip" className={form_error.zip ? 'input-error' : ""} required={true}
                                            label={i18n.t("customerSetup.provider.labelZip")} onValueChange={onHandleChange}
                                            setlocState={setState} setLocCity={setCity}
                                            setLocCountry={setCountry} setLocZipCode={setZip} defaultValue={zip} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group ">
                                        <TextInput type="text" id="country" name="country" label={i18n.t('userPages.referringProvider.labelCountry')} onValueChange={onHandleChange} className={form_error.country ? 'input-error' : ""} value={country} />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <PhoneInput name="phone" label={i18n.t('userPages.referringProvider.labelPhone')} onValueChange={setPhone} className={form_error.phone ? 'input-error' : ""} value={phone} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <PhoneInput id="fax" name="fax" label={i18n.t('userPages.referringProvider.labelFax')} onValueChange={setFax} className={form_error.fax ? 'input-error' : ""} value={fax} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group padding-top3" >
                                        <EmailInput id="email" placeholder="Email" name="email" label={i18n.t('userPages.referringProvider.labelEmail')} onValueChange={onHandleChange}  value={email} />
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="clea" name="clea" label={i18n.t("userPages.referringProvider.labelClea")} onValueChange={onHandleChange} value={clea} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className={"form-group " + (!taxonomy ? "input-error" : "")}>
                                        <Multiselect value={taxonomy}
                                            onChange={handleMultiselect}
                                            multiple={false}
                                            options={mSelTaxonomyList}
                                            label={i18n.t('customerSetup.provider.labelTaxonomy')}
                                            labelPlural={i18n.t('customerSetup.provider.labelTaxonomy')}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="medicareID" name="medicareID" label={i18n.t("userPages.referringProvider.labelMedicareID")} onValueChange={onHandleChange} value={medicareID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="medicaidID" name="medicaidID" label={i18n.t("userPages.referringProvider.labelMedicaidID")} onValueChange={onHandleChange} value={medicaidID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <SelectInput data={stateList} label={i18n.t('userPages.referringProvider.labelStateLicense')} name="stateLicense" id="stateLicense" setValue={setStateList} value={stateLicense} onValueChange={selected => onHandleChange(selected)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="bcbsProviderID" name="bcbsProviderID" label={i18n.t("userPages.referringProvider.labelBcbsProviderID")} onValueChange={onHandleChange} value={bcbsProviderID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="tricareProviderID" name="tricareProviderID" label={i18n.t("userPages.referringProvider.labelTricareProvideID")} onValueChange={onHandleChange} value={tricareProviderID} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <TextInput type="text" id="uhcProviderID" name="uhcProviderID" label={i18n.t("userPages.referringProvider.labelUhcProviderID")} onValueChange={onHandleChange} value={uhcProviderID} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <TextInput type="text" id="commercialProviderID" name="commercialProviderID" label={i18n.t("userPages.referringProvider.labelCOmmercialProviderID")} onValueChange={onHandleChange} value={commercialProviderID} />
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-4 inactive-chk">
                                {editForm && <div className="max-width-3">
                                    <div className="form-group">
                                        <div className="custom-checkbox lh-0">
                                            <input type="checkbox" id={"referringProviderInactive"}
                                                checked={referringProviderInactive} name="referringProviderInactive" onChange={onHandleChange}
                                            />
                                            <label className="checkbox top-14" htmlFor={"referringProviderInactive"}></label>
                                        </div>
                                    </div>
                                </div>}
                                {editForm && <div className="padding-left20 margin-top-7 padding-top5">
                                    <label className="">{i18n.t('userPages.referringProvider.labelInactive')}</label>
                                </div>}
                            </div>
                            <CustomizedDialogs showModal={inactiveReferringProviderModal} setShowModalWindow={setInactiveReferringProviderModal} header={i18n.t("commons.confrimInactiveHeader")} type="yes"
                                inactveItem={onSaveFormData} buttonStyle={'danger'}>
                                {i18n.t("commons.confrimInactive") + " referring provider?"}
                            </CustomizedDialogs>
                            <div className="col-md-8">
                                <div className="justify-right padding-top5">
                                    <button type="button" onClick={backToRefering} className="btn btn-secondary px-4 mr-3">{i18n.t("commons.cancel")}</button>
                                {checkPermission(permission_key_values_accountsetup.account_setup_referring_provider_modify,permission_key_values_accountsetup.account_setup_referring_provider_add) &&
                                <button type="submit" className="btn btn-primary-blue">{i18n.t("commons.save")}</button>}
                                </div>
                            </div>

                        </div>
                        </Form>
                    </div>  
                </div>  
            </div>      
            <CustomizedDialogs showModal={showNPIModalWindow} type="save" header={i18n.t("userPages.practices.labelSearchNPI")} setShowModalWindow={setShowNPIModalWindow} onHide={onHideNPI} resetForm={onHideNPI} selectNPIBtn={false} hideSave={true} marginLeftValue="30px" marginTopValue="100px" >
                <NPISearch setSelectedNPI={setSelectedNPI} setProviderType={setProviderType} setProviderTypeLabel={setProviderTypeLabel} searchNPIName={lastName} searchNPIFirstName={firstName} providerType={providerType} npi={npi} />
            </CustomizedDialogs>
        </div >
    )
}
