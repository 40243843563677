import { configureStore } from "@reduxjs/toolkit";
import reportResultSlice from "./reports/reportResultSlice";
import reportViewerSlice from "./reports/reportViewerSlice";
import allReportListSlice from "./reports/builder/allReportListSlice";
import patientInfoSlice from "./patient/patientInfoSlice";
import patientBillingSlice from "./patient/PatientBillingInfo/patientBillingSlice";
import statementManagementSlice from "../components/patientManagement/StatementManagement/StatementManagmentRedux/statementManagementSlice";
import ChargesDebitSlice from "../components/reports/ChargesDebit/ChargesDebitSlice";
import PaymentAdjustmentSlice from "../components/reports/PaymentAdjustment/PaymentAdjustmentSlice";
import OpenArReportSlice from "../components/reports/OpenARReport/openArSlice";
import TransactionReportSlice from "../components/reports/TransactionReport/TransactionReportSlice";
import CheckLevelReportSlice  from "../components/reports/CheckLevelReport/CheckLevelReportSlice";
import reportSlice from "../components/reports/ReportFilter/reportFilterSlice";
import reportStatusManagerSlice from "../components/reports/reportStatusManagerSlice"
import arClaimsSlice from "../components/ClaimsModule/ARManagement/ARClaimsSlice"
import commonStateSlice from "./CommonStateSlice";
import claimManagementSlice from "../components/ClaimsModule/claimManagementSlice/claimManagementSlice";
import claimSelectInputSlice from "../components/ClaimsModule/claimManagementSlice/claimSelectInputSlice";
import claimSaveManagementSlice from "../components/ClaimsModule/claimManagementSlice/saveManagementSlice";
import claimFilterSlice from "../components/ClaimsModule/claimManagementSlice/CLaimFilterSlice/claimFilterSlice";
import patientAdvanceSearchSlice from "./patient/PatientAdvanceSearch/PatientAdvanceSearchSlice";
import BatchEligibilitySlice from "../components/patientManagement/RunEligibility/BatchEligibilitySlice";
import chatBotSlice from "../components/ChatBot/ChatBotSlice/ChatBotSlice";
import taskSlice from "../components/MyTasksScreen/TaskSlice/taskSlice";
import importBatchSlice from "../components/patientManagement/RunEligibility/importBatchSlice";
import paperClaimSlice from "../components/ClaimsModule/claimManagementSlice/paperClaimSlice/paperClaimSlice";
import claimManagementSlice1 from "../components/ClaimModuleNew/StateManagement/ClaimModuleSlice"
import DropDownOptionSlice from "../components/ClaimModuleNew/StateManagement/DropDownOptionSlice";
import dashboardReducer from "../components/dashboard/StateManagement/DashboardSlice";
import paymentSlice from "../components/Payments/PaymentModule/StateManagement/paymentSlice"


/**
 * reportViewer, reportResult & allReports are not in use, this may need in future when implementing dynamic report generation!
 */

export const store = configureStore({
  reducer: {
    commonStates: commonStateSlice,
    reportViewer: reportViewerSlice,
    reportResult: reportResultSlice,
    allReports: allReportListSlice,
    patientData: patientInfoSlice,
    arClaimsData: arClaimsSlice,
    claimData: claimManagementSlice,
    otherBillingData: patientBillingSlice,
    claimSelectInputData: claimSelectInputSlice,
    claimSaveManagmentData: claimSaveManagementSlice,
    statementMgData: statementManagementSlice,
    chargeDebitReport: ChargesDebitSlice,
    paymentAdjustmentReport: PaymentAdjustmentSlice,
    openArReport: OpenArReportSlice,
    transactionReport: TransactionReportSlice,
    checkLevelReport: CheckLevelReportSlice,
    filterDropdownData: reportSlice,
    mainReportStatus: reportStatusManagerSlice,
    claimFilter: claimFilterSlice,
    patientAdvanceSearchData: patientAdvanceSearchSlice,
    batchEligibilityTab2: BatchEligibilitySlice,
    chatboxData: chatBotSlice,
    taskData: taskSlice,
    importBatch: importBatchSlice,
    paperclaim: paperClaimSlice,
    claimManagement: claimManagementSlice1,
    claimSelectInputManager: DropDownOptionSlice,
    dashboard: dashboardReducer,
    paymentData: paymentSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(), //MIDDLEWARE TO LOG ACTIONS AND VALUES
});
