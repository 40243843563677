import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { format } from "date-fns";
import moment from 'moment';
import CommonButton from "../../commons/Buttons";

import "./style.css";
import service from "./service";
import PatientInfo from "./patientInfo";
import PatientInsuranceInfo from "./PatientInsuranceInfo";
import PatientAddAlert from "./PatientAddAlert";
import PatientAddNotes from "./PatientAddNotes";
import PatientAccountSummary from "./PatientAccountSummary";
import OtherBilling from "./OtherBilling";

import LoadingContext from "../../../container/loadingContext";

import {
  DEFAULT_PAGING_SIZE,
  TAB1,
  TAB2,
  TAB3,
  TAB4,
  TAB5,
  TAB6,
  PATIENT_TYPE_SELF_PAY,
  SSN_VALID_LENGTH,
  SSN_VALID_LENGTH_SMALL,
  TAB7,
} from "../../../utilities/staticConfigs";
import {
  ADD_SUCCESS,
  ADD_ERROR,
  UPDATE_SUCCESS,
} from "../../../utilities/labelConfigs";
import i18n from "../../../utilities/i18n";
import { GENDERS } from "../../../utilities/dictionaryConstants";
import { getStorage } from "../../../utilities/browserStorage";
import { ValidateEmail, checkPermission } from "../../../utilities/commonUtilities";

import Notify from "../../commons/notify";
import TextInput from "../../commons/input/input";
import CalendarInput from "../../commons/input/CalendarInput";
import SelectInput from "../../commons/input/SelectInput";
import WarningPopUp from "../../commons/warningPopUp";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientAddDetails,
  getpatientInfo,
  getRenderingProviders,
  updatePatientInfo,
} from "../../../store/patient/patientInfoSlice";
import { customer_admin_privilege, permission_key_values_patient, super_admin_privileges } from "../../../utilities/permissions";
import { Divider } from "@mui/material";
import FileManagement from "../../commons/FileMangement/FileMangement";

const PatientsAdd = (props) => {
  const [activeTab, setActiveTab] = useState(TAB1);
  const [saveSubmitted, setSaveSubmitted] = useState(false);
  const [patientInfoApiData, setPatientInfoApiData] = useState({});
  const [otherBillingInfoApiData, setOtherBillingInfoApiData] = useState({});
  const [diagnosisesValidated, setDiagnosisesValidated] = useState(true);
  const [diagnosisduplicated,setDiagnosisduplicated] = useState(true);
  const [cptduplicated,setCptduplicated] = useState(true);
  const [cptValidated, setCptValidated] = useState(true);
  const [patientEditable, setPatientEditable] = useState(true);
  const [validSSN, setValidSSN] = useState(true);
  const [accidentNoId, setAccidentNoId] = useState("");
  const [patientPK, setPatientPK] = useState("");
  const dispatch = useDispatch();

  const patientInfoDetails = useSelector(
    (state) => state.patientData.patientAddDetails
  );
  const patientInfoDataFromRedux = useSelector((state)=>state?.patientData?.patientInfoDetails);
  const isLoading = useSelector(state => state.patientData.loading);

  const otherBillingData = useSelector((state) => state?.otherBillingData?.OtherBillingBasicData)

  const accidentDate = otherBillingData[0]?.patientBillingBasicData?.accident_date;
  const admissionDate = otherBillingData[0]?.patientBillingBasicData?.admission_date;
  const initialTreatmentDate = otherBillingData[0]?.patientBillingBasicData?.initial_treatment_date;
  const lastMensturalDate = otherBillingData[0]?.patientBillingBasicData?.last_menstural_date;

  const renderingProviders = useSelector(
    (state) => state.patientData.renderingProviderList
  );

  const selectedPks = useSelector((state) => state.patientData.pks);


  // // Form Warning Data Array from Rdux -- Form Input warnings from all the tabs are in this state
  const formWarnings = useSelector(state => state.patientData.patientFormWarnings[props.patientPK || patientPK || "model"]);

  const [dateError, setDateError] = useState(false);

  const [patientAddVariables, setPatientAddVariables] = useState({
    locked_by_name: "",
    locked: false,
    patient_editable: true,
    patient_inactive: false,
    profile_locked: "",
    patient: "",
    custom_patient_id: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    suffix: "",
    gender: "",
    date_of_birth: "",
    date_of_death: "",
    patient_type: "",
    ssn: "",
    other_id: "",
    active: true,
    detail_ids: {
      patient_info: "",
      billing_info: "",
    },
  });

  // Array list
  const genderList = GENDERS;
  const patientTypeList = useSelector((state) => state.patientData.dropDownListData.patientTypesList);

  const patientBasicData = "";
  const practicePK = getStorage("practice");

  const activePage = 1;

  //start ==== Alert message properties
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const setShowLoadingBar = useContext(LoadingContext);
  const [isSaved, setIsSaved] = useState(false);
  const [patientLocationData, setPatientLocationData] = useState({});
  const [inactivePatientModal, setInactivePatientModal] = useState(false);
  const [activePatientModal, setActivePatientModal] = useState(false);

  const [patientInfoValidEmail, setPatientInfoValidEmail] = useState(true);
  const [patientInfoValidEmergencyEmail, setPatientInfoValidEmergencyEmail] =
    useState(true);
  const [patientInfoValidGuarantorEmail, setPatientInfoValidGuarantorEmail] =
    useState(true);

  // showInsuranceAddnew USED TO SHOW/HIDE "Add New" IN INSURANCE, BASED ON PATIENT TYPE "Self-Pay Patient" 06Oct2021
  const [showInsuranceAddnew, setShowInsuranceAddnew] = useState(true);
  useEffect(() => {
    let showInsAddNew = true;
    if (patientTypeList && Array.isArray(patientTypeList)) {
      patientTypeList.forEach((item) => {
        if (
          patientAddVariables.patient_type == item.id &&
          item.name &&
          item.name.trim().toLowerCase() == PATIENT_TYPE_SELF_PAY
        ) {
          showInsAddNew = false;
        }
      });
      setShowInsuranceAddnew(showInsAddNew);
    } else {
      setShowInsuranceAddnew(showInsAddNew);
    }
  }, [patientAddVariables.patient_type]);

  const [inactivePatientAlertModal, setInactivePatientAlertModal] =
    useState(false);

  const [patientHasActiveInsurance, setPatientHasActiveInsurance] = useState(0);

  const getPatientInsuranceDetails = async () => {
    const response = await service.ListInsuranceInfos(props.patientPK);
    if (response.data?.length)
      setPatientHasActiveInsurance(response.data.length);
    else
      setPatientHasActiveInsurance(0);
  };

  // Flag to re-render form warning Component
  const [formWarningStatus, setFormWarningStatus] = useState(false);

  const checkFormWarning = (name, value) => {
    const node = "patientAdd" // Current Tab name
    // Dispatching key, value and current tab
    dispatch({ type: "patientInfo/setPatientFormWarnings", payload: { name, value, node, patientPK: patientInfoDetails.id || "" } });
    setFormWarningStatus(!formWarningStatus); // Once dispatch is executed re-rendering the warnings component
  };

  function showNotifyWindow(action, type, desc, age = 4000) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }
  //End ====

  function onListData(e) {
    e.preventDefault();
  }

  function getPatientTypes(pageSize) {
    let pageNum = 0;
    const result = service.ListPatientTypes(
      pageSize,
      pageNum,
      practicePK,
      props.patientPK
    );
    result.then((response) => {
      dispatch({ type: "patientInfo/updatePatientDropdownData", payload: { dropDownListDataKey: "patientTypesList", dropDownListDataValue: response.data } });
    });
  }

  useEffect(() => {
    getPatientTypes(DEFAULT_PAGING_SIZE, activePage);
    getPatientInsuranceDetails(); // For warnings   
  }, []);

  useEffect(() => {
    checkFormWarning("insuranceDetails", patientHasActiveInsurance)

  },[patientHasActiveInsurance])

  //loadingBar rendering
  useEffect(() => {
    if (isLoading) setShowLoadingBar(true)
    else setShowLoadingBar(false)
  }, [isLoading])

  useEffect(() => {
    if (
      props.patientPK &&
      activeTab !== "listPatients" &&
      activeTab !== "addNewPatient"
    ) {
      setActiveTab(props.activeSubTab);

      /**
       * checking patient pk already exist in selectedPks
       */
      if (!selectedPks.includes(props.patientPK)) {
        /**
         *if patientPK not includes selectedPk getPatientAddDetails()
         *@param  {*} props.patientPK
         *@param  {*} node
         *@param  {*} props.activeSubTab
         */
        let data = {
          pk: props.patientPK,
          node: "patientAddVariables",
          activeSubTab: props.activeSubTab,
        };
        dispatch(getPatientAddDetails(data));
        dispatch(getRenderingProviders(props.patientPK))
      } else {
        /**
         *if patientPK includes selectedPk getpatientInfo()
         * @param {*} props.patientPK
         *@param {*} node
         */
        let dataObject = { pk: props.patientPK, node: "patientAddVariables" };
        dispatch(getpatientInfo(dataObject));
      }
    } 
    else {
      dispatch(getRenderingProviders(props.patientPK))
    }
  }, [props.activeTab, props.activeSubTab]);


  /**
   * setting patieninfovariable in state setPatientAddVariable on selector value change (patientInfoDetails)
   */
  useEffect(() => {
    if (patientInfoDetails) {

      setPatientAddVariables({
        ...patientAddVariables,
        custom_patient_id: patientInfoDetails.custom_patient_id,
        date_of_birth: patientInfoDetails?.date_of_birth
          ? new Date(patientInfoDetails?.date_of_birth + " 00:00:00")
          : "",
        date_of_death: patientInfoDetails?.date_of_death
          ? new Date(patientInfoDetails?.date_of_death + " 00:00:00")
          : "",
        detail_ids: patientInfoDetails.detail_ids,
        first_name: patientInfoDetails.first_name,
        gender: patientInfoDetails.gender,
        last_name: patientInfoDetails.last_name,
        locked_by_name:
          patientInfoDetails.locked &&
            patientInfoDetails.locked_by ===
            JSON.parse(getStorage("userData")).profile_id
            ? i18n.t("commons.self")
            : patientInfoDetails.locked_by_name,
        locked: patientInfoDetails.locked,
        middle_name: patientInfoDetails.middle_name,
        active: patientInfoDetails.active,
        patient_editable:
          patientInfoDetails.active &&
            (!patientInfoDetails.locked ||
              (patientInfoDetails.locked &&
                patientInfoDetails.locked_by ===
                JSON.parse(getStorage("userData")).profile_id &&
                patientInfoDetails.unid ===
                JSON.parse(getStorage("userData")).unid))
            ? true
            : false,
        patient_inactive: patientInfoDetails.active ? false : true,
        patient_type: patientInfoDetails.patient_type,
        patient: patientInfoDetails.id,
        profile_locked:
          patientInfoDetails.locked &&
            patientInfoDetails.locked_by !==
            JSON.parse(getStorage("userData")).profile_id &&
            patientInfoDetails.unid !== JSON.parse(getStorage("userData")).unid
            ? true
            : false,
        ssn: patientInfoDetails.ssn,
        suffix: patientInfoDetails.suffix,
        other_id: patientInfoDetails.other_id,
      });

      /**
       * checking for mandatory field warings o
       * @param {*} field
       * @param {*} value
       */
      if (patientInfoDataFromRedux && patientInfoDataFromRedux.location_data) {
        checkFormWarning("street1", patientInfoDataFromRedux.location_data.street1);
        checkFormWarning("city", patientInfoDataFromRedux.location_data.city);
        checkFormWarning("state", patientInfoDataFromRedux.location_data.state);
        checkFormWarning("zip", patientInfoDataFromRedux.location_data.zip_code);
      }
      checkFormWarning("rendering_provider",patientInfoDataFromRedux?.rendering_provider );

      checkFormWarning("lastName", patientInfoDetails.last_name);
      checkFormWarning("firstName", patientInfoDetails.first_name);
      checkFormWarning("gender", patientInfoDetails.gender);
      checkFormWarning("DOBDate", patientInfoDetails.date_of_birth);
      // Incase of patient_type need to identify whether its Self-Payer or other patient types
      // If Self-Pay Insurance warning ins not required
      const patientTypeName = patientTypeList.find(item => item.id === patientInfoDetails.patient_type)?.name;
      checkFormWarning("patientType", patientTypeName);
      if (patientTypeName) {
        if (patientTypeName === "Self-Pay Patient" || (patientTypeName !== "Self-Pay Patient" && patientHasActiveInsurance > 0)) checkFormWarning("insuranceDetails", "Insurance Added");
        if (patientTypeName !== "Self-Pay Patient" && patientHasActiveInsurance === 0) checkFormWarning("insuranceDetails", "");
      }
      setPatientEditable(
          !patientInfoDetails.locked ||
            (patientInfoDetails.locked &&
              patientInfoDetails.locked_by ===
              JSON.parse(getStorage("userData")).profile_id &&
              patientInfoDetails.unid ===
              JSON.parse(getStorage("userData")).unid)
          ? true
          : false
      );
    }
  }, [patientInfoDetails]);

  function onTabChange(e, tab) {
    if (props.patientPK) {
      let item = {
        pk: props.patientPK,
        type: "patients",
        action: "add",
        practice_pk: practicePK,
        active_tab: tab,
      };
      props.addRemoveFromSelectedTab(item, true);
    }
    setActiveTab(tab);
  }

  function onHandleDate(value, name) {
    if (name === "date_of_birth") {
      setPatientAddVariables({
        ...patientAddVariables,
        date_of_birth: value,
      });
      checkFormWarning("DOBDate", value);
    } else if (name === "date_of_death") {
      setPatientAddVariables({
        ...patientAddVariables,
        date_of_death: value,
      });
    }
    // format the date and save to Redux
    const formattedDateForRedux = value instanceof Date ? moment(value).format("MM/DD/YYYY") : null;
    if (formattedDateForRedux != 'Invalid date' && props.activeTab != "listLast10Patients") {
      dispatch(
        updatePatientInfo({
          patientPK: props.patientPK,
          patientAddVariables: {
            [name]: moment(value).format("MM/DD/YYYY"),
          },
        })
      );
    }
  }

  function onHandleChangeSSN(e) {
    const name = e.target.name;
    let value = String(e.target.value.trim())
      .replace(/[^\d]/g, "")
      .substr(0, 9);
    setPatientAddVariables({
      ...patientAddVariables,
      [name]: value,
    });
    if (
      value.length !== SSN_VALID_LENGTH &&
      value.length !== SSN_VALID_LENGTH_SMALL
    ) {
      setValidSSN(false);
    }
    if (
      !value ||
      value.length === SSN_VALID_LENGTH ||
      value.length === SSN_VALID_LENGTH_SMALL
    ) {
      setValidSSN(true);
    }
    if (props.activeTab != "listLast10Patients") {
      dispatch(
        updatePatientInfo({
          patientPK: props.patientPK,
          patientAddVariables: {
            [name]: value,
          },
        })
      );
    }
  }

  function onHandleChange(e) {
    const name = e.target.name;
    let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    if (name === 'last_name' || name === 'first_name' || name === 'middle_name') {
      value = value.replace(/[^A-Za-z0-9.\s]/g, '')
    }
    if (name === "patient_type" && value != "") {
      const patientTypeName = patientTypeList.find(item => item.id === value)?.name;
      if (patientTypeName === "Self-Pay Patient") {
        checkFormWarning(name, patientTypeName);
        setFormWarningStatus(!formWarningStatus);
      } else {
        checkFormWarning(name, patientHasActiveInsurance);
        setFormWarningStatus(!formWarningStatus);
      }
    } else {
      checkFormWarning(name, value)
    }
    setPatientAddVariables({
      ...patientAddVariables,
      [name]: value,
    });
    if (props.activeTab != "listLast10Patients") {
      dispatch(
        updatePatientInfo({
          patientPK: props.patientPK,
          patientAddVariables: {
            [name]: value,
          },
        })
      );
    }
  }

  function resetForm() {
    setPatientAddVariables({
      ...patientAddVariables,
      patient_inactive: false,
      custom_patient_id: "",
      first_name: "",
      last_name: "",
      suffix: "",
      gender: "",
      patient_type: "",
      ssn: "",
      date_of_birth: "",
      date_of_death: "",
      other_id: "",
    });
  }

  function ValidateError(rendering_provider_id) {
    if (
      (props.patientPK && !rendering_provider_id) ||
      !patientAddVariables.last_name ||
      !patientAddVariables.first_name ||
      !patientAddVariables.gender ||
      !patientAddVariables.patient_type ||
      !patientAddVariables.date_of_birth
    ) {
      return true;
    }

    if (patientInfoApiData.email) {
      if (!ValidateEmail(patientInfoApiData.email)) {
        setPatientInfoValidEmail(false);
        return true;
      } 
    }
    if (patientInfoApiData.guarantor_email) {
      if (!ValidateEmail(patientInfoApiData.guarantor_email)) {
        setPatientInfoValidGuarantorEmail(false);
        return true;
      } 
    }
    if (patientInfoApiData.emergency_email) {
      if (!ValidateEmail(patientInfoApiData.emergency_email)) {
        setPatientInfoValidEmergencyEmail(false);
        return true;
      } 
    }

    if (
      patientAddVariables.ssn &&
      patientAddVariables.ssn.length !== SSN_VALID_LENGTH &&
      patientAddVariables.ssn.length !== SSN_VALID_LENGTH_SMALL
    ) {
      return true;
    }

    if (!diagnosisesValidated) {
      // IF DIAGNOSIS CODES NOT ADDED IN ORDER, IT WILL NOT ALLOW TO SAVE
      // e.g. IF dx1 FILLED, dx2 BLANK, AND ANY dx3 TO dx12 FILLED, IT WILL GIVE ERROR
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.invalid_diagonsis_order")
      );
      return true;
    }


    if (!diagnosisduplicated) {
      // IF DIAGNOSIS CODES NOT ADDED IN ORDER, IT WILL NOT ALLOW TO SAVE
      // e.g. IF dx1 FILLED, dx2 BLANK, AND ANY dx3 TO dx12 FILLED, IT WILL GIVE ERROR
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.duplicate_diagnosis_item")
      );
      return true;
    }

    if (!cptduplicated) {
      // IF CPT CODES NOT ADDED IN ORDER OR DUPLICATE CPT, IT WILL NOT ALLOW TO SAVE
      // e.g. IF cpt1 FILLED, cpt2 BLANK, AND ANY cpt3 TO cpt12 FILLED, IT WILL GIVE ERROR
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.duplicate_cpt_item")
      );
      return true;
    }
    if (!cptValidated) {
      // IF CPT CODES NOT ADDED IN ORDER OR DUPLICATE CPT, IT WILL NOT ALLOW TO SAVE
      // e.g. IF cpt1 FILLED, cpt2 BLANK, AND ANY cpt3 TO cpt12 FILLED, IT WILL GIVE ERROR
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.invalid_cpt_codes")
      );
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (isSaved) {
      let item = {
        pk:"add_new",
        type: "patients",
        action: "remove",
        practice_pk: practicePK,
      };
      props.addRemoveFromSelectedTab(item, true);
      setIsSaved(false);
    }
  },[isSaved])

  async function onSaveFormData(e, isClose) {
    e.preventDefault();

    let deathDate;
    if (patientAddVariables.date_of_death) {
      deathDate = format(patientAddVariables.date_of_death, "yyyy-MM-dd");
    }

    if (deathDate) {
      if (
        (initialTreatmentDate && initialTreatmentDate > deathDate) ||
        (admissionDate && admissionDate > deathDate) ||
        (accidentDate && accidentDate > deathDate) ||
        (lastMensturalDate && lastMensturalDate > deathDate)
      ) {
        showNotifyWindow(
          "show",
          "error",
          i18n.t("commons.deathDateValidation")
        );
        return;
      }
    }

    let structuredData = patientInfoApiData?.location?.street1 
    ? patientInfoApiData 
    : patientInfoDataFromRedux;

    const isTab1 = activeTab === TAB1;

    const locationData = isTab1 ? structuredData?.location : structuredData?.location_data;
    const providerId = isTab1 ? structuredData?.rendering_provider_id : structuredData?.rendering_provider;

    if (locationData) {
      const { street1, city, state, zip_code } = locationData;

      if (!street1 || !city || !state || !zip_code || !providerId) {
        return;
      }
    }

      let rendering_provider_id = patientInfoApiData.rendering_provider_id
        ? patientInfoApiData.rendering_provider_id
        : renderingProviders.length > 0 ? renderingProviders[0].id : "";
      let result = "";
      let data = {};
      if (ValidateError(rendering_provider_id)) {
        return true
      }

      data = await createFormData()
      if (!props.patientPK) {
        result = service.SaveNewPatient(data);
        setShowLoadingBar(true);
      } else if (props.patientPK) {

        // Create mutable copies of the objects before modifying them
        data.patient_info = { ...data.patient_info };  // Shallow copy
        data.billing_info = { ...data.billing_info };

        data.patient_info["pk"] = patientAddVariables.detail_ids.patient_info;
        data.patient_info["active"] = !patientAddVariables.patient_inactive; 
        data.patient_info["rendering_provider_id"] = rendering_provider_id;
        data.billing_info["pk"] = patientAddVariables.detail_ids.billing_info;
        result = service.UpdatePatient(props.patientPK, data);
        result.then((response) => {
          if (
            (response.status === 400) &&
            (response.data.error === "zip_phone_validation_error")
          ) {
            showNotifyWindow("show", "error", response.data.message);
          }
          if (data.patient_info.active === false)
             dispatch({ type: 'patientInfo/resetPatientDataOnTabClose', payload: { pk: props.patientPK } }); // Dispatch the patientPK to redux to remove its data from state
        });
        setShowLoadingBar(true);
      }
      
    result.then((response) => {
      if (!props.patientPK) {
        setPatientPK(response.data.id);
        let item = {
          pk: response.data.id,
          type: "patients",
          action: "add",
          practice_pk: practicePK,
        };
        if (response.status == 400) {
          props.addRemoveFromSelectedTab(item, false);
        } else {
          props.addRemoveFromSelectedTab(item, true);
          setIsSaved(true)
        }
      } else {
        if (isClose && response.status === 400) {
          let item = {
            pk: props.patientPK,
            type: "patients",
            action: "remove",
            practice_pk: practicePK,
          };
          props.addRemoveFromSelectedTab(item, false);
        } else if (isClose) {
          let item = {
            pk: props.patientPK,
            type: "patients",
            action: "remove",
            practice_pk: practicePK,
          };
          props.addRemoveFromSelectedTab(item, true);
          setIsSaved(true)
        }
        else {
          let item = {
            pk: props.patientPK,
            type: "patients",
            action: "add",
            practice_pk: practicePK,
          };
          props.addRemoveFromSelectedTab(item, true);
        }
      }
      setShowLoadingBar(false);
      responseHandler(response)
      setInactivePatientModal(false);
    });

  }

  //api data generation
  function createFormData() {
    let {
      first_name,
      middle_name,
      last_name,
      suffix,
      gender,
      date_of_birth,
      date_of_death,
      patient_type,
      ssn,
      other_id,
    } = patientAddVariables;
    let patientInfoData; 
    if (patientInfoApiData.location?.street1?.length > 0 && patientInfoApiData?.location?.zip_code?.length > 0){
      patientInfoData = {
        ...patientInfoApiData,
        service_location: {
          id: "51",
          location_type: "practice"
        }
      }; 
    } else {

      patientInfoData = {
        guarantor_relationship_id: patientInfoDataFromRedux.guarantor_relationship,
        emergency_relationship_id: patientInfoDataFromRedux.emergency_relationship,
        authorized_contact: patientInfoDataFromRedux.authorized_contact,
        location: {
          street1: patientInfoDataFromRedux?.location_data?.street1,
          street2: patientInfoDataFromRedux?.location_data?.street2,
          zip_code: patientInfoDataFromRedux?.location_data?.zip_code,
          city: patientInfoDataFromRedux?.location_data?.city,
          state: patientInfoDataFromRedux?.location_data?.state,
          country: patientInfoDataFromRedux?.location_data?.country
        },
        guarantor_location: {
          street1: patientInfoDataFromRedux?.guarantor_location_data?.street1,
          street2: patientInfoDataFromRedux?.guarantor_location_data?.street2,
          zip_code: patientInfoDataFromRedux?.guarantor_location_data?.zip_code,
          city: patientInfoDataFromRedux?.guarantor_location_data?.city,
          state: patientInfoDataFromRedux?.guarantor_location_data?.state,
          country: patientInfoDataFromRedux?.guarantor_location_data?.country
        },
        emergency_location: {
          street1: patientInfoDataFromRedux?.emergency_location_data?.street1,
          street2: patientInfoDataFromRedux?.emergency_location_data?.street2,
          zip_code: patientInfoDataFromRedux?.emergency_location_data?.zip_code,
          city: patientInfoDataFromRedux?.emergency_location_data?.city,
          state: patientInfoDataFromRedux?.emergency_location_data?.state,
          country: patientInfoDataFromRedux?.emergency_location_data?.country
        },
        cell_phone: patientInfoDataFromRedux?.cell_phone,
        work_phone: patientInfoDataFromRedux?.work_phone,
        home_phone: patientInfoDataFromRedux?.home_phone,
        service_location: {
          id: "51",
          location_type: "practice"
        },
        guarantor_work_phone: patientInfoDataFromRedux?.guarantor_work_phone,
        guarantor_cell_phone: patientInfoDataFromRedux?.guarantor_cell_phone,
        emergency_home_phone: patientInfoDataFromRedux?.emergency_home_phone,
        emergency_work_phone: patientInfoDataFromRedux?.emergency_work_phone,
        emergency_cell_phone: patientInfoDataFromRedux?.emergency_cell_phone,
        email: patientInfoDataFromRedux?.email,
        ethnicity: patientInfoDataFromRedux?.ethnicity,
        race: patientInfoDataFromRedux?.race,
        preferred_language_id: patientInfoDataFromRedux?.preferred_language,
        rendering_provider_id: patientInfoDataFromRedux?.rendering_provider,
        referring_provider_id: patientInfoDataFromRedux?.referring_provider,
        referring_provider_group_id: patientInfoDataFromRedux?.referring_provider_group,
        guarantor_last_name: patientInfoDataFromRedux?.guarantor_last_name,
        guarantor_first_name: patientInfoDataFromRedux?.guarantor_first_name,
        guarantor_middle_name: patientInfoDataFromRedux?.guarantor_middle_name,
        guarantor_home_phone: patientInfoDataFromRedux?.guarantor_home_phone,
        guarantor_email: patientInfoDataFromRedux?.guarantor_email,
        emergency_last_name: patientInfoDataFromRedux?.emergency_last_name,
        emergency_first_name: patientInfoDataFromRedux?.emergency_first_name,
        emergency_middle_name: patientInfoDataFromRedux?.emergency_middle_name,
        emergency_suffix: patientInfoDataFromRedux?.emergency_suffix,
        emergency_email: patientInfoDataFromRedux?.emergency_email,
        emergency_as_guarantor: patientInfoDataFromRedux?.emergency_as_guarantor,
        guarantor_address_as_patient: patientInfoDataFromRedux?.guarantor_address_as_patient,
        guarantor_suffix: patientInfoDataFromRedux?.guarantor_suffix,
        active: patientInfoDataFromRedux?.active
      };
    }

    let data = {
      first_name,
      middle_name,
      last_name,
      suffix,
      gender,
      date_of_birth: date_of_birth
        ? format(date_of_birth, "yyyy-MM-dd")
        : null,
      date_of_death: date_of_death
        ? format(date_of_death, "yyyy-MM-dd")
        : null,
      patient_type,
      ssn,
      other_id,
      practice: getStorage("practice"),
      patient_info: patientInfoData,
      billing_info: otherBillingInfoApiData,
    }
    return data
  }

  //response handled here
  function responseHandler(response) {
    if (response.status === 201 || response.status === 200) {
      if (response.status === 201) {
        showNotifyWindow("show", "success", ADD_SUCCESS);
        resetForm();
        setIsSaved(true)
        setTimeout(() => {
          props.patientListTab("editPatient " + response.data.id);
        }, 1750);
      } else showNotifyWindow("show", "success", UPDATE_SUCCESS);
    } else if (response.status === 405) {
      if (response.data.message === "patient_inactive")
        setInactivePatientAlertModal(true);
      else if (response.data.message === "patient_locked")
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.patient_locked")
        );
    } else if (response.status === 400) {
      if (response.data.message === "unique_patient"){
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.unique_patient")
        )
      } else if (response.data.error_message === "invalid_required_fields") {
        showNotifyWindow(
          "show",
          "error",
          "Please fill all the required fields."
        );
      }
    } else {
      showNotifyWindow("show", "error", ADD_ERROR);
    }
  }

  function onInactivePatient(e) {
    e.preventDefault();
    setInactivePatientModal(true);
  }

  function onActiveReloadPatient() {
    let item = {
      pk: props.patientPK,
      type: "patients",
      action: "add",
      practice_pk: practicePK,
    };
    props.addRemoveFromSelectedTab(item, true);
  }


  function onAlertOk() {
    setInactivePatientAlertModal(false);
  }

  function ActivatePatient() {
    const active_result = service.UpdatePatientActive(props.patientPK, {
      active: props.patientInactive,
    });
    active_result.then(() => {
      setActivePatientModal(false);
      showNotifyWindow("show", "success", i18n.t("patientPages.patients.patientStatusActivated") );

      // Mark the Patient as active in the Redux
      dispatch({ type: "patientInfo/activatePatient", payload: { patientPK: props.patientPK } });
      // Mark the Patient as editable in the local state
      setPatientAddVariables({
        ...patientAddVariables,
        patient_inactive: false,
        patient_editable: true
      });
      onActiveReloadPatient(props.patientPK);
    });
  }

  useEffect(() => {
    if (!patientAddVariables.patient_inactive && !patientAddVariables.active)
      setActivePatientModal(true);
  }, [patientAddVariables.patient_inactive]);

  function onHideActivatePatient() {
    if (!patientAddVariables.patient_inactive) {
      // if cancel button clicked then continue the inactive status
      setPatientAddVariables({
        ...patientAddVariables,
        patient_inactive: true,
      });
    }
  }

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      
      {((!patientAddVariables.patient_editable && checkPermission(permission_key_values_patient.patients_patients_list_add, permission_key_values_patient.patients_patients_list_modify)) && !patientAddVariables.patient_inactive) ||
            (patientInfoDetails.unid !== JSON.parse(getStorage("userData"))?.unid && patientAddVariables.patient_inactive) ? 
            <div className="padding-left15">
              <div className="warning-patient-div">
                <span className={"warning_lock"}>
                  <span className={""}>
                    {i18n.t("commons.locking")} :{" "}
                    <span className="font-bold">
                      {patientAddVariables.locked_by_name}
                    </span>
                  </span>
                </span>
              </div>
            </div>
            : null
          }
      <div className="margin-left15">    
          {patientAddVariables.custom_patient_id && formWarnings && (

              <WarningPopUp
                type="patient"
                notifyDescription={i18n.t("warning.incompleteData")}
                formWarningData={formWarnings}
                formWarningStatus={formWarningStatus}
                warningText={i18n.t("warning.warningPatient")}
          />
        )}
      </div>
      <div className={" " + props.patientPK}>
        <Form id="patient_info_form" onSubmit={(e) => onListData(e)} autoComplete="off">
          <div className="pr-pl-15" >
            <div className="row padding-top20">
              {patientAddVariables.custom_patient_id && (
                <div className="col-3">
                  <div className="form-group padding-top">
                    <TextInput
                      id="patient-id"
                      type="text"
                      name="custom_patient_id"
                      label={i18n.t("patientPages.addPatient.labelPatientID")}
                      required={true}
                      onValueChange={onHandleChange}
                      value={patientAddVariables.custom_patient_id}
                      disabled
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  patientAddVariables.custom_patient_id ? "col-3 " : "col-3"
                }
              >
                <div className="form-group padding-top">
                  <TextInput
                    type="text"
                    name="last_name"
                    id="last_name"
                    label={i18n.t("patientPages.addPatient.labelLastName")}
                    required={true}
                    onValueChange={onHandleChange}
                    value={patientAddVariables.last_name}
                  />
                </div>
              </div>
              <div className="col-3 ">
                <div className="form-group padding-top">
                  <TextInput
                    type="text"
                    name="first_name"
                    id="first_name"
                    label={i18n.t("patientPages.addPatient.labelFirstName")}
                    required={true}
                    onValueChange={onHandleChange}
                    value={patientAddVariables.first_name}
                  />  
                </div>
              </div>
              <div className="col-3 ">
                <div className="form-group padding-top">
                  <TextInput
                    type="text"
                    name="middle_name"
                    id="middle_name"
                    label={i18n.t("patientPages.addPatient.labelMiddleInitial")}
                    onValueChange={onHandleChange}
                    value={patientAddVariables.middle_name}
                  />
                </div>
              </div>
            </div>

            <div className="row padding-top20">
                <div className="col-3">
                  <div className="form-group padding-top">
                  <TextInput
                    type="text"
                    name="suffix"
                    id="suffix"
                    label={i18n.t("patientPages.addPatient.labelSuffix")}
                    onValueChange={onHandleChange}
                    value={patientAddVariables.suffix}
                  />
                  </div>
                </div>
              
              <div className="col-3 ">
                <div className="form-group padding-top">
                <SelectInput
                  data={genderList}
                  label="Gender"
                  name="gender"
                  id="gender"
                  value={patientAddVariables.gender}
                  required={true}
                  onValueChange={onHandleChange}
                />
                </div>
              </div>
              <div className="col-3 ">
                <div className="form-group padding-top">
                <CalendarInput
                  selected={patientAddVariables.date_of_birth}
                  required={true}
                  onValueChange={(selected) =>
                    onHandleDate(selected, "date_of_birth")
                  }
                  name="date_of_birth"
                  id="date_of_birth"
                  maxDate={new Date()}
                  label={i18n.t("patientPages.addPatient.labelDOB")}
                  disableFuture={true}
                />
                </div>
              </div>
              <div className="col-3 ">
                <div className="form-group padding-top">
                <CalendarInput
                  selected={patientAddVariables.date_of_death}
                  onValueChange={(selected) =>
                    onHandleDate(selected, "date_of_death")
                  }
                  name="date_of_death"
                  id="date_of_death"
                  minDate={patientAddVariables.date_of_birth}
                  label={i18n.t("patientPages.addPatient.labelDOD")}
                  disableFuture={true}
                  maxDate={new Date()}
                />
                </div>
              </div>
            </div>

            <div className="row ">
                <div className="col-3">
                  <div className="form-group padding-top">
                  <SelectInput
                  data={patientTypeList}
                  label={i18n.t("patientPages.addPatient.labelPatientType")}
                  required={true}
                  name="patient_type"
                  id="patient_type"
                  value={patientAddVariables.patient_type}
                  onValueChange={onHandleChange}
                />
                  </div>
                </div>
              
              <div className="col-3 ">
                <div className="form-group padding-top">
                <TextInput
                    type="text"
                    name="ssn"
                    id="ssn"
                    label={i18n.t("patientPages.addPatient.labelSSN")}
                    onValueChange={onHandleChangeSSN}
                    value={patientAddVariables.ssn}
                    defaultClassName={(patientAddVariables?.ssn?.length === SSN_VALID_LENGTH || patientAddVariables?.ssn?.length === SSN_VALID_LENGTH_SMALL || patientAddVariables?.ssn?.length === 0) ? "" : "input-error"}
                    helperText={validSSN ? "" : i18n.t("validations.errors.ssn")}
                  />
                </div>
              </div>
              <div className="col-3 ">
                <div className="form-group padding-top">
                  <TextInput
                    type="text"
                    name="other_id"
                    id="other_id"
                    label={i18n.t("patientPages.addPatient.labelOtherID")}
                    onValueChange={onHandleChange}
                    value={patientAddVariables.other_id}
                  />
                </div>
              </div>


              <div className="col-3 justify-right margin-top25">
                  
                    {checkPermission(permission_key_values_patient.patients_patients_list_add, permission_key_values_patient.patients_patients_list_modify) && patientAddVariables.patient_editable && patientAddVariables.custom_patient_id && (
                      <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{paddingRight:"24px"}}>
                          <CommonButton label="Save & Close" variant="outlined"
                          onClick={
                              patientAddVariables.patient_inactive
                                ? (e) => onInactivePatient(e)
                                : (e) => onSaveFormData(e, "close")
                            } /> 
                        </div>
                      <div>
                        <div className="justify-right">
                          <CommonButton label="Save" variant="contained" id="patient-save-parent-btn"
                            onClick={
                                patientAddVariables.patient_inactive
                                    ? (e) => onInactivePatient(e)
                                    : (e) => onSaveFormData(e)
                          } /> 
                          </div>
                      </div>
                </div>
                    )}
                  
              </div>
              
            </div>


            
          </div>
          <div className="">
            <div className=" padding-left5">
              <div className="box-head padding-left5">
                    <TabContext value={activeTab}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={onTabChange}
                          aria-label="lab API tabs example"
                          className="ml-1"
                        >
                        <Tab
                            label={i18n.t(
                              "patientPages.addPatient.labelPatientInfo"
                            )}
                            value={TAB1}
                            className={`tab patient-sub-${TAB1}`}
                          />
                        <Tab
                            label={i18n.t(
                              "patientPages.addPatient.labelInsuranceInfo"
                            )}
                            value={TAB2}
                            disabled={!patientAddVariables?.patient} 
                            className={`tab patient-sub-${TAB2} ${
                              !patientAddVariables?.patient ? "disabled-tab" : ""
                            }`}
                          />
                        <Tab
                            label={i18n.t(
                              "patientPages.addPatient.labelAccountSummary"
                            )}
                            value={TAB3}
                            disabled={!patientAddVariables?.patient}
                            className={`tab patient-sub-${TAB3} ${
                              !patientAddVariables?.patient ? "disabled-tab" : ""
                            } `}
                          />
                        <Tab
                            label={i18n.t(
                              "patientPages.addPatient.labelOtherBillingInfo"
                            )}
                            value={TAB4}
                            disabled={!patientAddVariables?.patient}
                            className={`tab patient-sub-${TAB4} ${
                              !patientAddVariables?.patient ? "disabled-tab" : ""
                            }`}
                          />
                        <Tab
                          label={i18n.t(
                            "patientPages.addPatient.labelAlerts"
                          )}
                          value={TAB5}
                          disabled={!patientAddVariables?.patient}
                          className={`tab patient-sub-${TAB5} ${
                            !patientAddVariables?.patient ? "disabled-tab" : ""
                          }`}
                          />
                        <Tab
                          label={i18n.t("patientPages.addPatient.labelNotes")}
                          value={TAB6}
                          disabled={!patientAddVariables?.patient}
                          className={`tab patient-sub-${TAB6} ${
                            !patientAddVariables?.patient ? "disabled-tab" : ""
                          }`}
                          />
                        <Tab
                          label={i18n.t("patientPages.addPatient.labelDocuments")}
                          value={TAB7}
                          disabled={!patientAddVariables?.patient}
                          className={`tab patient-sub-${TAB7} ${
                            !patientAddVariables?.patient ? "disabled-tab" : ""
                          }`}
                          />
                        </TabList>
                        <Divider style={{ color: '#000', marginBottom: 20,marginLeft:'5px' }} />

                      </Box>
                      <TabPanel className="p-0" value={TAB1}>
                        <div className="padding-left">
                          <PatientInfo
                            formWarningStatus={formWarningStatus}
                            setFormWarningStatus={setFormWarningStatus}
                            patientLocationData={patientLocationData}
                            setPatientLocationData={setPatientLocationData}
                            patientPK={patientAddVariables.patient}
                            patientInfoPK={
                              patientAddVariables.detail_ids.patient_info
                            }
                            saveSubmitted={saveSubmitted}
                            setPatientInfoApiData={setPatientInfoApiData}
                            setSaveSubmitted={setSaveSubmitted}
                            patientBasicData={patientBasicData}
                          onActiveReloadPatient={onActiveReloadPatient}
                            patientInactive={
                              patientAddVariables.patient_inactive
                            }
                            setPatientInacive={setPatientAddVariables}
                            patientInfoValidEmail={patientInfoValidEmail}
                            setPatientInfoValidEmail={setPatientInfoValidEmail}
                            patientInfoValidEmergencyEmail={
                              patientInfoValidEmergencyEmail
                            }
                            setPatientInfoValidEmergencyEmail={
                              setPatientInfoValidEmergencyEmail
                            }
                            patientInfoValidGuarantorEmail={
                              patientInfoValidGuarantorEmail
                            }
                            setPatientInfoValidGuarantorEmail={
                              setPatientInfoValidGuarantorEmail
                            }
                          />
                        </div>
                      </TabPanel>
                      <TabPanel className="p-0" value={TAB2}>
                        <div className="">
                          <PatientInsuranceInfo
                            setFormWarningStatus={setFormWarningStatus}
                            patientLocationData={patientLocationData}
                            patientPK={patientInfoDetails.id}
                            patientBasicData={patientBasicData}
                            patientEditable={patientEditable}
                            showInsuranceAddnew={showInsuranceAddnew}
                            patientDob={patientAddVariables.date_of_birth}
                            patientDod={patientAddVariables.date_of_death}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel className="p-0" value={TAB3}>
                        <div className="padding-left5">
                          <PatientAccountSummary
                            patientPK={patientAddVariables.patient}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel className="p-0" value={TAB4}>
                        <div className="padding-left5">
                          <OtherBilling
                            patientPK={patientAddVariables.patient}
                            patientBillingPK={
                              patientAddVariables.detail_ids.billing_info
                            }
                            practicePK={practicePK}
                            patientGender={patientAddVariables.gender}
                            setOtherBillingInfoApiData={
                              setOtherBillingInfoApiData
                            }
                            setDiagnosisesValidated={setDiagnosisesValidated}
                            setDiagnosisduplicated ={setDiagnosisduplicated}
                            setCptValidated={setCptValidated}
                            setCptduplicated ={setCptduplicated}
                            setAccidentNoId={setAccidentNoId}
                            accidentNoId={accidentNoId}
                            setDateError={setDateError}
                            dateError={dateError}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel className="p-0" value={TAB5}>
                        <div className="padding-left5">
                          <PatientAddAlert
                            patientPK={patientAddVariables.patient}
                            patientEditable={patientEditable}
                           />
                        </div>
                      </TabPanel>
                      <TabPanel className="p-0" value={TAB6}>
                        <div className="padding-left5">
                        <PatientAddNotes
                          patientPK={patientAddVariables.patient}
                          patientEditable={patientEditable && checkPermission(permission_key_values_patient.patients_patients_list_add)}
                        />
                        </div>
                      </TabPanel>

                      <TabPanel className="p-0" value={TAB7}>
                        <div className="padding-left5">
                        <FileManagement
                          type="patient"
                          parentPK={patientAddVariables.patient}
                          permissions={{
                            uploadPermission: checkPermission(permission_key_values_patient.pms_can_upload_patients_file, super_admin_privileges.super_admin_full_privilege, customer_admin_privilege),
                            viewPermission: checkPermission(permission_key_values_patient.pms_can_view_patient_upload_files_list, super_admin_privileges.super_admin_full_privilege, customer_admin_privilege),
                            deletePermission: checkPermission(permission_key_values_patient.pms_can_delete_uploaded_patients_file, super_admin_privileges.super_admin_full_privilege, customer_admin_privilege)
                          }}
                        />
                        </div>
                      </TabPanel>
                    </TabContext>
                <CustomizedSmallDialogs
                  showModal={inactivePatientModal}
                  setShowModalWindow={setInactivePatientModal}
                  header={i18n.t("commons.confrimInactiveHeader")}
                  type="yes"
                  inactveItem={(e) => onSaveFormData(e, "close")}
                  buttonStyle={"danger"}
                >
                  {i18n.t("commons.confrimInactive") + " patient?"}
                </CustomizedSmallDialogs>
                <CustomizedSmallDialogs
                  showModal={inactivePatientAlertModal}
                  setShowModalWindow={setInactivePatientAlertModal}
                  alertOK={onAlertOk}
                  header={i18n.t("commons.alert")}
                  type="alert"
                  inactveItem={(e) => onSaveFormData(e, "close")}
                >
                  {i18n.t("errorMessages.patient_inactive")}
                </CustomizedSmallDialogs>
                <CustomizedSmallDialogs
                  showModal={activePatientModal}
                  setShowModalWindow={setActivePatientModal}
                  header={i18n.t("commons.confrimActiveHeader")}
                  type="yes"
                  onHide={onHideActivatePatient}
                  inactveItem={ActivatePatient}
                  resetForm={onHideActivatePatient}
                  buttonStyle={"primary-blue"}
                >
                  {i18n.t("commons.confrimActive") + " patient?"}
                </CustomizedSmallDialogs>
                {checkPermission(permission_key_values_patient.patients_patients_list_add, permission_key_values_patient.patients_patients_list_modify) && patientEditable &&
                  activeTab &&
                  (activeTab === TAB1 || activeTab === TAB4) && (
                    <div className="d-flex justify-content-between padding-top5 ml-3" >
                      <div className="d-flex ">
                        <div className="max-width-3">
                          <div className="form-group">
                            <div className="custom-checkbox lh-0">
                            <input
                                type="checkbox"
                                id={"patient_inactive" + patientPK}
                                checked={patientAddVariables.patient_inactive}
                                name="patient_inactive"
                                disabled={!patientAddVariables.custom_patient_id}
                                onChange={(e) =>
                                  setPatientAddVariables((prev) => {
                                    return {
                                      ...prev,
                                      [e.target.name]: e.target.checked,
                                    };
                                  })
                                }
                              />
                              <label
                                className="checkbox top-14"
                                htmlFor={"patient_inactive" + patientPK}
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="padding-top5 padding-left20 mr-2">
                          <label className="padding-top5 margin-top-3px">
                            {i18n.t("patientPages.patientInfo.inactive")}
                          </label>
                        </div>
                      </div>
                      
                    <div className="padding-right10" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {patientAddVariables.custom_patient_id && (
                        <div style={{ paddingRight: "24px" }}>

                          <CommonButton label="Save & Close" variant="outlined"
                            onClick={
                              patientAddVariables.patient_inactive
                                ? (e) => onInactivePatient(e)
                                : (e) => onSaveFormData(e, "close")
                            } />

                        </div>)}
                      <div>
                        <CommonButton label="Save" variant="contained"
                          onClick={
                            patientAddVariables.patient_inactive
                              ? (e) => onInactivePatient(e)
                              : (e) => onSaveFormData(e)
                          } />
                      </div>
                    </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default PatientsAdd;
