import React from 'react';
import loadingImage from '../../assets/images/loader.json'
import Lottie from 'react-lottie';
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingImage,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};
const Loading = (props) => {
	if (props.showLoadingBar) {
		return (
			<div style={{ backgroundColor: 'rgba(97, 97, 97, 0.21)', height: '100%', width: '100%', zIndex: 9999, position: 'fixed', textAlign: 'center' }}>
				<div style={{ marginTop: '20%' }}>
					<Lottie options={defaultOptions}
						height={120}
						width={100} />
					<p style={{ color: '#1074B9', fontSize: '22px', zIndex: '9999' }}>Loading...</p>
				</div>
			</div>
		)
	} else {
		return (<div></div>)
	}

}
export default Loading