import React, { useContext, useEffect, useState } from "react";

// Third-party libraries
import { Form } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import LoadingContext from "../../../container/loadingContext";

// Utils & Constants
import { getStorage } from '../../../utilities/browserStorage';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from "../../../utilities/labelConfigs";
import { checkPermission, commonTableBody } from "../../../utilities/commonUtilities";
import { DEFAULT_PAGING_SIZE, PAGING_END_INDEX, ROUTE_AR_STATUS, ROUTE_PRACTICE_DICTIONARIES, ROUTE_SUPER_DICTIONARIES } from "../../../utilities/staticConfigs";
import { RESPONSIBILITY_TYPES } from "../../../utilities/dictionaryConstants";
import i18n from '../../../utilities/i18n';
import { ArStatusTableObject } from "./ARStatusTable";
import { customer_admin_privilege, permission_key_values_practice_dictionaries, super_admin_permission_key_value, super_admin_privileges } from "../../../utilities/permissions";

// API Service
import service from './service';

// Custom Components
import MultiSelectAutoComplete from "../../../MaterialMultiselect/MultiSelectAutoComplete";
import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import CommonButton from '../../commons/Buttons';
import Notify from '../../commons/notify';
import BackArrowWithLabel from '../../commons/Back';
import TextInput from '../../commons/input/input';
import Table from '../../commons/Table/Table';
import Pagination from "../../commons/pagination";


const ARStatus = () => {
    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const isAdminModule = getStorage('isAdminModule');
    const setShowLoadingBar = useContext(LoadingContext);
    const history = useHistory();
    const practicePk = getStorage("practice");

    const [searchValue, setSearchValue] = useState('')
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [responsibility, setResponsibility] = useState([]);
    const [header, setHeader] = useState('');
    const [ArStatus, setArStatus] = useState();
    const [ARStatusList, setARStatusList] = useState([])
    const [permission, setPermission] = useState('');
    const [editedARStatus, setEditedARStatus] = useState('');
    const [ARActionCodeList, setARActionCodeList] = useState([]);
    const [ARStatusData, setARStatusData] = useState([]);
    const [form_error, setFormError] = useState({ 'ArStatus': '' });
    const [editForm, setEditForm] = useState(false);
    const [deleteARStatusId, setDeleteARStatusId] = useState();
    const [deleteARStatusName, setDeleteARStatusName] = useState();
    const [defaultValue, setDefaultValue] = useState(false);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    /**
     * Handles the "Previous Page" button click event.
     */
    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getARStatusList(DEFAULT_PAGING_SIZE, previousPage, searchValue)
    }

    /**
     * Handles direct page selection via pagination controls.
     * @param {*} e 
     */
    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getARStatusList(DEFAULT_PAGING_SIZE, page, searchValue)
    }

    /**
     * Handles the "Next Page" button click event
     */
    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getARStatusList(DEFAULT_PAGING_SIZE, nextPage, searchValue)
    }
    //Pagination ends

    /**
     * Fetches the AR status list on the initial render 
     * and whenever dependencies change.
     */
    useEffect(() => {
        getARStatusList(DEFAULT_PAGING_SIZE, activePage);
        if (history.location?.pathname == ROUTE_AR_STATUS) {
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,customer_admin_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                super_admin_privileges.super_admin_full_privilege
            ))
        }
    }, [])

    /**
     * Navigates the user back to the dictionary page
     */
    const backToDictionaryPage = () => {
        if (isAdminModule === 'true'){
            history.push(ROUTE_SUPER_DICTIONARIES)
        } else {
            history.push(ROUTE_PRACTICE_DICTIONARIES)
        }
    }

    /**
     * Handles form input changes
     * @param {*} e 
     */
    const onHandleChange = (e) => {

        let { name, value } = e.target;
        let trimmedValue = '';

        if (name == "searchValue") {
            setSearchValue(value)
        }

        if (e.target.type === "text") {
            trimmedValue = value;
        }

        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setArStatus(trimmedValue)
    }

    // Handles the 'Enter' key press for triggering a search.
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchValue) {
            onSearch(e, searchValue);
        }
    }

    // Resets the search value and retrieves the AR status list.
    const onClose = () => {
        setSearchValue('');
        setActivePage(1)
        getARStatusList(DEFAULT_PAGING_SIZE, 1)
    }

    // Performs a search if the input value has at least 2 characters
    const onSearch = (e, value) => {
        if (value.length >= 2) {
            getARStatusList(DEFAULT_PAGING_SIZE, 1, value)
        }
    }

    // Updates the responsibility value based on user input.
    const handleResponsibility = (e) => {
        setResponsibility(e.target.value)
    }

    // Opens the modal window for adding a new AR status and resets relevant fields.
    const handleAddNew = () => {
        if (!permission) {
            showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setShowModalWindow(true);
        setArStatus('');
        setResponsibility([]);
        setARStatusData([]);
        setEditForm(false)
        setHeader(i18n.t("dictionariesPages.arStatus.ArStatus"));
    }


    // Searches AR action codes based on a query and updates the action code list.
    const handleSearch = async (search) => {
        try {
            setShowLoadingBar(true);
            let result = await service.ARactionCodeList(0, 0, search,isAdminModule,practicePk);
            if (Array.isArray(result.data) && result.data.length > 0) {
                let structuredData = result.data.map((item) => ({
                    id: item.id,
                    name: item.name
                }))
                setARActionCodeList(structuredData);
            } else {
                setARActionCodeList([]);
            } 
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            console.error("Error while searching AR status:", error)
        } finally {
            setShowLoadingBar(false);
        }
    }

    const handleOnBlur = () => {
        setARActionCodeList([]);
    }

    // Fetches AR status details for editing and opens the edit modal.
    const onEditARStatus = async (ARStatusId) => {
        setShowLoadingBar(true);
        try {
            const response = await service.GetARStatusData(ARStatusId,isAdminModule);
            if (response?.data) {
                let structuredData = response.data?.ar_action?.map((item)=> ({
                    id: item.id,
                    name:item.name
                }));
                setARStatusData(structuredData)
                setResponsibility(response.data?.priorities)
                setArStatus(response.data?.name)
                setShowLoadingBar(false);
            }
            setEditForm(true);
            setEditedARStatus(ARStatusId)            
            setShowModalWindow(true);
            setHeader(i18n.t("dictionariesPages.arStatus.ArStatus"));
        } catch (error) {
            console.error("Error while editing AR status:", error);
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        } finally {
            setShowLoadingBar(false);
        }

    }

    /**
     *  Opens the delete confirmation modal and stores the AR status details for deletion.
     * @param {*} ArStatusId 
     */
    const onDeleteARStatus = (ArStatusId) => {
        setShowDeleteModalWindow(true);
        ARStatusList.map((item)=> {
            if (item.id === ArStatusId){
                setDeleteARStatusId(item.id);
                setDeleteARStatusName(item.name);
            }
        })
    }

    /**
     * Handles dropdown actions like editing or deleting AR statuses.
     */
    const dropDownFunction = (id, name) => {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name.toLowerCase() == 'edit') {
            onEditARStatus(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteARStatus(Number(id));
        }
    }

    /**
     * Fetches the AR status list based on page size, page, and optional search query.
     */
    const getARStatusList = async (pageSize, page, searchString) => {
        setShowLoadingBar(true);
        let result = await service.ARStatusList(pageSize, page, isAdminModule, practicePk, searchString);

        if (result.status == 200 && result.data.results) {

            setShowLoadingBar(false);
            if (result.data.results !== undefined) {
                setTotalPage(
                    Math.ceil(result.data.count / result.data.page_size)
                );
            }
            if(result.data.results) {
				result.data.results = result.data.results?.map(item => {
                    setDefaultValue(item.not_delete);
					if (item.not_delete === true) {
						item.isDefault = "Yes";
					} else {
						item.isDefault = "No";
					}
					return item;
				});
			}

            const rowArray = commonTableBody(result.data.results, ArStatusTableObject.tableBodyData[0]);
            if (rowArray.length > 0) {
                ArStatusTableObject.tableBodyData = rowArray;
            } else {
                ArStatusTableObject.tableBodyData = ArStatusTableObject;

            }
            setARStatusList(result.data?.results);

        } else {
            setShowLoadingBar(false);
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
        }

    }

    /**
     * Adds or updates AR status data based on the current form state
     * @param {*} data 
     * @returns 
     */
    const addARStatusTableData = async (data) => {
        try {
            if (editForm) {
                return service.UpdateARStatusData(editedARStatus, data,isAdminModule);
            } else {
                return await service.AddARStatusData(data, isAdminModule);
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            console.error("Error while adding AR status:", error)
        } 
    }


    /**
     * Saves form data and triggers appropriate backend actions for adding or updating AR statuses.
     * @param {*} e 
     */
    const onSaveFormData = async (e) => {
        try {
            e.preventDefault();
            if ( isAdminModule === 'false' && ARStatusData.length == 0){
                showNotifyWindow('show', 'error', i18n.t("dictionariesPages.arStatus.ArActionError"));
                return;
            }
            setShowLoadingBar(true);
            if (!ArStatus.trim()) {
                setFormError({
                    ...form_error,
                    ['ArStatus']: 'error'
                })
            }
            let data;
            if (isAdminModule == "true") {
                data = {
                    name: ArStatus,
                    ar_action: ARStatusData.map(item => item.id),
                    priorities: responsibility 
                }
            } else {
                data = {
                    name: ArStatus,
                    ar_action: ARStatusData.map(item => item.id),
                    priorities: responsibility,
                    practice: practicePk
                }
                
            }
            const response = await addARStatusTableData(data);

            if (!response || !response.data || typeof response.data !== "object") {
                throw new Error("Invalid response format");
            }
            
            if (response.data.message === "name_blank") {
                showNotifyWindow('show', 'error', i18n.t("dictionariesPages.arStatus.ARStatusBlankName"));
            }  else if (response.data.message === "name_unique") {
                showNotifyWindow('show', 'error', i18n.t("dictionariesPages.arStatus.ARStatusAlreadyExist"));
            } else if (response.status == 200 || response.status == 201) {
                showNotifyWindow('show', 'success', editForm ? UPDATE_SUCCESS : ADD_SUCCESS);
                setSearchValue('');
                getARStatusList(DEFAULT_PAGING_SIZE, activePage);
                setShowModalWindow(false);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            console.error("Error while saving AR status:", error);
        } finally {
            setShowLoadingBar(false);
        }
    }

    /**
     * Updates the AR status data state when form values change.
     * @param {*} value 
     */
    const handleValueChange = (value) => {
        setARStatusData(value);
    }

    /**
     * Closes the modal window and resets form errors.
     */
    const onHide = () => {
        setShowModalWindow(false);
        setFormError({
            ...form_error,
            ['ArStatus']: ''
        })
    }

    /**
     * Resets form error states.
     */
    function resetForm() {
        setFormError({
            ...form_error,
            ['ArStatus']: 'error'
        });
    }

    /**
     * Deletes an AR status by ID and refreshes the list upon success
     */
    const onDeleteAction = async() => {
        try {
            setShowDeleteModalWindow(true);
            setShowLoadingBar(true);
            let result = await service.DeleteARStatusData(deleteARStatusId,isAdminModule);
            if (result.status_code === 400 ) {
                showNotifyWindow("show", "error", "Not able to delete since it's a default value.");
            } else if (result.status == 204) {
                showNotifyWindow("show", "success", DELETE_SUCCESS);
                setSearchValue('');
                setShowDeleteModalWindow(false);
                getARStatusList(DEFAULT_PAGING_SIZE, activePage); //calling the GET to update table data
            }
            
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
            console.error("Error deleting AR status:", error)
        } finally {
            setShowLoadingBar(false);
        }
    }

    /**
     * Hides the delete confirmation modal and resets delete-related states.
     */
    const onDeleteHide = () => {
        setShowDeleteModalWindow(false)
        setDeleteARStatusId('');
        setDeleteARStatusName('');
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                {isAdminModule === "true" ? (
                    <div className="box box-content-white mb-3">
                        <div className="row mr-0 mb-2">
                            <div className="col pl-4 mb-1">
                                <div className={"dataTables_filter"}>
                                    <div
                                        className="link dictionaries-back"
                                        onClick={backToDictionaryPage}
                                    >
                                        <BackArrowWithLabel
                                            label={i18n.t(
                                                "dictionariesPages.gotoDictionaries"
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-content">
                            <div className="common-search-wrapper-style">
                                <div className="input-content-box">
                                    <div className="form-group padding-top15 relative">
                                        <TextInput
                                            type="text"
                                            id="searchValue"
                                            name="searchValue"
                                            value={searchValue}
                                            onValueChange={onHandleChange}
                                            label={i18n.t(
                                                "dictionariesPages.arStatus.ArStatus"
                                            )}
                                            onKeyDown={handleKeyDown}
                                            labelClassName={"margin-bottom0"}
                                            clearButton={
                                                typeof searchValue ==
                                                "string" &&
                                                searchValue.length !== 0
                                            }
                                            clearInput={onClose}
                                        />
                                    </div>
                                </div>
                                <div className="input-content-box padding-top15">
                                    <div className="margin-top18">
                                        <CommonButton
                                            variant="contained"
                                            label="Search"
                                            onClick={(e) =>
                                                onSearch(e, searchValue)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="box box-content-white">
                        <div className="box-head pl-0 pr-0">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back pl-0"
                                    onClick={backToDictionaryPage}
                                >
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={handleAddNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={ArStatusTableObject}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={(e) => onPagePrevious(e)}
                                onPageUp={(e) => onPageUp(e)}
                                onPageNext={(e) => onPageNext(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}
            >
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="ArStatus"
                                        name="ArStatus"
                                        label={i18n.t(
                                            "dictionariesPages.arStatus.ArStatus"
                                        )}
                                        disabled={defaultValue}
                                        defaultClassName={
                                            form_error.ArStatus
                                                ? "input-error"
                                                : ""
                                        }
                                        onValueChange={onHandleChange}
                                        value={ArStatus || ""}
                                    />
                                </div>
                                <div className='' >
                                    <MultiSelectAutoComplete
                                        options={ARActionCodeList}
                                        label="AR Action Code"
                                        value={ARStatusData}
                                        onChange={(e) => handleValueChange(e)}
                                        onSearch={(e) => handleSearch(e)}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <MaterialMultiselect
                                        name="priorities"
                                        value={responsibility}
                                        onValueChange={(e) => handleResponsibility(e)}
                                        options={RESPONSIBILITY_TYPES}
                                        label={"Responsibility Level"}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>

            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={() => onDeleteAction()}
                resetForm={resetForm}
                onHide={() => onDeleteHide()}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.arStatus.deletConfirmation") +
                    "'" +
                    deleteARStatusName +
                    "' ?"}
            </CustomizedSmallDialogs>

        </React.Fragment>
    )
}

export default ARStatus