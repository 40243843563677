import format from "date-fns/format";
import { procedure } from "./initialState";
import { DEFAULT_COUNTRY } from "../../../utilities/staticConfigs";
import { getStorage } from "../../../utilities/browserStorage";
import i18n from "../../../utilities/i18n";
import { DEFAULT_CLAIM_ACCIDENT_TYPE, PRIORITIES } from "../../../utilities/dictionaryConstants";
import { findDuplicates } from "./claimAlertValidations";
import { decimalConverter } from "../../../utilities/commonUtilities";

export const createSaveData = (
    professionalClaimDetails,
    billingInfoDetails,
    serviceLineDetails,
    additionalClaimDetails,
    claimPK
) => {
    /**
     * structuring parent component that is professional claim details
     */

    let filteredProfessionalClaimDataVariable = {};
    let professionaldata = {};
    const {
        patient,
        reference_number,
        posted_date,
        service_from_date,
        service_to_date,
        claim_status,
        claim_sub_status,
        authorization_referral,
        claim_type,
        identifier,
    } = professionalClaimDetails;
    const active = !professionalClaimDetails.claim_inactive;
    const professionalClaimVariableData = {
        patient,
        reference_number,
        posted_date,
        service_from_date,
        service_to_date,
        claim_status,
        claim_sub_status,
        authorization_referral,
        claim_type,
        identifier,
        active,
    };
    for (const [key, value] of Object.entries(professionalClaimVariableData)) {
        if (value && key.endsWith("_date")) {
            filteredProfessionalClaimDataVariable[key] = value;
        } else {
            filteredProfessionalClaimDataVariable[key] = value;
        }
    }
    professionaldata = { ...filteredProfessionalClaimDataVariable };

    /********************************************************************** */
    /**
     * billing info details structure done here
     */
    let filteredBillingInfoData = {};
    let billingData = {};
    const {
        pk,
        billing_provider,
        rendering_provider,
        supervising_provider,
        service_location,
        referring_provider,
        ordering_provider,
        accept_assignments,
        insurer_relation,
        patient_signature,
        claim_fequency,
        insured_signature,
        payer_info,
        claim_reference,
    } = billingInfoDetails;
    const billingInfoVariableData = {
        pk,
        billing_provider_id: billing_provider,
        rendering_provider_id: rendering_provider,
        supervising_provider_id: supervising_provider,
        referring_provider_id: referring_provider,
        ordering_provider_id: ordering_provider,
        accept_assignments: accept_assignments,
        insurer_relation_id: insurer_relation,
        patient_signature,
        claim_fequency_id: claim_fequency,
        insured_signature,
        service_location,
        claim_reference,
        payer_info,
    };
    for (const [key, value] of Object.entries(billingInfoVariableData)) {
        if (value) {
            filteredBillingInfoData[key] = value;
        }
    }

    if (claimPK) {
        billingData = {
            ...filteredBillingInfoData,
            pk: professionalClaimDetails.pks.billing_info_pk,
        };
    } else if (!claimPK) {
        billingData = { ...filteredBillingInfoData };
    }
    /****************************************************************** */
    /**
     * service line diagnosis value handled here
     */
    let filteredDiagnosisesDataVariables = {};
    let diagnosisdata = {};
    const {
        id,
        icd,
        diagnosis1,
        diagnosis2,
        diagnosis3,
        diagnosis4,
        diagnosis5,
        diagnosis6,
        diagnosis7,
        diagnosis8,
        diagnosis9,
        diagnosis10,
        diagnosis11,
        diagnosis12,
    } = serviceLineDetails;
    const diagnosisesDataVariables = {
        id,
        icd,
        diagnosis1,
        diagnosis2,
        diagnosis3,
        diagnosis4,
        diagnosis5,
        diagnosis6,
        diagnosis7,
        diagnosis8,
        diagnosis9,
        diagnosis10,
        diagnosis11,
        diagnosis12,
    };
    for (const [key, value] of Object.entries(diagnosisesDataVariables)) {
        if (value) {
            filteredDiagnosisesDataVariables[key] = value;
        }
    }
    if (!claimPK) {
        diagnosisdata = filteredDiagnosisesDataVariables;
    } else if (claimPK) {
        diagnosisdata = {
            ...filteredDiagnosisesDataVariables,
            pk: professionalClaimDetails.pks.diagnosises_pk,
        };
    }
    /*************************************************************************/
    /**
     * procedure structure making
     */

    const newData = serviceLineDetails?.procedures;
    let procedureArray = [];
    // dosFrom and dosTo is set for updating claim dos while saving,
    // if one procedure is added but any value not updated against it. so that
    // procedure will not save to db. for those case we need to iterate newArray
    // for final claim dos from and to
    if (serviceLineDetails?.procedures?.length === 1) {
        let firstItem = JSON.stringify(serviceLineDetails?.procedures[0]);
        let tempProcedure = JSON.stringify(procedure);
        if (firstItem === tempProcedure) {
            procedureArray = [];
        }
    }
    newData.forEach((element) => {
        let item = JSON.parse(JSON.stringify(element));

        if (typeof (item?.id) == 'string' && item?.id?.startsWith("New-"))
            item.id = ""

        if (
            item.id ||
            item.dos_from ||
            item.dos_to ||
            item.pos_id ||
            item.cpt_hcpcs ||
            item.charges
        ) {
            if (item.dos_from)
                item.dos_from = format(
                    new Date(
                        item.dos_from && typeof item.dos_from === "string"
                            ? item.dos_from + " 00:00:00"
                            : item.dos_from
                    ),
                    "yyyy-MM-dd"
                );
            if (item.dos_to)
                item.dos_to = format(
                    new Date(
                        item.dos_to && typeof item.dos_to === "string"
                            ? item.dos_to + " 00:00:00"
                            : item.dos_to
                    ),
                    "yyyy-MM-dd"
                );
            if (item.drug_code) {
                item.drug_code = formatDrugCode(item.drug_code);
            }
            for (const [key, value] of Object.entries(item)) {
                if (!value && key != "dx_pointers") {
                    delete item[key];
                }
                if (key.endsWith("List")) {
                    delete item[key];
                }
                if(key.match('payment_done')){
                    delete item[key];  
                }
            }
            procedureArray.push(item);
        }
    });
    /*******************************************************************************/
    /**
     * additional claim details structuring
     */

    let additionaldata = {};
    let filteredAdditionalClaimData = {};
    let {
        employment,
        accident,
        state,
        other_accident,
        accident_date,
        transport_code,
        last_menstural_date,
        initial_treatment_date,
        last_seen_date,
        unable_work_from_date,
        unable_work_to_date,
        hospitalization_from_date,
        hospitalization_to_date,
        assumed_care_date,
        disability_begin_date,
        disability_end_date,
        relinquished_date,
        mammography_cert_number,
        care_plan_number,
        service_authorization_code,
        clia_number,
        ide_number,
        special_program_code,
        project_demonstration_id,
        patient_homebound,
        claim_delay_reason,
        claim_note,
        lab_sales_rep,
        claim_service_type,
        clinic_site_name,
        accession_number,
        ambulance_claim,
        weight,
        transport_reason,
        distance,
        return_trip_reason,
        stretcher_reason,
        certification,
        condition_indicator,
        pickup_location_data,
        dropoff_location_data,
        lab_notes,
    } = additionalClaimDetails;
    let additionalClaimVariableData = {
        employment,
        accident_id: accident,
        state_id: state,
        special_program_code,
        transport_reason_id: transport_reason,
        other_accident,
        transport_code,
        accident_date,
        last_menstural_date,
        initial_treatment_date,
        last_seen_date,
        unable_work_from_date,
        unable_work_to_date,
        hospitalization_from_date,
        hospitalization_to_date,
        assumed_care_date,
        disability_begin_date,
        disability_end_date,
        relinquished_date,
        mammography_cert_number,
        care_plan_number,
        service_authorization_code,
        clia_number,
        ide_number,
        project_demonstration_id,
        patient_homebound,
        claim_delay_reason,
        claim_note,
        lab_sales_rep,
        claim_service_type,
        clinic_site_name,
        accession_number,
        ambulance_claim,
        weight,
        distance,
        return_trip_reason,
        stretcher_reason,
        certification,
        ...condition_indicator,
        pickup_address: ambulance_claim === 1 ? pickup_location_data : {},
        dropoff_address: ambulance_claim === 1 ? dropoff_location_data : {},
        pickup_location_data: ambulance_claim === 1 ? pickup_location_data : {},
        dropoff_location_data:
            ambulance_claim === 1 ? dropoff_location_data : {},
        lab_notes,
    };
    for (const [key, value] of Object.entries(additionalClaimVariableData)) {
        if (value) {
            if (key.endsWith("_date")) {
                try {
                    filteredAdditionalClaimData[key] = format(
                        value,
                        "yyyy-MM-dd"
                    );
                } catch (e) {
                    filteredAdditionalClaimData[key] = value;
                }
            } else {
                filteredAdditionalClaimData[key] = value;
            }
        }
    }
    if (!claimPK) {
        delete filteredAdditionalClaimData["pickup_location_data"];
        delete filteredAdditionalClaimData["dropoff_location_data"];
        additionaldata = filteredAdditionalClaimData;
    } else if (claimPK) {
        additionaldata = {
            ...filteredAdditionalClaimData,
            pk: professionalClaimDetails.pks.additional_claim_info_pk,
            ...condition_indicator,
        };
        delete additionaldata["pickup_location_data"];
        delete additionaldata["dropoff_location_data"];
    }
    return {
        professionaldata,
        billingData,
        diagnosisdata,
        procedureArray,
        additionaldata,
    };
};

const formatDrugCode = (rawValue) => {
    let formattedValue = "";
    for (let i = 0; i < rawValue?.length; i++) {
        if (i === 5 || i === 9) {
            formattedValue += "-";
        }
        formattedValue += rawValue.charAt(i);
    }
    return formattedValue;
};

/**
 * function is used to generate data that needed for billing info tab
 * @param {*} data
 */
export const generateBillingTabSaveData = (data,payload) => {
    let billingTabData = {};
    let payer_info_list=data.payer_info_list ?? [];
    let insuranceCompleted=false;
    const payerInfoData=makePayerInsuranceData(payer_info_list,payload);
    if (data.service_location.length > 0) {
        if (data.service_location[0].location_type === "practice") {
            billingTabData = {
                ...data,
                service_location: {
                    id: data.service_location[0].Id,
                    location_type: "practice",
                },
                service_location_value: `${data.service_location[0].Id}||practice`,
                location_type: "practice",
                payer_info: [],
                id: "",
                patient_signature: 1,
                insured_signature: 1,
                claim_fequency: 1,
                billing_provider: data.rendering_provider,
                accept_assignments: data.accept_assignemnt
                    ? data.accept_assignemnt
                    : "",
                ...payerInfoData,
            };
        } else if (data.service_location[0].location_type === "facility") {
            billingTabData = {
                ...data,
                service_location: {
                    id: data.service_location[0].Id,
                    location_type: "facility",
                },
                service_location_value: `${data.service_location[0].Id}||facility`,
                payer_info: [],
                id: "",
                patient_signature: 1,
                insured_signature: 1,
                claim_fequency: 1,
                billing_provider: data.rendering_provider,
                accept_assignments: data.accept_assignemnt
                    ? data.accept_assignemnt
                    : "",
                ...payerInfoData,
            };
        }
    } else {
        billingTabData = {
            ...data,
            payer_info: [],
            id: "",
            patient_signature: 1,
            insured_signature: 1,
            claim_fequency: 1,
            ...payerInfoData,
            billing_provider: data.rendering_provider,
            accept_assignments: data.accept_assignemnt
                ? data.accept_assignemnt
                : "",
        };
    }
    insuranceCompleted=true;
    return {billingTabData,insuranceCompleted};
};

/**
 * function to process and make data needed for service line tab
 * @param {*} data
 */

export const generateServiceLineSaveData = (data) => {
    let dx_pointers = "";
    const dxMap = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];
    for (let index = 0; index < 12; index++) {
        const dx = data["diagnosis" + (index + 1)];
        if (dx) {
            dx_pointers += dxMap[index];
        }
    }
    let newArray = [];
    const propertiesToAssign = [
        "id",
        "dos_from",
        "dos_to",
        "pos_id",
        "pos_id_List",
        "tos_id",
        "tos_id_List",
        "emg",
        "cpt_hcpcs",
        "cpt_hcpcsList",
        "m1_id",
        "m1List",
        "m2_id",
        "m2List",
        "m3_id",
        "m3List",
        "m4_id",
        "m4List",
        "dx_pointers",
        "fee",
        "fee_units",
        "charges",
        "cpt_epsdt",
        "responsibility_type",
        "proced_description",
        "line_note",
        "drug_code",
        "drug_unit_type",
        "drug_unit_value",
        "measurement_unit_type",
        "measurement_unit_value",
        "anesthesia",
        "responsibility_type",
        "payment_doneList",
    ];
    // set the default cpts and other variable
    for (let index = 0; index < 12; index++) {
        if (!data["cpt" + (index + 1)]) continue;
        newArray[index] = {};
        propertiesToAssign.forEach((property) => {
            newArray[index][property] = "";
        });
        newArray[index]["cpt_hcpcs"] = data["cpt" + (index + 1)];
        newArray[index]["cpt_hcpcsList"] = [
            {
                id: data["cpt" + (index + 1)],
                name: data["cpt" + (index + 1) + "_name"],
            },
        ];
        for (let j = 1; j <= 4; j++) {
            if (
                data["cpt" + (index + 1) + "_m" + j + "_id"] &&
                data["cpt" + (index + 1) + "_m" + j + "_value"].length > 0
            ) {
                newArray[index]["m" + j + "_id"] =
                    data["cpt" + (index + 1) + "_m" + j + "_id"];
                newArray[index]["m" + j + "List"] =
                    data["cpt" + (index + 1) + "_m" + j + "_value"];
            }
        }
        if (data["pos" + (index + 1) + "_id"] == null) {
            if (data["location_pos_id"]) {
                newArray[index]["pos_id"] = data["location_pos_id"];
                newArray[index]["pos_id_List"] = data["location_pos_List"];
            } else {
                newArray[index]["pos_id"] = [];
                newArray[index]["pos_id_List"] = [];
            }
        } else {
            newArray[index]["pos_id"] = data["pos" + (index + 1) + "_id"];
            newArray[index]["pos_id_List"] = data["pos" + (index + 1) + "_id"]
                ? data["pos" + (index + 1) + "_List"]
                : [];
        }
        newArray[index]["tos_id"] = data["tos" + (index + 1) + "_id"];
        newArray[index]["tos_id_List"] = data["tos" + (index + 1) + "_id"]
            ? data["tos" + (index + 1) + "_List"]
            : [];
        newArray[index]["fee_units"] = data["default_unit" + (index + 1)];
        newArray[index]["dx_pointers"] = dx_pointers;
        newArray[index]["cpt_epsdt"] = 2;
        newArray[index]["emg"] = 2;
        newArray[index]["fee"] = data["default_fee" + (index + 1)];
        newArray[index]["responsibility_type"] = data["responsibility_type"];
        newArray[index]["charges"] =
            parseFloat(data["default_unit" + (index + 1)]) *
            parseFloat(data["default_fee" + (index + 1)]);
        newArray[index]["claim_status"] = data["claim_status"];
        newArray[index]["claim_sub_status"] = data["claim_sub_status"];
        newArray[index]['payment_done']=false;
        newArray[index]["insurance_message"]=data["insurance_message"] ? data["insurance_message"] : "";
    }

    /**
     * creaating diagnosis list format here
     * @param {*} num
     * @returns
     */
    const createDiagnosisList = (num) => {
        const diagnosis = `diagnosis${num}`;
        const diagnosisValue = `${diagnosis}_value`;
        return {
            [diagnosis]: data[diagnosis] ? data[diagnosis].toString() : "",
            [`${diagnosis}List`]: data[diagnosis]
                ? [{ label: data[diagnosis], name: data[diagnosisValue] }]
                : [],
        };
    };
    /**
     * storing diagnosis value and list
     */
    let diagnosisdata;
    for (let i = 1; i <= 12; i++) {
        diagnosisdata = {
            ...diagnosisdata,
            ...createDiagnosisList(i),
        };
    }
    let serviceLineData = {
        icd: data.icd ? data.icd : 2,
        ...diagnosisdata,
        procedures: (newArray && Array.isArray(newArray) && newArray.length > 0) ? newArray.map((item) => ({ ...item, id: `New-${crypto.randomUUID()}` })) : []
    };

    return serviceLineData;
};
/**
 * additional data structure is build here
 * @param {*} data
 * @param {*} practiceData
 * @param {*} accidentList
 */

export const generateAdditionalclaimSaveData = (
    data,
    practiceData,
    accidentList
) => {
    const additionalData = {
        ...data,
        clia_number: practiceData.clia,
        accident: data.accident_type
            ? data.accident_type
            : accidentList?.find(
                (item) => item.name === DEFAULT_CLAIM_ACCIDENT_TYPE
            )?.id || null,
        state: data.accident_location,
        dropoff_location: data.dropoff_location ? data.dropoff_location : "",
        dropoff_location_data: data.dropoff_location_data
            ? {
                street1: data.dropoff_location_data.street1,
                street2: data.dropoff_location_data.street2,
                city: data.dropoff_location_data.city,
                state: data.dropoff_location_data.state,
                country: data.dropoff_location_data.country,
                zip_code: data.dropoff_location_data.zip_code,
            }
            : {
                street1: "",
                street2: "",
                city: "",
                state: "",
                country: DEFAULT_COUNTRY,
                zip_code: "",
            },
        condition_indicator: {
            ambulance_service_medical_necessary:
                data.ambulance_service_medical_necessary
                    ? data.ambulance_service_medical_necessary
                    : false,
            patient_admitted_hospital: data.patient_admitted_hospital
                ? data.patient_admitted_hospital
                : false,
            patient_confined_bed_chair: data.patient_confined_bed_chair
                ? data.patient_confined_bed_chair
                : false,
            patient_moved_by_stretcher: data.patient_moved_by_stretcher
                ? data.patient_moved_by_stretcher
                : false,
            patient_physically_restrained: data.patient_physically_restrained
                ? data.patient_physically_restrained
                : false,
            patient_transported_emergency: data.patient_transported_emergency
                ? data.patient_transported_emergency
                : false,
            patient_unconscious_shock: data.patient_unconscious_shock
                ? data.patient_unconscious_shock
                : false,
            patient_visible_haemorrhaging: data.patient_visible_haemorrhaging
                ? data.patient_visible_haemorrhaging
                : false,
        },
        pickup_location: data.pickup_location ? data.pickup_location : "",
        pickup_location_data: data.pickup_location_data
            ? {
                street1: data.pickup_location_data.street1,
                street2: data.pickup_location_data.street2,
                city: data.pickup_location_data.city,
                state: data.pickup_location_data.state,
                country: data.pickup_location_data.country,
                zip_code: data.pickup_location_data.zip_code,
            }
            : {
                street1: "",
                street2: "",
                city: "",
                state: "",
                country: DEFAULT_COUNTRY,
                zip_code: "",
            },
        employment: data.employment ? data.employment : 2,
        ambulance_claim: data.ambulance_claim ? data.ambulance_claim : 2,
    };

    return additionalData;
};

/**
 * parent component data structure is build here during existing claim update
 * @param {*} data
 */
export const generateClaimCommonUpdateData = (claim_info) => {
    let commonData = {
        ...claim_info,
        locked_by_name:
            claim_info?.locked &&
                claim_info?.locked_by ===
                JSON.parse(getStorage("userData"))?.profile_id
                ? i18n.t("commons.self")
                : claim_info?.locked_by_name,
        claim_editable:
            !claim_info?.locked ||
                (claim_info?.locked &&
                    claim_info?.locked_by ===
                    JSON.parse(getStorage("userData"))?.profile_id &&
                    claim_info?.unid === JSON.parse(getStorage("userData"))?.unid)
                ? true
                : false,
        claim_inactive: claim_info?.active ? false : true,
        active: claim_info?.active,
        claim_locked:
            claim_info?.locked &&
                claim_info?.locked_by !==
                JSON.parse(getStorage("userData"))?.profile_id &&
                claim_info?.unid !== JSON.parse(getStorage("userData"))?.unid
                ? true
                : false,
    };

    return commonData;
};

/**
 * billing info data structure is build here during existing claim update
 * @param {*} data
 */
export const generateBillingInfoUpdateData = (data,payload) => {
    let billingInfoTabData = {};
    let payer_info_list=data.payer_info_list ?? [];
    let insuranceCompleted=false;
    const payerInfoData=makePayerInsuranceData(payer_info_list,payload);
    const payer_info_db = data?.payer_info.map((item) => ({
        practice_insurance_id: item.Id,
        policy_id: item.policy_id,
        priority: item.priority,
        Id: item.Id,
    }));
    if (data?.facility_location === null) {
        billingInfoTabData = {
            ...data,
            payer_info: payer_info_db,
            service_location: {
                id: data?.practice_location,
                location_type: "practice",
            },
            service_location_value: `${data?.practice_location}||practice`,
        };
    } else if (data?.practice_location === null) {
        billingInfoTabData = {
            ...data,
            payer_info: payer_info_db,
            service_location: {
                id: data?.facility_location,
                location_type: "facility",
            },
            service_location_value: `${data?.facility_location}||facility`,
        };
    }
    insuranceCompleted=true;
    return {billingInfoTabData,payerInfoData,insuranceCompleted};
};

export const generateServiceLineUpdateData = (procedureData) => {
    const createDiagnosisList = (num) => {
        const diagnosis = `diagnosis${num}`;
        const diagnosisValue = `${diagnosis}_value`;
        return {
            [diagnosis]: procedureData[diagnosis]
                ? procedureData[diagnosis].toString()
                : "",
            [`${diagnosis}List`]: procedureData[diagnosis]
                ? [
                    {
                        label: procedureData[diagnosis],
                        name: procedureData[diagnosisValue],
                    },
                ]
                : [],
        };
    };
    /**
     * storing diagnosis value and list
     */
    let diagnosisdata;
    for (let i = 1; i <= 12; i++) {
        diagnosisdata = {
            ...diagnosisdata,
            ...createDiagnosisList(i),
        };
    }

    /**
     * making procdure line structure
     */
    let newData = [];
    if (procedureData?.procedures.length > 0) {
        procedureData?.procedures?.forEach((item) => {
            let data = {
                id: item.id,
                dos_from: item.dos_from ? item.dos_from : "",
                dos_to: item.dos_to ? item.dos_to : "",
                pos_id: item.pos,
                pos_id_List: [item.pos ? { id: item.pos, name: item.pos_drop_down_name, label: item.pos_drop_down_name } : ""],
                tos_id: item.tos,
                tos_id_List: [item.tos ? { id: item.tos, name: item.tos_drop_down_name, label: item.tos_drop_down_name } : ""],
                emg: item.emg,
                cpt_hcpcs: item.cpt_hcpcs,
                cpt_hcpcsList: item.cpt_hcpcs ? [{ "id": item.cpt_hcpcs, "name": item.cpt_value }] : [],
                m1_id: item.m1 ? item.m1 : [],
                m2_id: item.m2 ? item.m2 : [],
                m3_id: item.m3 ? item.m3 : [],
                m4_id: item.m4 ? item.m4 : [],
                m1List: [item.m1 ? { "id": item.m1, "name": item.m1_value } : ""],
                m2List: [item.m2 ? { "id": item.m2, "name": item.m2_value } : ""],
                m3List: [item.m3 ? { "id": item.m3, "name": item.m3_value } : ""],
                m4List: [item.m4 ? { "id": item.m4, "name": item.m4_value } : ""],
                dx_pointers: item.dx_pointers,
                fee: item.fee ? decimalConverter(item.fee, 2) : 0.00,
                fee_units: item.fee_units,
                charges: item.charges,
                cpt_epsdt: item.cpt_epsdt,
                proced_description: item.proced_description,
                line_note: item.line_note,
                drug_code: item.drug_code ? item.drug_code.replace(/-/g, '') : "", // converted to number
                drug_unit_type: item.drug_unit_type,
                drug_unit_value: item.drug_unit_value,
                measurement_unit_type: item.measurement_unit_type,
                measurement_unit_value: item.measurement_unit_value,
                anesthesia: item.anesthesia,
                responsibility_type: item.responsibility_type,
                payment_done: item.payment_done==true ? true : false,
                balance: item.balance ? Number(item.balance) : null,
                claim_status:item.claim_status??"",
                claim_sub_status:item.claim_sub_status??"",
                insurance_message:item.insurance_message ?? "",
            }
            newData.push(data)
        })
    }
    const procedurDetails = {
        icd: procedureData.icd ? procedureData.icd : 2,
        ...diagnosisdata,
        procedures: newData,
    };

    return procedurDetails;
};

export const generateAdditionaleUpdateData = (additionalInfo, accidentList) => {
    let additonalData = {
        ...additionalInfo,
        accident: additionalInfo.accident
            ? additionalInfo.accident
            : accidentList?.find(
                (item) => item.name === DEFAULT_CLAIM_ACCIDENT_TYPE
            )?.id || null,
        condition_indicator: {
            ambulance_service_medical_necessary: additionalInfo.ambulance_service_medical_necessary ? additionalInfo.ambulance_service_medical_necessary : false,
            patient_admitted_hospital: additionalInfo.patient_admitted_hospital ? additionalInfo.patient_admitted_hospital : false,
            patient_confined_bed_chair: additionalInfo.patient_confined_bed_chair ? additionalInfo.patient_confined_bed_chair : false,
            patient_moved_by_stretcher: additionalInfo.patient_moved_by_stretcher ? additionalInfo.patient_moved_by_stretcher : false,
            patient_physically_restrained: additionalInfo.patient_physically_restrained ? additionalInfo.patient_physically_restrained : false,
            patient_transported_emergency: additionalInfo.patient_transported_emergency ? additionalInfo.patient_transported_emergency : false,
            patient_unconscious_shock: additionalInfo.patient_unconscious_shock ? additionalInfo.patient_unconscious_shock : false,
            patient_visible_haemorrhaging: additionalInfo.patient_visible_haemorrhaging ? additionalInfo.patient_visible_haemorrhaging : false,
        },
        dropoff_location_data: additionalInfo.dropoff_location_data ? {
            "street1": additionalInfo.dropoff_location_data.street1, "street2": additionalInfo.dropoff_location_data.street2,
            "city": additionalInfo.dropoff_location_data.city, "state": additionalInfo.dropoff_location_data.state,
            "country": additionalInfo.dropoff_location_data.country,
            "zip_code": additionalInfo.dropoff_location_data.zip_code
        }
            : {
                "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
            },
        dropoff_location: additionalInfo.dropoff_location ? additionalInfo.dropoff_location : "",
        pickup_location: additionalInfo.pickup_location ? additionalInfo.pickup_location : "",
        pickup_location_data: additionalInfo.pickup_location_data ? {
            "street1": additionalInfo.pickup_location_data.street1, "street2": additionalInfo.pickup_location_data.street2,
            "city": additionalInfo.pickup_location_data.city, "state": additionalInfo.pickup_location_data.state,
            "country": additionalInfo.pickup_location_data.country,
            "zip_code": additionalInfo.pickup_location_data.zip_code
        } : {
            "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
        },
    }
    return additonalData;
}

export const getServiceDateFrom = (date, procedure, rowId) => {
    let serviceDateFrom;
    procedure?.forEach((element, index) => {
        let procedureDosFrom = element.dos_from ? element.dos_from : "";
        if (index !== rowId) {
            if (
                procedureDosFrom &&
                new Date(procedureDosFrom) < new Date(date)
            ) {
                serviceDateFrom = procedureDosFrom;
            }
        }
    });

    return serviceDateFrom ? serviceDateFrom : date;
};

export const getServiceDateTo = (date, procedure, rowId) => {
    let serviceDateTo;
    procedure?.forEach((element, index) => {
        let procedureDosTo = element.dos_to ? element.dos_to : "";
        if (index !== rowId) {
            if (procedureDosTo && new Date(procedureDosTo) > new Date(date)) {
                serviceDateTo = procedureDosTo;
            }
        }
    });

    return serviceDateTo ? serviceDateTo : date;
};

export const validateModifier = (procedures) => {
    let modifiervalidate = [];
    let modifier1 = [];
    let modifier2 = [];
    let modifier3 = [];
    let modifier4 = [];

    procedures.forEach((item, index) => {
        let M1 = validateModifierField(item.m1_id);
        let M2 = validateModifierField(item.m2_id);
        let M3 = validateModifierField(item.m3_id);
        let M4 = validateModifierField(item.m4_id);

        modifier1[index] = modifier2[index] = modifier3[index] = modifier4[index] = true;
        modifiervalidate[index] = true;

        if (M4) {
            modifier1[index] = M1;
            modifier2[index] = M2;
            modifier3[index] = M3;
            if (!M1 || !M2 || !M3) {
                modifiervalidate[index] = false;
            }
        } else if (M3) {
            modifier1[index] = M1;
            modifier2[index] = M2;
            modifier3[index] = true;
            if (!M1 || !M2) {
                modifiervalidate[index] = false;
            }
        } else if (M2) {
            modifier1[index] = M1;
            modifier2[index] = true;
            if (!M1) {
                modifiervalidate[index] = false;
            }
        }
    });

    return {
        modifier1,
        modifier2,
        modifier3,
        modifier4,
        modifiervalidate,
    };
};

const validateModifierField = (field) => Array.isArray(field) ? field.length > 0 : !!field;

export const validateDiagnosis = (serviceData, validationState) => {
    let dgnValid = validationState;
    let tmpName = "";
    let foundTopOrderValue = false;
    let isValidted = true;
    let isDuplicate = false;
    let codeList = [];
    for (let dgCnt = 12; dgCnt > 0; dgCnt--) {
        tmpName = "diagnosis" + String(dgCnt);
        if (serviceData[tmpName]) {
            foundTopOrderValue = true;
            dgnValid[tmpName] = "";
            codeList.push(parseInt(serviceData[tmpName]));
        } else if (foundTopOrderValue && !serviceData[tmpName]) {
            dgnValid[tmpName] = " input-error";
            isValidted = false;
        } else if (!foundTopOrderValue) {
            dgnValid[tmpName] = "";
        }
    }
    let duplicateList = findDuplicates(codeList);
    if (isValidted && duplicateList.length > 0) {
        isDuplicate = true;
        isValidted = false;
    }

    return {
        dgnValid: dgnValid,
        isDuplicate: isDuplicate,
        isValidted: isValidted,
    };
};


export const claimFormWaringMessage = (data) => {
    let warningText = "";
    if (data.claim_validations &&
        data.claim_validations.length > 0) {
        data.claim_validations.map((item) => {
            let lbl = Array.isArray(item) ? item[0] : item;
            let l_text = "<div><span style='width: 40px; display: inline-block; text-align: right;'>" + (lbl) + ".&nbsp;&nbsp;</span><span>" + i18n.t("warning.claim." + lbl) + "</div>";
            if (Array.isArray(item)) {
                l_text = l_text.replace("{" + Object.keys(item[1])[0] + "}", Object.values(item[1])[0]);
            }
            warningText = warningText + l_text
        })
    }

    return warningText;
}


export const setWarningFromServer = (response) => {
    let warningsArray = [];
    warningsArray = warningsArray.filter(function (obj) {
        return obj.type !== 'validation';
    });
    if (response?.claim_validations && response?.claim_validations?.length > 0) {
        let claimValidations = response?.claim_validations;
        claimValidations.forEach((item) => {
            let warningExist = warningsArray.filter((warningObj) => warningObj.tabName === item);
            let warningCode = '';
            let warningMessages = '';
            if (Array.isArray(item)) {
                warningCode = item[0];
                let optionString = i18n.t('warning.claim.' + warningCode);
                warningMessages = optionString.replace(/{(\w+)}/g, function (_, k) {
                    return item[1][k];
                });
            } else {
                warningCode = item;
                warningMessages = i18n.t('warning.claim.' + warningCode);
            }

            let warnings = { "tabName": warningCode, "type": "validation" }
            warnings[warningMessages] = "";
            if (warningExist) {
                let warningIndexItem = '';
                if (Array.isArray(item))
                    warningIndexItem = item[0];
                else
                    warningIndexItem = item;
                let warningIndex = warningsArray.findIndex(items => items.tabName === warningIndexItem);
                if (warningIndex < 0) {
                    warningsArray.push(warnings);
                } else {
                    warningsArray[warningIndex] = warnings;
                }
            } else {
                warningsArray.push(warnings);
            }
        })
    }

    return warningsArray;
}


/**
 * @description Util function to validate if any empty slots exists b/w icds on ICD Handle Change reducer
 * @param {*} slotValues 
 * @returns Boolean
 */
export const checkForGapsBetweenIcd = (slotValues) => {
    const keys = Object.keys(slotValues);
    let lastFilledIndex = -1;
    let emptyFoundAfterFilled = false;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (i === 0 && slotValues[key] === "") {
            // Check if any non-empty values exist after the first key
            return keys.slice(1).some(k => slotValues[k] !== "");
        }

        if (slotValues[key]) {
            if (emptyFoundAfterFilled) {
                return true;
            }
            lastFilledIndex = i;
        } else {
            if (lastFilledIndex !== -1) {
                emptyFoundAfterFilled = true;
            }
        }
    }
    return false;
}


/**
 * @description Util function to validate if user is pointing the DX pointers to the ICDs which are selected
 * @param {Object} serviceLineDetails Redux State
 * @param {string} requestDxPointers user input string of dx pointer
 * @returns Boolean
 */
export const validateIsValidDxPointer = (serviceLineDetails, requestDxPointers) => {
    let dxPointers = [...requestDxPointers];
    let isValidPointer = true;
    const dxPointerKeyMapping = {
        "A": "diagnosis1",
        "B": "diagnosis2",
        "C": "diagnosis3",
        "D": "diagnosis4",
        "E": "diagnosis5",
        "F": "diagnosis6",
        "G": "diagnosis7",
        "H": "diagnosis8",
        "I": "diagnosis9",
        "J": "diagnosis10",
        "K": "diagnosis11",
        "L": "diagnosis12"
    };

    dxPointers.forEach((item) => {
        if (!serviceLineDetails[dxPointerKeyMapping[item]]) {
            isValidPointer = false;
        }
    })
    return isValidPointer;
}


export const updateDXpointer = (serviceLineDetails) => {
    const dxPointerKeyMapping = {
        "A": "diagnosis1",
        "B": "diagnosis2",
        "C": "diagnosis3",
        "D": "diagnosis4",
        "E": "diagnosis5",
        "F": "diagnosis6",
        "G": "diagnosis7",
        "H": "diagnosis8",
        "I": "diagnosis9",
        "J": "diagnosis10",
        "K": "diagnosis11",
        "L": "diagnosis12"
    };

    let dxPointers = "";

    for (let key in dxPointerKeyMapping) {
        const diagnosisKey = dxPointerKeyMapping[key];
        if (serviceLineDetails[diagnosisKey]) {
            dxPointers += key;
        }
    }

    return dxPointers;
}
/**
 * creating payer info structure
 * @param {*} insuranceData 
 * @param {*} claimPK 
 */
export const makePayerInsuranceData=(insuranceData, claimPK)=>{
    let arrInsurePriority = [];
    let newarrInsurance = [];
    let InsuranceObject = {};
    let primaryInsList = [];
    let secondaryInsList = [];
    let tertiaryInsList = [];
    let claim_pk = !claimPK ? "add_new" : claimPK;
    let updateValue;
    if (Array.isArray(insuranceData) && insuranceData.length > 0) {
                    insuranceData.forEach((item) => {
                        let data = {
                            id: item.Id,
                            name: item.name,
                            policy_id: item.policy_id,
                        }
                        const priorityObj = PRIORITIES.find(pIObj => pIObj.id === item.priority);
                        const arryObj = {
                            practice_insurance_id: item.Id,
                            policy_id: item.policy_id,
                        };
    
                        if (priorityObj) {
                            arryObj.priority = priorityObj.id;
                        }
                        arrInsurePriority.push(arryObj);
                        newarrInsurance.push({...arryObj,Id:arryObj.practice_insurance_id});
                        if (item.priority === 1) {
                            if (primaryInsList.length === 0) {
                                InsuranceObject = {
                                    ...InsuranceObject,
                                    insurancePrimary: item.Id,
                                    primarypolicyId: item.policy_id
                                }
                            }
                            primaryInsList.push(data);
                        }
                        else if (item.priority === 2) {
                            if (secondaryInsList.length === 0) {
                                InsuranceObject = {
                                    ...InsuranceObject,
                                    insurancesecondary: item.Id,
                                    secondarypolicyId: item.policy_id,
                                }
                            }
                            secondaryInsList.push(data)
                        } else {
                            if (tertiaryInsList.length === 0) {
                                InsuranceObject = {
                                    ...InsuranceObject,
                                    insuranceTertiary: item.Id,
                                    tertiarypolicyId: item.policy_id,
                                }
                            }
                            tertiaryInsList.push(data);
                        }
    
                    })
                }
    
    
                if(arrInsurePriority.length>0 && claim_pk == "add_new"){
                    updateValue={
                        payer_info: newarrInsurance,
                        payerInsuranceList:arrInsurePriority,
                        ...InsuranceObject,
                        primaryInsuranceDataList: primaryInsList.length>0 ? primaryInsList : [],
                        secondaryInsuranceDataList: secondaryInsList.length>0 ? secondaryInsList : [],
                        tertiaryInsuranceDataList: tertiaryInsList.length>0 ? tertiaryInsList : [],
                    }
                }else{
                    updateValue={
                        primaryInsuranceDataList: primaryInsList.length>0 ? primaryInsList : [],
                        secondaryInsuranceDataList: secondaryInsList.length>0 ? secondaryInsList : [],
                        tertiaryInsuranceDataList: tertiaryInsList.length>0 ? tertiaryInsList : [],
                    }
                }
        return updateValue;
}
