// React and PropTypes
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material-UI components
import { Grid, Paper } from '@mui/material';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getChartsListByTab, updateChartList } from './StateManagement/DashboardSlice';

// Custom components
import ChartSkeleton from './ChartSkeleton';
import ErrorFallback from './ErrorFallback';
import CommonChart from './Charts/CommonChart';

// Services
import { getDashboardsList } from './service';



/**
 * CommonDashboardTab Component
 * 
 * This component fetches and displays coding SLA charts.
 *
 * @param {Object} props
 * @param {Function} props.showNotifyWindow - Function to show notification window
 * * @param {String} props.tabId - key of the parent tab
 * @returns {React.ReactElement} The rendered coding SLA charts or loading skeletons
 */
function CommonDashboardTab({ showNotifyWindow, tabId }) {
    const dispatch = useDispatch();

    // Cached Chart list for the tab from Redux Store
    const chartListFromRedux = useSelector(state => getChartsListByTab(state, tabId));

    const [charts, setCharts] = useState([]);
    const [apiStatus, setApiStatus] = useState(null);


    useEffect(() => {
        if (Array.isArray(chartListFromRedux)) {
            setCharts(chartListFromRedux);
            setApiStatus("success");
        } else {
            getCharts();
        }
    }, []);


    /**
     * Fetches dashboard charts
     * @async
     * @function getCharts
     */
    const getCharts = async () => {
        try {
            setApiStatus('loading');
            const response = await getDashboardsList(tabId);
            if (!response.data) throw new Error("Chart data not found or an error occurred while fetching chart list.");

            // Dispatch the response charts list to redux for caching purpose
            dispatch(updateChartList({
                dashboardTab: tabId,
                chartsList: response.data
            }))

            setCharts(response.data);
            setApiStatus("success");
        } catch (error) {
            console.error(error);
            showNotifyWindow("show", "error", "An error occurred while fetching chart list.");
            setApiStatus("error");
        }
    };


    const renderChart = (chart) => {
        try {
            switch (chart.chartType) {
                case 'combo_bar':
                case 'vertical_bar':
                case 'donut':
                    return <CommonChart chartObject={chart} showNotifyWindow={showNotifyWindow} />;
                default:
                    return null;
            }
        } catch (error) {
            console.error(`Error rendering chart ${chart.id}:`, error);
            return (
                <ErrorFallback
                    errorMessage={`Error in chart: ${chart.chartName}`}
                    errorSubtext="There was an issue rendering this chart."
                    retryFunction={() => { renderChart(chart) }}
                />
            );
        }
    };

    const renderContent = () => {
        if (apiStatus == 'loading') return <ChartSkeleton count={8} />;

        if (apiStatus == "error") {
            return (
                <Grid item xs={12}>
                    <ErrorFallback
                        errorMessage="Oops! Something went wrong"
                        errorSubtext="We're having trouble loading the Coding SLA charts. This might be due to a temporary glitch or network issue. Let's give it another shot!"
                        retryFunction={getCharts}
                    />
                </Grid>
            );
        }

        return charts.map((chart) => (
            <Grid item xs={chart.gridWidth} key={chart.id}>
                <Paper elevation={chart.gridElevation} sx={{ height: chart.gridMinHeight }}>
                    {renderChart(chart)}
                </Paper>
            </Grid>
        ));
    };

    return (
        <div>
            <Grid container spacing={2}>
                {renderContent()}
            </Grid>
        </div>
    );
}

CommonDashboardTab.propTypes = {
    showNotifyWindow: PropTypes.func.isRequired,
    tabId: PropTypes.string.isRequired
};

export default CommonDashboardTab;

/**
 * Chart data structure:
 * @typedef {Object} ChartData
 * @property {number} id - The unique identifier for the chart
 * @property {string} chartName - The name of the chart
 * @property {number} gridIndexPosition - The position of the chart in the grid
 * @property {number} gridWidth - The width of the chart in the grid
 * @property {string} gridMinHeight - The minimum height of the chart
 * @property {string} gridElevation - The elevation of the chart's Paper component
 * @property {string} parent - The parent category of the chart
 * @property {string} chartType - The type of the chart
 * @property {string} apiUri - The API endpoint to fetch the chart data
 */
