import { createSlice } from '@reduxjs/toolkit'
import { isValidFilterConfig } from '../../../utilities/commonUtilities';
import i18n from '../../../utilities/i18n';

let initialState = {
    tableData: [],
    loading: false,
    pageSize: 0,
    count: 0,
    totalPage: 0,
    sortField: "", // Current SortField
    orderType: "", // Current Sort Order Asc or Dsc
    filterConfig: [
        {
            fieldName: "dateOfServiceFrom",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfServiceFrom"),
        },
        {
            fieldName: "dateOfServiceTo",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfServiceTo"),
            minDateField: "dateOfServiceFrom"
        },
        {
            fieldName: "dateOfEntryFrom",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfEntryFrom")
        },
        {
            fieldName: "dateOfEntryTo",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfEntryTo"),
            minDateField: "dateOfEntryFrom"
        },
        {
            fieldName: "firstBilledDateFrom",
            type: "dateField",
            label: i18n.t("reports.filters.firstBilledDateFrom"),
            minDateField: "dateOfServiceFrom"
        },
        {
            fieldName: "firstBilledDateTo",
            type: "dateField",
            label: i18n.t("reports.filters.firstBilledDateTo"),
            minDateField: "firstBilledDateFrom"
        },
        {
            fieldName: "lastBilledDateFrom",
            type: "dateField",
            label: i18n.t("reports.filters.lastBilledDateFrom"),
            minDateField: "firstBilledDateFrom"
        },
        {
            fieldName: "lastBilledDateTo",
            type: "dateField",
            label: i18n.t("reports.filters.lastBilledDateTo"),
            minDateField: "lastBilledDateFrom"
        },
        {
            fieldName: "payerType",
            type: "multiSelect",
            label: i18n.t("reports.filters.payerType"),
            dropDownOptionName: "payerTypes"
        },
        {
            fieldName: "insurance_companies",
            type: "multiSelect",
            label: i18n.t("reports.filters.insuranceCompanies"),
            dropDownOptionName: "insuranceCompanyList"
        },
        {
            fieldName: "claimStatus",
            type: "multiSelect",
            label: i18n.t("reports.filters.claimStatus"),
            dropDownOptionName: "claimStatusList"
        },
        {
            fieldName: "claimSubStatus",
            type: "multiSelect",
            label: i18n.t("reports.filters.claimSubStatus"),
            dropDownOptionName: "claimSubStatusList"
        },
        {
            fieldName: "renderingProvider",
            type: "multiSelect",
            label: i18n.t("reports.filters.renderingProvider"),
            dropDownOptionName: "renderingProviderList"
        },
        {
            fieldName: "refferingProvider",
            type: "multiSelect",
            label: i18n.t("reports.filters.refferingProvider"),
            dropDownOptionName: "referringProviderList"
        },
        {
            fieldName: "billingProvider",
            type: "multiSelect",
            label: i18n.t("reports.filters.billingProvider"),
            dropDownOptionName: "renderingProviderList"
        },
        {
            fieldName: "serviceLocation",
            type: "multiSelect",
            label: i18n.t("reports.filters.serviceLocation"),
            dropDownOptionName: "serviceLocationList"
        },
        {
            fieldName: "cptList",
            type: "multiSelect",
            label: i18n.t("reports.filters.cptList"),
            dropDownOptionName: "cptList"
        },
        {
            fieldName: "ICD",
            type: "multiSelect",
            label: i18n.t("reports.filters.ICD"),
            dropDownOptionName: "icdList"
        },
        {
            fieldName: "chargeEnteredUser",
            type: "multiSelect",
            label: i18n.t("reports.filters.chargeEntered"),
            dropDownOptionName: "praticeUserList"
        },
        {
            fieldName: "paymentEnteredUser",
            type: "multiSelect",
            label:  i18n.t("reports.filters.paymentEntered"),
            dropDownOptionName: "praticeUserList"
        },
        {
            fieldName: "dateOfPaymentEntryFrom",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfPaymentEntryFrom"),
        },
        {
            fieldName: "dateOfPaymentEntryTo",
            type: "dateField",
            label: i18n.t("reports.filters.dateOfPaymentEntryTo"),
            minDateField: "dateOfPaymentEntryFrom"
        },
        {
            fieldName: "paymentCheckDateFrom",
            type: "dateField",
            label: i18n.t("reports.filters.paymentCheckDateFrom"),
        },
        {
            fieldName: "paymentCheckDateTo",
            type: "dateField",
            label: i18n.t("reports.filters.paymentCheckDateTo"),
            minDateField: "paymentCheckDateFrom"
        },
        {
            fieldName: "paymentCheckNumber",
            type: "text",
            label: i18n.t("reports.filters.paymentCheckNumber")
        },
        {
            fieldName: "aging",
            type: "select",
            label: i18n.t("reports.filters.aging"),
            dropDownOptionName: 'agingList'
        },
        {
            fieldName: 'balanceAmount',
            label: i18n.t("reports.filters.balanceAmountTotals"),
            type: 'amountFilter',
            fieldPrefix: 'balanceAmountTotals',
        }
    ].map(item => {
        if (!isValidFilterConfig(item)) {
            throw new Error(`Invalid filter config for ${item.fieldName}`);
        }
        return item;
    }),
    filters: { // Filter input variables
        dateOfServiceFrom: "",
        dateOfServiceTo: "",
        dateOfPaymentEntryFrom: "",
        dateOfPaymentEntryTo:"",
        dateOfEntryFrom: "",
        dateOfEntryTo: "",
        firstBilledDateFrom: "",
        firstBilledDateTo: "",
        lastBilledDateFrom: "",
        lastBilledDateTo: "",
        payerType: [],
        insurance_companies: [],
        claimStatus: [],
        claimSubStatus: [],
        renderingProvider: [],
        refferingProvider: [],
        billingProvider: [],
        serviceLocation: [],
        cptList: [],
        ICD: [],
        chargeEnteredUser: [],
        paymentEnteredUser: [],
        balanceAmountTotalsType: "",
        balanceAmountTotalsFrom: "",
        balanceAmountTotalsTo: "",
        aging: "",
        paymentCheckDateFrom : "",
        paymentCheckDateTo : "",
        paymentCheckNumber: ""
        
    },
    allSortFieldStates: {
        "custom_claim_id": true,
        "entered_date": true,
        "first_billed_date": true,
        "last_billed_date": true,
        "service_from_date": true,
        "service_to_date": true,
        "check_number": true,
        "check_date": true,
    },
    isError: 'not-set'
}

export const PaymentAdjustmentSlice = createSlice({
    name: "paymentAdjustment",
    initialState,
    reducers: {
        onResetData: () => initialState,
        updatePaymentAdjustmentReport: (state, action) => {
            if (action.payload.type === "success") {
                state.count = action.payload.data.data.count;
                state.pageSize = action.payload.data.data.page_size;
                state.totalPage = Math.ceil(action.payload.data.data.count / action.payload.data.data.page_size)
                state.tableData = action.payload.data.data.results;
            }
            if (action.payload.type === "error") {
                state.isError = true
            }
        },
        updateSortField: (state, actions) => {
            // toggle all the sortfields to true except for the currently sorting field
            const newSortState = Object.keys(state.allSortFieldStates).reduce((acc, key) => {
                acc[key] = key === actions.payload.sortField ? false : true;
                return acc;
            }, {});
            return {
                ...state,
                sortField: actions.payload.sortField,
                orderType: actions.payload.sortOrder,
                allSortFieldStates: newSortState
            };
        },
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
    }
});

export const {
    onResetData, updatePaymentAdjustmentReport, updateSortField, updateFieldValue
} = PaymentAdjustmentSlice.actions

export default PaymentAdjustmentSlice.reducer;