import api from '../../../service/api'

const ListInsuranceCompanies = async (...args) => {
    let [pageSize, page, isAdminModule, practicePK, practiceDict, list, searchValue, orderingField, orderType] = args;
    let path = null;
    if (isAdminModule === 'true')
        path = 'super-admin/insurance-company/?page_size=' + pageSize + '&page=' + page;
    else if (practiceDict)
        path = 'super-admin/insurance-company/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    else
        path = 'practice/insurance-company/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    if (list)
        path += '&list=' + list;
    if (searchValue)
        path += '&search=' + searchValue;
    if (orderingField) {
        let fieldOrder = '';
        if (orderType) fieldOrder += orderType;
        fieldOrder += orderingField;
        path += '&column=' + fieldOrder;
    } else {
        path += '&column=payer_id';
    }
    return api.get(path);
}

const AddInsuranceCompany = async (data) => {
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, "practice"))
    {
        path = 'practice/insurance-company/';
    }
    else
    {
        path = 'super-admin/insurance-company/';
    }
        return api.post(path, data);
}

const GetInsuranceCompany = async (InsuranceCompanyId, practicePK, isAdminModule) => {
    let path = null;
    if (isAdminModule === 'true'){
        path = 'super-admin/insurance-company/' + InsuranceCompanyId;
    }
    else if (practicePK){
        path = 'practice/insurance-company/' + InsuranceCompanyId;
    }
    else{
        path = 'super-admin/insurance-company/' + InsuranceCompanyId;
    }
        return api.get(path);
}
const UpdateInsuranceCompany = async (InsuranceCompanyId, data,isAdminModule) => {
    let path = null;
    if (isAdminModule === "true") {
        path = 'super-admin/insurance-company/' + InsuranceCompanyId
    }
    else if (Object.prototype.hasOwnProperty.call(data, 'practice')) {
        path = 'practice/insurance-company/' + InsuranceCompanyId;
    }
    else{
        path = 'super-admin/insurance-company/' + InsuranceCompanyId;
    }
    return api.put(path, data);
}
const DeleteInsuranceCompany = async (InsuranceCompanyId, practicePK) => {
    let path = null;
    if (practicePK){
        path = 'practice/insurance-company/' + InsuranceCompanyId
    }
    else{
        path = 'super-admin/insurance-company/' + InsuranceCompanyId
    }
    return api.destroy(path);
}

const ImportCSVData = async (data) => {
    let path = 'super-admin/import-insurance-company/'
    return api.post(path, data);
}

const ListClaimStatus = async (pageSize, page, practicePK) => {
    let path = null;
    path = 'practice/claim-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    return api.get(path);
}

const GetPayerTypesList = async (pageSize, page) => {
    let path = 'super-admin/payer-type/?page_size=' + pageSize + '&page=' + page;
    return api.get(path);
}

const GetPracticeInsuranceMasterData = async (pageSize, page, orderingField, searchFilterVariables, practicePK) => {
    let path = 'super-admin/insurance-company/?page_size=' + pageSize + '&page=' + page;
    if (orderingField) {
        path += '&column=' + orderingField;
    } else {
        path += '&column=payer_id';
    }
    let filter = 0
    if (searchFilterVariables.name_contains || searchFilterVariables.apms_payer_id || searchFilterVariables.payer_id) {
        filter = 1
    }
    path += '&filter=' + filter;
    path += '&name=' + searchFilterVariables.name_contains;
    path += '&apms_payer_id=' + searchFilterVariables.apms_payer_id;
    path += '&payer_id=' + searchFilterVariables.payer_id;
    path += '&exact_match_only=' + searchFilterVariables.exact_match_only;
    path += '&practice_pk=' + practicePK;
    return api.get(path);
}

const exportInsuranceCompany = async (type, practicePK, pageSize, page, orderingField, searchFilterVariables) => {
    let path = 'practice/insurance-company/?page_size=' + pageSize + '&page=' + page;
    if (orderingField) {
        path += '&column=' + orderingField;
    } else {
        path += '&column=payer_id';
    }
    let filter = 0
    if (searchFilterVariables.name_contains || searchFilterVariables.apms_payer_id || searchFilterVariables.payer_id) {
        filter = 1
    }
    path += '&filter=' + filter;
    path += '&name=' + searchFilterVariables.name_contains;
    path += '&apms_payer_id=' + searchFilterVariables.apms_payer_id;
    path += '&payer_id=' + searchFilterVariables.payer_id;
    path += '&exact_match_only=' + searchFilterVariables.exact_match_only;
    path += '&practice_pk=' + practicePK;
    path += '&export=true&export_type=' + type;
    let result;
    if (type === 'xls') {
        result = api.getXLS(path);
    } else {
        result = api.get(path);
    }
    return result;}

const getPaymentSourceCodesListAll = async (pageSize, page) => {
    let path = 'super-admin/payment-source/?page_size=' + pageSize + '&page=' + page;
    return await api.get(path)
}

const getClaimSubmission = async () => {
    let path = 'super-admin/claim-submission-mode/';
    return  api.get(path)
}

export default {
    exportInsuranceCompany,
    ListInsuranceCompanies, AddInsuranceCompany, GetInsuranceCompany, UpdateInsuranceCompany, DeleteInsuranceCompany,
    ImportCSVData, ListClaimStatus, GetPayerTypesList, GetPracticeInsuranceMasterData, getPaymentSourceCodesListAll,getClaimSubmission
}