import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import "./style.css";
import service from "./service";

import claimService from "../../ClaimsModule/SearchClaims/service";


import TextInput from "../../commons/input/input";
import SelectInput from "../../commons/input/SelectInput";
import PhoneInputWithCode from "../../commons/PhoneInputWithCode/PhoneInputWithCode";
import EmailInput from "../../commons/EmailInput/EmailInput";
import GeoCodeSearch from "../../commons/helpers/geoCodeApiSearch";

import i18n from "../../../utilities/i18n";
import { getStorage } from "../../../utilities/browserStorage";
import { ValidateEmail } from "../../../utilities/commonUtilities";
import {
  DEFAULTRELATION,

  DEFAULT_COUNTRY,
  DEFAULT_PAGING_SIZE,
} from "../../../utilities/staticConfigs";
import {
  ETHNICITIES,
  RACES,
  YesOrNo,
} from "../../../utilities/dictionaryConstants";
import { CityInput } from "../../commons/input/CityInput";
import { US_STATES } from "../../../utilities/usStates";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientOtherInfo } from "../../../store/patient/patientInfoSlice";
import { Divider } from "@mui/material";

// let defaultProviderId = "";

const PatientInfo = (props) => {
  const [practicePK, setPracticePK] = useState(getStorage("practice"));
  const activePage = 1;
  const patientPK = props.patientPK;
  const [valueChanges, setValueChanges] = useState(false);

  //selector values taken from redux
  const patientInfoDetails = useSelector(
    (stateData) => stateData.patientData.patientInfoDetails
  );

  const patientPkFromRedux = useSelector((state) => state.patientData.patientAddDetails.id);

  const rendering_provider_list = useSelector(
    (stateValue) => stateValue.patientData.renderingProviderList
  );

  // variables
  const [patientInfoVariables, setPatientInfoVariables] = useState({
    id: "",
    active: true,
    authorized_contact: 1,
    cell_phone: "",
    email: "",
    emergency_as_guarantor: true,
    emergency_cell_phone: "",
    emergency_email: "",
    emergency_first_name: null,
    emergency_home_phone: null,
    emergency_last_name: null,
    emergency_location: null,
    emergency_location_data: {
      city: "",
      country: "",
      state: "",
      street1: "",
      street2: "",
      zip_code: "",
    },
    emergency_middle_name: null,
    emergency_relationship: "",
    emergency_relationship_name: "",
    emergency_suffix: null,
    emergency_work_phone: "",
    ethnicity: 1,
    facility_location: null,
    guarantor_address_as_patient: false,
    guarantor_cell_phone: "",
    guarantor_email: null,
    guarantor_first_name: null,
    guarantor_home_phone: null,
    guarantor_last_name: null,
    guarantor_location: null,
    guarantor_location_data: {
      city: "",
      country: "",
      state: "",
      street1: "",
      street2: "",
      zip_code: "",
    },
    guarantor_middle_name: null,
    guarantor_relationship: "",
    guarantor_relationship_name: "",
    guarantor_suffix: null,
    guarantor_work_phone: "",
    home_phone: "",
    location: "",
    location_data: {
      city: "",
      country: DEFAULT_COUNTRY,
      state: "",
      street1: "",
      street2: "",
      zip_code: "",
    },
    patient: "",
    patient_status: true,
    practice_location: "",
    preferred_language: "",
    race: "",
    referring_provider: null,
    referring_provider_group: "",
    rendering_provider: null,
    work_phone: "",
    service_location_value: "",
    service_location: {},
  });

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZIP] = useState("");
  const [country, setCountry] = useState("");
  const [serviceLocation, setServiceLocation] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [streetValidity, setStreetValidity] = useState({
    street: false,
    guarantorStreet: false,
    emergencyStreet: false
  });

  const ethnicityList = ETHNICITIES;
  const raceList = RACES;
  const [languageList, setLanguageList] = useState([]);
  const renderingProviderList = rendering_provider_list;

  const [serviceLocationList, setServiceLocationList] = useState([]);
  const [referringProviderList, setReferringProviderList] = useState([]);
  const [referringProviderGroupList, setReferringProviderGroupList] = useState(
    []
  );
  const [relationShipList, setRelationShipList] = useState([]);

  const dispatch = useDispatch();
  const [defaultId, setDefaultId] = useState("");

  const [guaratorCity, setGuaratorCity] = useState("");
  const [guaratorState, setGuaratorState] = useState("");
  const [guaratorZip, setGuaratorZIP] = useState("");
  const [guaratorCountry, setGuaratorCountry] = useState("");
  const [guaratorHomePhone, setGuaratorHomePhone] = useState("");
  const [guaratorWorkPhone, setGuaratorWorkPhone] = useState("");
  const [guaratorCellPhone, setGuaratorCellPhone] = useState("");
  const [guaratorSelf, setGuarantorSelf] = useState(false);

  const [emergencySelf, setEmergencySelf] = useState(false);

  const [emergencyRelationShip, setEmergencyRelationShip] = useState("");
  const [emergencyCity, setEmergencyCity] = useState("");
  const [emergencyState, setEmergencyState] = useState("");
  const [emergencyZip, setEmergencyZIP] = useState("");
  const [emergencyCountry, setEmergencyCountry] = useState("");
  const [emergencyHomePhone, setEmergencyHomePhone] = useState("");
  const [emergencyWorkPhone, setEmergencyWorkPhone] = useState("");
  const [emergencyCellPhone, setEmergencyCellPhone] = useState("");

  const [disableGuarantorAddressFields, setDisableGuarantorAddressFields] =
    useState(patientInfoVariables.guarantor_address_as_patient);

      //start ==== Alert message properties

  //End ====

  // Function to Dispatch missing input fields to Redux to show the Warnings
  // Accepted args: name === "key of the input field", value: "actual input value or dummy value - doesn't matter"
  const checkFormWarning = (name, value) => {
    const node = "patientInfo" // Tab name
    dispatch({ type: "patientInfo/setPatientFormWarnings", payload: { name, value, node, patientPK: patientPkFromRedux || "" } })
    props.setFormWarningStatus(!props.formWarningStatus); // Toggling the formWarningStatus to re-render the component after dispatch is executed
  };
  /* *********************************************** */

  //Warning Message changes --- end
  function onHandlePatientInfoLocationDataChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'street2' && patientInfoVariables.location_data.street1 === "") {
      setStreetValidity(prevState => ({ ...prevState, street: true }));
    } else {
      setStreetValidity(prevState => ({ ...prevState, street: false }));
    }
    setPatientInfoVariables({
      ...patientInfoVariables,
      location_data: {
        ...patientInfoVariables.location_data,
        [name]: value,
      },
    });
    checkFormWarning(name, value);
    setValueChanges(true);
  }

  useEffect(() => {
    checkFormWarning("homePhone", homePhone);
  }, [homePhone]);

  useEffect(() => {
    setPatientInfoVariables({
      ...patientInfoVariables,
      location_data: {
        ...patientInfoVariables.location_data,
        zip_code: zip,
        city: city,
        state: state,
        country: country,
      },
    });
  }, [city, country, state, zip]);

   //For setting default rendering provider and service location
  useEffect(() => {
    setPatientInfoVariables((prevState) => ({
      ...prevState,
      rendering_provider: patientInfoDetails?.rendering_provider
        ? patientInfoDetails?.rendering_provider
        : rendering_provider_list.length > 0
        ? rendering_provider_list.find((provider) => provider.default_provider === true)?.id
        : null,
  
      service_location_value: patientInfoDetails?.service_location_value
        ? patientInfoDetails?.service_location_value
        : serviceLocationList.length > 0
        ? serviceLocationList.find((loc) => loc.default_location === true)?.id
        : null,
  
      service_location: patientInfoDetails?.service_location_value
        ? {
            id: patientInfoDetails.service_location_value.split("||")[0], //spliting to get id
            location_type: patientInfoDetails.service_location_value.split("||")[1],  //spliting to get the type
          }
        : serviceLocationList.length > 0
        ? {
            id: serviceLocationList.find((loc) => loc.default_location === true)?.id.split("||")[0], 
            location_type: serviceLocationList.find((loc) => loc.default_location === true)?.id.split("||")[1],
          }
        : {},
    }));
  }, [rendering_provider_list, serviceLocationList]);
  
  useEffect(() => {
    if (workPhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        work_phone: workPhone,
      });
    }
  }, [workPhone]);

  useEffect(() => {
    if (homePhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        home_phone: homePhone,
      });
    }
  }, [homePhone]);

  useEffect(() => {
    if (cellPhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        cell_phone: cellPhone,
      });
    }
  }, [cellPhone]);

  function onHandleGuarantorLocationData(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'street2' && patientInfoVariables.guarantor_location_data.street1 === "") {
      setStreetValidity(prevState => ({ ...prevState, guarantorStreet: true }));
    } else {
      setStreetValidity(prevState => ({ ...prevState, guarantorStreet: false }));
    }
    setPatientInfoVariables({
      ...patientInfoVariables,
      guarantor_location_data: {
        ...patientInfoVariables.guarantor_location_data,
        [name]: value,
      },
    });
  }
  useEffect(() => {
    setPatientInfoVariables({
      ...patientInfoVariables,
      guarantor_location_data: {
        ...patientInfoVariables.guarantor_location_data,
        zip_code: guaratorZip,
        country: guaratorCountry,
      },
    });
  }, [guaratorZip, guaratorCountry]);

  useEffect(() => {
    setPatientInfoVariables({
      ...patientInfoVariables,
      guarantor_location_data: {
        ...patientInfoVariables.guarantor_location_data,
        city: guaratorCity,
      },
    });
  }, [guaratorCity]);

  useEffect(() => {
    setPatientInfoVariables({
      ...patientInfoVariables,
      guarantor_location_data: {
        ...patientInfoVariables.guarantor_location_data,
        state: guaratorState,
      },
    });
  }, [guaratorState]);

  useEffect(() => {
    setPatientInfoVariables({
      ...patientInfoVariables,
      guarantor_location_data: {
        ...patientInfoVariables.guarantor_location_data,
        country: guaratorCountry,
      },
    });
  }, [guaratorCountry]);

  useEffect(() => {
    if (guaratorWorkPhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        guarantor_work_phone: guaratorWorkPhone ? guaratorWorkPhone : "1",
      });
    } else if (guaratorHomePhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        guarantor_home_phone: guaratorHomePhone ? guaratorHomePhone : "1",
      });
    }

    if (guaratorCellPhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        guarantor_cell_phone: guaratorCellPhone ? guaratorCellPhone : "1",
      });
    }
  }, [guaratorCellPhone, guaratorHomePhone, guaratorWorkPhone]);

  function onHandleEmergencyLocationData(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "street2" && patientInfoVariables.emergency_location_data.street1 === "") {
      setStreetValidity((prevState) => ({ ...prevState, emergencyStreet: true }))
    } else {
      setStreetValidity((prevState) => ({ ...prevState, emergencyStreet: false }))
    }
    setPatientInfoVariables({
      ...patientInfoVariables,
      emergency_location_data: {
        ...patientInfoVariables.emergency_location_data,
        [name]: value,
      },
    });
    setValueChanges(true);
  }
  useEffect(() => {
    setPatientInfoVariables({
      ...patientInfoVariables,
      emergency_location_data: {
        ...patientInfoVariables.emergency_location_data,
        zip_code: emergencyZip,
        city: emergencyCity,
        state: emergencyState,
        country: emergencyCountry,
      },
    });
    // setValueChanges(true);
  }, [emergencyCity, emergencyState, emergencyCountry, emergencyZip]);

  useEffect(() => {
    if (emergencyHomePhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        emergency_home_phone: emergencyHomePhone ? emergencyHomePhone : "1",
      });
      setValueChanges(true);
    }
    if (emergencyWorkPhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        emergency_work_phone: emergencyWorkPhone ? emergencyWorkPhone : "1",
      });
      setValueChanges(true);
    }

    if (emergencyCellPhone) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        emergency_cell_phone: emergencyCellPhone ? emergencyCellPhone : "1",
      });
      setValueChanges(true);
    }
  }, [emergencyCellPhone, emergencyHomePhone, emergencyWorkPhone]);

  /**
   * setting patieninfovariable in state setPatientInfoVariable on selector value change (patientInfoDetails)
   */
  useEffect(() => {
    if (patientInfoDetails) {
      setPatientInfoVariables({
        ...patientInfoVariables,
        id: patientInfoDetails?.id,
        active: patientInfoDetails.active,
        authorized_contact: patientInfoDetails?.authorized_contact,
        cell_phone: patientInfoDetails?.cell_phone ?? "1",
        email: patientInfoDetails?.email ?? "",
        emergency_as_guarantor: patientInfoDetails?.emergency_as_guarantor,
        emergency_cell_phone: patientInfoDetails?.emergency_cell_phone ?? "1",
        emergency_email: patientInfoDetails?.emergency_email ?? "",
        emergency_first_name: patientInfoDetails?.emergency_first_name ?? "",
        emergency_home_phone: patientInfoDetails?.emergency_home_phone ?? "1",
        emergency_last_name: patientInfoDetails?.emergency_last_name ?? "",
        emergency_location: patientInfoDetails?.emergency_location,
        emergency_location_data: patientInfoDetails?.emergency_location_data
          ? {
            street1: patientInfoDetails?.emergency_location_data.street1,
            street2: patientInfoDetails?.emergency_location_data.street2,
            zip_code: patientInfoDetails?.emergency_location_data.zip_code,
            city: patientInfoDetails?.emergency_location_data.city,
            state: patientInfoDetails?.emergency_location_data.state,
            country: patientInfoDetails?.emergency_location_data.country,
          }
          : {
            street1: "",
            street2: "",
            zip_code: zip,
            city: city,
            state: state,
            country: DEFAULT_COUNTRY,
          },
        emergency_middle_name: patientInfoDetails?.emergency_middle_name ?? "",
        emergency_relationship: patientInfoDetails?.emergency_relationship
          ? patientInfoDetails?.emergency_relationship
          : null,
        emergency_relationship_name:
          patientInfoDetails?.emergency_relationship_name,
        emergency_suffix: patientInfoDetails?.emergency_suffix ?? "",
        emergency_work_phone: patientInfoDetails?.emergency_work_phone ?? "1",
        ethnicity: patientInfoDetails?.ethnicity,
        service_location: patientInfoDetails?.service_location_value
          ? {
            id: patientInfoDetails?.service_location_value.split("||")[0],
            location_type: patientInfoDetails?.service_location_value.split("||")[1],
          }
          : {},
        service_location_value: patientInfoDetails?.service_location_value
          ? patientInfoDetails?.service_location_value : null,
        guarantor_address_as_patient:
          patientInfoDetails?.guarantor_address_as_patient,
        guarantor_cell_phone: patientInfoDetails?.guarantor_cell_phone ?? "1",
        guarantor_email: patientInfoDetails?.guarantor_email ?? "",
        guarantor_first_name: patientInfoDetails?.guarantor_first_name ?? "",
        guarantor_home_phone: patientInfoDetails?.guarantor_home_phone ?? "1",
        guarantor_last_name: patientInfoDetails?.guarantor_last_name ?? "",
        guarantor_location: patientInfoDetails?.guarantor_location ?? null,
        guarantor_location_data: patientInfoDetails?.guarantor_location_data
          ? {
            street1: patientInfoDetails?.guarantor_location_data.street1,
            street2: patientInfoDetails?.guarantor_location_data.street2,
            zip_code: patientInfoDetails?.guarantor_location_data.zip_code,
            city: patientInfoDetails?.guarantor_location_data.city,
            state: patientInfoDetails?.guarantor_location_data.state,
            country: patientInfoDetails?.guarantor_location_data.country,
          }
          : {
            street1: "",
            street2: "",
            zip_code: zip,
            city: city,
            state: state,
            country: DEFAULT_COUNTRY,
          },
        guarantor_middle_name: patientInfoDetails?.guarantor_middle_name ?? "",
        guarantor_relationship: patientInfoDetails?.guarantor_relationship
          ? patientInfoDetails?.guarantor_relationship
          : null,
        guarantor_relationship_name:
          patientInfoDetails?.guarantor_relationship_name,
        guarantor_suffix: patientInfoDetails?.guarantor_suffix ?? "",
        guarantor_work_phone: patientInfoDetails?.guarantor_work_phone ?? "1",
        home_phone: patientInfoDetails?.home_phone ?? "1",
        location: patientInfoDetails?.location,
        location_data: patientInfoDetails?.location_data
          ? {
            street1: patientInfoDetails?.location_data.street1,
            street2: patientInfoDetails?.location_data.street2,
            zip_code: patientInfoDetails?.location_data.zip_code,
            city: patientInfoDetails?.location_data.city,
            state: patientInfoDetails?.location_data.state,
            country: patientInfoDetails?.location_data.country,
          }
          : {
            street1: "",
            street2: "",
            zip_code: zip,
            city: city,
            state: state,
            country: DEFAULT_COUNTRY,
          },
        patient: patientInfoDetails?.patient,
        patient_status: patientInfoDetails?.patient_status,
        preferred_language: patientInfoDetails?.preferred_language,
        race: patientInfoDetails?.race,
        referring_provider: patientInfoDetails?.referring_provider,
        referring_provider_group: patientInfoDetails?.referring_provider_group,
        rendering_provider: patientInfoDetails?.rendering_provider
          ? patientInfoDetails?.rendering_provider
          : rendering_provider_list.length > 0
            ? rendering_provider_list.find(provider => provider.default_provider === true)?.id
            : null,
        work_phone: patientInfoDetails?.work_phone ?? "1",
      });

      /**
       * checking for mandatory field warings
       * @param {*} field
       * @param {*} value
       */

      let renderingProvider = patientInfoDetails?.rendering_provider
        ? patientInfoDetails?.rendering_provider
        : rendering_provider_list.length > 0
          ? rendering_provider_list.find(provider => provider.default_provider === true)?.id
          : null
      if (patientInfoDetails?.location_data) {
        checkFormWarning("street1", patientInfoDetails?.location_data.street1);
        checkFormWarning("city", patientInfoDetails?.location_data.city);
        checkFormWarning("state", patientInfoDetails?.location_data.state);
        checkFormWarning("zip", patientInfoDetails?.location_data.zip_code);
        props.setPatientLocationData(patientInfoDetails?.location_data);
      }
      checkFormWarning("homePhone", patientInfoDetails?.home_phone);
      checkFormWarning("rendering_provider", renderingProvider);

      if (
        (patientInfoDetails?.guarantor_relationship_name &&
          patientInfoDetails?.guarantor_relationship_name.toLowerCase() ===
          DEFAULTRELATION) ||
        !patientInfoDetails?.guarantor_relationship
      )
        setGuarantorSelf(true);
      else setGuarantorSelf(false);
      setEmergencyRelationShip(
        patientInfoDetails?.emergency_relationship
          ? patientInfoDetails?.emergency_relationship
          : defaultId
      );

      // let render_value = patientInfoDetails?.rendering_provider
      //   ? patientInfoDetails?.rendering_provider
      //   : defaultProviderId
      //     ? defaultProviderId
      //     : null;
      // checkFormWarning("rendering_provider", render_value);
      if (
        (patientInfoDetails?.emergency_relationship_name &&
          patientInfoDetails?.emergency_relationship_name.toLowerCase() ===
          DEFAULTRELATION) ||
        !patientInfoDetails?.emergency_relationship
      )
        setEmergencySelf(true);
      else setEmergencySelf(false);

      setTimeout(() => {
        props.setFormWarningStatus(!props.formWarningStatus);
      }, 1000);
    }

  }, [patientInfoDetails]);

  /**
   * updating patientInfoVariables in redux state on patientiInfoVariables state  change
   */

  useEffect(() => {
    if (valueChanges) {
      /**
       * checking for mandatory field warings
       * @param {*} props.patientPK
       * @param {*} patientInfoVariables
       */

      let data = {
        patientPK: props.patientPK,
        patientInfoVariables: patientInfoVariables,
      };
      dispatch(updatePatientOtherInfo(data));
    }
  }, [patientInfoVariables]);

  function onHandlePatientInfoChange(e) {
    const name = e.target.name;
    let value =
      e.target.type === "file"
        ? e.target.files[0]
        : e.target.type === "checkbox"
          ? e.target.checked
          : e.target.value;
    checkFormWarning(name, value);
    if (name === "guarantor_relationship") {
      setPatientInfoVariables((prev) => {
        let newData = null;
        let selId = e.target.value;
        let relSelfId = relationShipList.find(
          (element) => element.name.trim().toLowerCase() === DEFAULTRELATION
        );
        if (
          prev.guarantor_relationship === relSelfId.id &&
          prev.guarantor_relationship !== value
        )
          newData = {
            ...prev,
            guarantor_relationship: value,
            guarantor_first_name: null,
            guarantor_middle_name: null,
            guarantor_last_name: null,
            guarantor_suffix: null,
            guarantor_address_as_patient: false,
            guarantor_location_data: {
              city: "",
              country: "",
              state: "",
              street1: "",
              street2: "",
              zip_code: "",
            },
            guarantor_home_phone: "1",
            guarantor_work_phone: "1",
            guarantor_cell_phone: "1",
            guarantor_email: null,
          };
        else newData = { ...prev, guarantor_relationship: value };
        if (patientInfoVariables.emergency_as_guarantor) {
          newData["emergency_relationship"] = value;
          if (selId === relSelfId.id) {
            // selectedText === 'Self'
            setEmergencySelf(true);
          } else {
            setEmergencySelf(false);
          }
        }
        if (selId === relSelfId.id) {
          // selectedText === 'Self'
          setGuarantorSelf(true);
        } else {
          setGuarantorSelf(false);
        }
        return newData;
      });
    } else if (name === "guarantor_address_as_patient") {
      setDisableGuarantorAddressFields(value);
      setPatientInfoVariables((prevValue) => {
        let newValue = { ...prevValue, [name]: value };
        let newData = {};
        if (value) {
          newData = {
            ...newValue,
            guarantor_location_data: {
              city: patientInfoVariables.location_data.city,
              country: patientInfoVariables.location_data.country,
              state: patientInfoVariables.location_data.state,
              street1: patientInfoVariables.location_data.street1,
              street2: patientInfoVariables.location_data.street2,
              zip_code: patientInfoVariables.location_data.zip_code,
            },
            guarantor_home_phone: patientInfoVariables.home_phone
              ? patientInfoVariables.home_phone
              : "1",
            guarantor_work_phone: patientInfoVariables.work_phone
              ? patientInfoVariables.work_phone
              : "1",
            guarantor_cell_phone: patientInfoVariables.cell_phone
              ? patientInfoVariables.cell_phone
              : "1",
            guarantor_email: patientInfoVariables.email,
          };
          setGuaratorZIP(zip);
          setGuaratorCountry(country);
        } else {
          newData = {
            ...newValue,
            guarantor_location_data: {
              city: "",
              country: "",
              state: "",
              street1: "",
              street2: "",
              zip_code: "",
            },
            guarantor_home_phone: "1",
            guarantor_work_phone: "1",
            guarantor_cell_phone: "1",
            guarantor_email: "",
          };
          setGuaratorZIP("");
          setGuaratorCountry("");
        }
        return newData;
      });
    } else if (name === "emergency_as_guarantor") {
      setPatientInfoVariables((prevValue) => {
        let newValue = { ...prevValue, [name]: value };
        let newData = {};
        let relationShipSelfItem = relationShipList.find(
          (element) => element.name.toLowerCase() === DEFAULTRELATION
        );
        if (
          relationShipSelfItem.id ===
          patientInfoVariables.guarantor_relationship
        ) {
          setEmergencySelf(true);
        } else {
          setEmergencySelf(false);
        }
        if (value) {
          newData = {
            ...newValue,
            emergency_location_data:
              patientInfoVariables.guarantor_location_data,
            emergency_first_name: patientInfoVariables.guarantor_first_name,
            emergency_last_name: patientInfoVariables.guarantor_last_name,
            emergency_middle_name: patientInfoVariables.guarantor_middle_name,
            emergency_suffix: patientInfoVariables.guarantor_suffix,
            emergency_relationship: patientInfoVariables.guarantor_relationship,
            emergency_cell_phone: patientInfoVariables.guarantor_cell_phone,
            emergency_home_phone: patientInfoVariables.guarantor_home_phone,
            emergency_work_phone: patientInfoVariables.guarantor_work_phone,
            emergency_email: patientInfoVariables.guarantor_email,
          };
        } else {
          newData = {
            ...newValue,
            emergency_location_data: {
              city: "",
              country: "",
              state: "",
              street1: "",
              street2: "",
              zip_code: "",
            },
            emergency_first_name: "",
            emergency_last_name: "",
            emergency_middle_name: "",
            emergency_suffix: "",
            emergency_relationship: patientInfoVariables.guarantor_relationship,
            emergency_cell_phone: "1",
            emergency_home_phone: "1",
            emergency_work_phone: "1",
            emergency_email: ""
          };
        }
        return newData;
      });
    } else if (name === "emergency_relationship") {
      setPatientInfoVariables((prev) => {
        let newData = null;
        let selId = e.target.value;
        let relSelfId = relationShipList.find(
          (element) => element.name.trim().toLowerCase() === DEFAULTRELATION
        );
        if (
          prev.emergency_relationship === relSelfId.id &&
          prev.emergency_relationship !== value
        )
          newData = {
            ...prev,
            [name]: value,
            emergency_first_name: null,
            emergency_middle_name: null,
            emergency_last_name: null,
            emergency_suffix: null,
            emergency_cell_phone: "1",
            emergency_home_phone: "1",
            emergency_work_phone: "1",
            emergency_location_data: {
              city: "",
              country: "",
              state: "",
              street1: "",
              street2: "",
              zip_code: "",
            },
          };
        else newData = { ...prev, [name]: value };
        if (selId === relSelfId.id) {
          // selectedText === 'Self'
          setEmergencySelf(true);
          if (patientInfoVariables.guarantor_relationship === value) {
            newData["emergency_as_guarantor"] = true;
          } else {
            newData["emergency_as_guarantor"] = false;
          }
        } else {
          setEmergencySelf(false);
          if (patientInfoVariables.guarantor_relationship !== value) {
            newData["emergency_as_guarantor"] = false;
          }
        }
        return newData;
      });
    } else if (name === "service_location_value") {
      if (value) {
        let service_location = value.split("||");
        setPatientInfoVariables({
          ...patientInfoVariables,
          ["service_location"]: {
            id: service_location[0],
            location_type: service_location[1],
          },
          ["service_location_value"]: value
        });
      }
    } else {
      if (name === "email") {
        if (value && !ValidateEmail(value)) {
          props.setPatientInfoValidEmail(false);
        } else {
          props.setPatientInfoValidEmail(true);
        }
      }
      if (name === "emergency_email") {
        if (value && !ValidateEmail(value)) {
          props.setPatientInfoValidEmergencyEmail(false);
        } else {
          props.setPatientInfoValidEmergencyEmail(true);
        }
      }
      if (name === "guarantor_email") {
        if (value && !ValidateEmail(value)) {
          props.setPatientInfoValidGuarantorEmail(false);
        } else {
          props.setPatientInfoValidGuarantorEmail(true);
        }
      }
      let providerGroup;
      if (name === 'referring_provider') {
        providerGroup = referringProviderList.find(ele => ele.id == value);
      }

      if (providerGroup && providerGroup.referral_provider_group) {
        setPatientInfoVariables({
          ...patientInfoVariables,
          [name]: value,
          "referring_provider_group": providerGroup.referral_provider_group
        });
      }
      else {
        setPatientInfoVariables({
          ...patientInfoVariables,
          [name]: value,
        });
      }
      
    }

    setValueChanges(true);
  }

  useEffect(() => {
    getRelationShips(DEFAULT_PAGING_SIZE, activePage);
    setPracticePK(getStorage("practice"));
    getLanguages(DEFAULT_PAGING_SIZE, activePage);
    getServiceLocations();
    getReferringProviders(DEFAULT_PAGING_SIZE, activePage);
    getReferringProviderGroups(DEFAULT_PAGING_SIZE, activePage);
  }, []);



  // useEffect(() => {
  //   setEmergencySelf(true);
  //   setGuarantorSelf(true);
  // }, [props.patientInfoPK]);

  function getLanguages(pageSize) {
    const pageNum = 0;
    const result = service.ListLanguages(
      pageSize,
      pageNum,
      practicePK,
      props.patientPK
    );
    result.then((response) => {
      setLanguageList(response.data);
    });
  }


  function getServiceLocations() {
    const result = claimService.ServiceLocationLists(practicePK, patientPK);
    result.then((response) => {
      let locations = [];
      response.data.map((item) => {
        item.id = item.Id + "||" + item.location_type;
        locations.push(item);
        if (
          item.default_location === true &&
          (serviceLocation === "" || serviceLocation === undefined)
        ) {
          setServiceLocation(item.Id + "||" + item.location_type);
          let tmpItem = { id: item.Id, location_type: item.location_type };
          patientInfoVariables["service_location_value"] = tmpItem;
        }
      });
      setServiceLocationList(locations);
    });
  }

  function getReferringProviders(pageSize) {
    const pageNum = 0;
    const result = service.ListReferringProviders(
      pageSize,
      pageNum,
      practicePK,
      patientPK
    );
    result.then((response) => {
      setReferringProviderList(response.data);
    });
  }

  function getReferringProviderGroups(pageSize) {
    const pageNum = 0;
    const result = service.ListReferringProviderGroups(
      pageSize,
      pageNum,
      practicePK,
      patientPK
    );
    result.then((response) => {
      setReferringProviderGroupList(response.data);
    });
  }

  function getRelationShips(pageSize) {
    const pageNum = 0;
    const result = service.ListRelationShips(pageSize, pageNum, patientPK, "info");
    result.then((response) => {
      setRelationShipList(response.data);
      let obj = response.data.find(
        (item) => item.name.toLowerCase() === DEFAULTRELATION.toLowerCase()
      );
      setDefaultId(obj.id);
      patientInfoVariables["guarantor_relationship"] = obj.id;
      patientInfoVariables["emergency_relationship"] = obj.id;
      setEmergencyRelationShip(obj.id);
    });
  }

  useEffect(() => {
    if (props.saveSubmitted) {
      onSaveFormData();
    }
  }, [props.saveSubmitted]);

  function onSaveFormData() {
    props.setSaveSubmitted(false);
  }

  useEffect(() => {
    let {
      guarantor_relationship,
      emergency_relationship,
      authorized_contact,
      location_data,
      cell_phone,
      work_phone,
      home_phone,
      service_location,
      guarantor_work_phone,
      guarantor_cell_phone,
      emergency_location_data,
      emergency_home_phone,
      emergency_work_phone,
      emergency_cell_phone,
      email,
      ethnicity,
      race,
      preferred_language,
      rendering_provider,
      referring_provider,
      referring_provider_group,
      guarantor_last_name,
      guarantor_first_name,
      guarantor_middle_name,
      guarantor_home_phone,
      guarantor_email,
      emergency_last_name,
      emergency_first_name,
      emergency_middle_name,
      emergency_suffix,
      emergency_email,
      emergency_as_guarantor,
      guarantor_location_data,
      guarantor_address_as_patient,
      guarantor_suffix,
    } = patientInfoVariables;
    let data = {
      guarantor_relationship_id: guarantor_relationship,
      emergency_relationship_id: emergency_relationship,
      authorized_contact,
      location: location_data,
      guarantor_location: guarantor_location_data,
      emergency_location: emergency_location_data,
      cell_phone,
      work_phone,
      home_phone,
      service_location,
      guarantor_work_phone,
      guarantor_cell_phone,
      emergency_home_phone,
      emergency_work_phone,
      emergency_cell_phone,
      email,
      ethnicity,
      race,
      preferred_language_id: preferred_language,
      rendering_provider_id: rendering_provider,
      referring_provider_id: referring_provider,
      referring_provider_group_id: referring_provider_group,
      guarantor_last_name,
      guarantor_first_name,
      guarantor_middle_name,
      guarantor_home_phone,
      guarantor_email,
      emergency_last_name,
      emergency_first_name,
      emergency_middle_name,
      emergency_suffix,
      emergency_email,
      emergency_as_guarantor,
      guarantor_address_as_patient,
      guarantor_suffix,
    };
    props.setPatientInfoApiData(data);
  }, [patientInfoVariables]);

  const setContactDetails = (e, key) => {
    if (key === "homephone") {
      setHomePhone(e);
      setValueChanges(true);
    }
    if (key === "workphone") {
      setWorkPhone(e);
      setValueChanges(true);
    }

    if (key === "cellphone") {
      setCellPhone(e);
      setValueChanges(true);
    }
  };
  return (
    <div>
      <Form autoComplete="off">
        <Form.Group>
          <div 
           className="pr-pl-10"
           >
          <div className="row padding-top20">
            <div className="col-3 " >
              <div className="form-group padding-top15">
                <TextInput
                  type="text"
                  id="location_data_street1"
                  name="street1"
                  required={true}
                  value={patientInfoVariables.location_data.street1}
                  onValueChange={onHandlePatientInfoLocationDataChange}
                  label={i18n.t("patientPages.patientInfo.labelStreet1")}
                />
              </div>
            </div>
            <div className="col-3 ">
              <div className="form-group padding-top15">
                <TextInput
                  type="text"
                  id="location_data_street2"
                  name="street2"
                  value={patientInfoVariables.location_data.street2}
                  onValueChange={onHandlePatientInfoLocationDataChange}
                  label={i18n.t("patientPages.patientInfo.labelStreet2")}
                  helperText={streetValidity.street ? "Street 1 should not be empty." : ""}
                />
              </div>
            </div>
            <div className="col-3 ">
              <div className="form-group padding-top15">
                <CityInput
                  type="text"
                  id="location_data_city"
                  name="city"
                  required={true}
                  onValueChange={onHandlePatientInfoLocationDataChange}
                  label={i18n.t("patientPages.patientInfo.labelCity")}
                  value={patientInfoVariables.location_data.city}
                  zip={patientInfoVariables.location_data.zip_code}
                  setValue={setPatientInfoVariables}
                />
              </div>
            </div>
            <div className="col-3 ">
              <div className="form-group padding-top15">
                <SelectInput
                  listItem={"US_StateList"}
                  required={true}
                  id="state"
                  data={US_STATES}
                  name="state"
                  value={patientInfoVariables.location_data.state}
                  onValueChange={onHandlePatientInfoLocationDataChange}
                  label={i18n.t("patientPages.patientInfo.labelState")}
                />
              </div>
            </div>
          </div>

          <div className="row" >
            <div className="col-3 ">
              <div className="form-group padding-top15">
                <TextInput
                  type="text"
                  id="location_data_country"
                  name="country"
                  onValueChange={onHandlePatientInfoLocationDataChange}
                  label={i18n.t("patientPages.patientInfo.labelCountry")}
                  value={patientInfoVariables.location_data.country}
                />
              </div>
            </div>
            <div className="col-3 ">
              <div className="form-group padding-top15">
                <GeoCodeSearch
                  id="location_data_zip"
                  name="zip_code"
                  required={true}
                  label={i18n.t("patientPages.patientInfo.labelZip")}
                  setlocState={setState}
                  setLocCountry={setCountry}
                  setLocZipCode={setZIP}
                  setLocCity={setCity}
                  funcInvoke={true}
                  setValueChanged={setValueChanges}
                  defaultValue={patientInfoVariables.location_data.zip_code}
                  checkFormWarning={checkFormWarning}
                  onValueChange={onHandlePatientInfoLocationDataChange}
                />
              </div>
            </div>
          </div>

          <div className="row" >
            <div className="col-3 ">
              <div className="form-group padding-top15">
                <EmailInput
                  id="location_data_email"
                  name="email"
                  label={i18n.t("patientPages.patientInfo.labelEmail")}
                  value={patientInfoVariables.email}
                  onValueChange={onHandlePatientInfoChange}
                  className={props.patientInfoValidEmail ? "" : "input-error"}
                />
              </div>
            </div>
            <div className="col-3 mt-2" >
              <div className="form-group padding-top15">
                <PhoneInputWithCode
                  name="location_data_home_phone"
                  value={patientInfoVariables.home_phone}
                  label={i18n.t("patientPages.patientInfo.labelHomePhone")}
                  onValueChange={(e) => setContactDetails(e, "homephone")}
                  className={
                    patientInfoVariables.home_phone > 4 ? "" : "input-error"
                  }
                />
              </div>
            </div>
            <div className="col-3 mt-2">
              <div className="form-group padding-top15">
                <PhoneInputWithCode
                  name="location_data_work_phone"
                  label={i18n.t("patientPages.patientInfo.lableWorkPhone")}
                  onValueChange={(e) => setContactDetails(e, "workphone")}
                  value={patientInfoVariables.work_phone}
                />
              </div>
            </div>
            <div className="col-3 mt-2">
              <div className="form-group padding-top15">
                <PhoneInputWithCode
                  name="location_data_cell_phone"
                  label={i18n.t("patientPages.patientInfo.labelCellPhone")}
                  onValueChange={(e) => setContactDetails(e, "cellphone")}
                  value={patientInfoVariables.cell_phone}
                />
              </div>
            </div>
          </div>
          
          <div className="row ">
            <div className="div-inline">
              <label className="margin-top-3px ml-3">
                {i18n.t("patientPages.patientInfo.labelPersonalInfo")}
              </label>
            </div>
          </div>
          <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
          
          <div className="row pb-2"  >
            <div className="col-3">
              <SelectInput
                data={ethnicityList}
                defaultValue={true}
                name="ethnicity"
                id="ethnicity"
                value={patientInfoVariables.ethnicity}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t("patientPages.patientInfo.labelEthnicity")}
                selectOptionRemove={true}
              />
            </div>
            <div className="col-3 ">
              <SelectInput
                data={raceList}
                defaultValue={true}
                name="race"
                id="race"
                value={patientInfoVariables.race}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t("patientPages.patientInfo.labelRace")}
                selectOptionRemove={true}
              />
            </div>
            <div className="col-3 ">
              <SelectInput
                data={languageList}
                defaultValue={true}
                name="preferred_language"
                id="preferred_language"
                value={patientInfoVariables.preferred_language}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t("patientPages.patientInfo.labelLanguage")}
              />
            </div>
          </div>

          <div className="row ">
            <div className="div-inline padding-top5">
              <label className="margin-top-3px ml-3">
                {i18n.t("patientPages.patientInfo.labelProviderInfo")}
              </label>
            </div>
          </div>
          <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />

          <div className="row " >
            <div className="col-3">
              <SelectInput
                required={true}
                data={renderingProviderList}
                name="rendering_provider"
                id="rendering_provider_patient_info"
                value={patientInfoVariables.rendering_provider}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t(
                  "patientPages.patientInfo.labelRenderingProvider"
                )}
              />
            </div>
            <div className="col-3 ">
              <SelectInput
                data={serviceLocationList}
                name="service_location_value"
                id="service_location_value"
                value={patientInfoVariables.service_location_value}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t("patientPages.patientInfo.labelServiceLocation")}
                selectOptionRemove={true}
              />
            </div>
            <div className="col-3 ">
              <SelectInput
                data={referringProviderList}
                name="referring_provider"
                id="referring_provider"
                value={patientInfoVariables.referring_provider}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t(
                  "patientPages.patientInfo.labelReferringProvider"
                )}
              />
              </div>
              {referringProviderGroupList.length > 0 &&
                <div className="col-3 ">
                  <SelectInput
                    data={referringProviderGroupList}
                    name="referring_provider_group"
                    id="referring_provider_group"
                    value={patientInfoVariables.referring_provider_group}
                    onValueChange={onHandlePatientInfoChange}
                    label={i18n.t(
                      "patientPages.patientInfo.labelReferringProviderGroup"
                    )}
                  />
                </div>
            }
          </div>
          
          <div className="row">
            <div className="div-inline">
              <label className="margin-top-3px ml-3">
                {i18n.t("patientPages.patientInfo.labelGuarantorInfo")}
              </label>
            </div>
          </div>
          <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />

          <div className="row " >
            <div className="col-3">
              <SelectInput
                data={relationShipList}
                defaultValue={true}
                name="guarantor_relationship"
                id="guarantor_relationship"
                value={patientInfoVariables.guarantor_relationship}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t("patientPages.patientInfo.labelRelationShip")}
                selectOptionRemove={true}
              />
            </div>
          </div>
         </div> 
          {patientInfoVariables.guarantor_relationship !== 1 && !guaratorSelf && (
            <React.Fragment>
              <div className="pr-pl-10">
              <div className="row padding-top15"
              //  style={{marginLeft:"-25px"}}
               >
                <div className="col-3 ">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guarantor_last_name"
                      name="guarantor_last_name"
                      label={i18n.t("patientPages.patientInfo.labelLastName")}
                      onValueChange={onHandlePatientInfoChange}
                      value={patientInfoVariables.guarantor_last_name}
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guarantor_first_name"
                      name="guarantor_first_name"
                      value={patientInfoVariables.guarantor_first_name}
                      onValueChange={onHandlePatientInfoChange}
                      label={i18n.t("patientPages.patientInfo.labelFirstName")}
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guarantor_middle_name"
                      name="guarantor_middle_name"
                      value={patientInfoVariables.guarantor_middle_name}
                      onValueChange={onHandlePatientInfoChange}
                      label={i18n.t(
                        "patientPages.patientInfo.labelMiddleInitial"
                      )}
                    />
                  </div>
                </div>

                <div className="col-3 ">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guarantor_suffix"
                      name="guarantor_suffix"
                      value={patientInfoVariables.guarantor_suffix}
                      onValueChange={onHandlePatientInfoChange}
                      label={i18n.t("patientPages.patientInfo.labelSuffix")}
                    />
                  </div>
                </div>
              </div>
              <div className="row " style={{marginLeft:"0px"}}>
                <div className="max-width-3">
                  <div className="form-group">
                    <div className="custom-checkbox lh-0">
                      <input
                        type="checkbox"
                        id={"guarantor_address_as_patient"}
                        checked={
                          patientInfoVariables.guarantor_address_as_patient
                        }
                        name="guarantor_address_as_patient"
                        onChange={onHandlePatientInfoChange}
                      />
                      <label
                        className="checkbox top-14"
                        htmlFor={"guarantor_address_as_patient"}
                      ></label>
                    </div>
                  </div>
                </div>
                <div className="padding-top5 padding-left20 ml-2">
                  <label className="padding-top5 margin-top-3px">
                    {i18n.t("patientPages.patientInfo.labelSameAsPatient")}
                  </label>
                </div>
              </div>

              <div className="row  padding-top15" >
                <div className="col-3">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guaratorStreet1"
                      name="street1"
                      value={
                        patientInfoVariables.guarantor_location_data.street1
                      }
                      disabled={disableGuarantorAddressFields}
                      onValueChange={onHandleGuarantorLocationData}
                      label={i18n.t("patientPages.patientInfo.labelStreet1")}
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guaratorStreet2"
                      name="street2"
                      value={
                        patientInfoVariables.guarantor_location_data.street2
                      }
                      disabled={disableGuarantorAddressFields}
                      onValueChange={onHandleGuarantorLocationData}
                      label={i18n.t("patientPages.patientInfo.labelStreet2")}
                      helperText={streetValidity.guarantorStreet ? "Street 1 should not be empty." : ""}
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="form-group ">
                    <CityInput
                      type="text"
                      id="guarantor_location_data_city"
                      name="city"
                      value={patientInfoVariables.guarantor_location_data.city}
                      disabled={disableGuarantorAddressFields}
                      onValueChange={onHandleGuarantorLocationData}
                      label={i18n.t("patientPages.patientInfo.labelCity")}
                      setValue={setPatientInfoVariables}
                      zip={
                        patientInfoVariables.guarantor_location_data.zip_code
                      }
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="form-group ">
                    <TextInput
                      type="text"
                      id="guaratorState"
                      name="state"
                      value={patientInfoVariables.guarantor_location_data.state}
                      disabled={disableGuarantorAddressFields}
                      onValueChange={onHandleGuarantorLocationData}
                      label={i18n.t("patientPages.patientInfo.labelState")}
                    />
                  </div>
                </div>
              
              </div>
              <div className="row  padding-top15" 
              // style={{marginLeft:"-25px"}}
              >
              <div className="col-3 ">
                  <div className="form-group">
                    <TextInput
                      type="text"
                      id="guaratorCountry"
                      name="country"
                      value={
                        patientInfoVariables.guarantor_location_data.country
                      }
                      disabled={disableGuarantorAddressFields}
                      onValueChange={onHandleGuarantorLocationData}
                      label={i18n.t("patientPages.patientInfo.labelCountry")}
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div className="form-group">
                    <GeoCodeSearch
                      id="guaratorZip"
                      name="guaratorZip"
                      label={i18n.t("patientPages.patientInfo.labelZip")}
                      setlocState={setGuaratorState}
                      setLocCountry={setGuaratorCountry}
                      disabled={disableGuarantorAddressFields}
                      setLocCity={setGuaratorCity}
                      setLocZipCode={setGuaratorZIP}
                      defaultValue={
                        patientInfoVariables.guarantor_location_data.zip_code
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <div className="form-group padding-top15">
                    <EmailInput
                      id="guarantor_email"
                      name="guarantor_email"
                      value={patientInfoVariables.guarantor_email}
                      onValueChange={onHandlePatientInfoChange}
                      label={i18n.t("patientPages.patientInfo.labelEmail")}
                      className={
                        props.patientInfoValidGuarantorEmail
                          ? ""
                          : "input-error"
                      }
                    />
                  </div>
                </div>
                <div className="col-3 mt-2">
                  <div className="form-group padding-top15">
                    <PhoneInputWithCode
                      name="guarantor_home_phone"
                      label={i18n.t("patientPages.patientInfo.labelHomePhone")}
                      onValueChange={(e) => {
                        setGuaratorHomePhone(e);
                        setValueChanges(true);
                      }}
                      value={patientInfoVariables.guarantor_home_phone}
                    />
                  </div>
                </div>
                <div className="col-3 mt-2">
                  <div className="form-group padding-top15">
                    <PhoneInputWithCode
                      name="guarantor_work_phone"
                      label={i18n.t("patientPages.patientInfo.lableWorkPhone")}
                      onValueChange={(e) => {
                        setGuaratorWorkPhone(e);
                        setValueChanges(true);
                      }}
                      value={patientInfoVariables.guarantor_work_phone}
                    />
                  </div>
                </div>
                <div className="col-3 mt-2">
                  <div className="form-group padding-top15">
                    <PhoneInputWithCode
                      name="guarantor_cell_phone"
                      value={patientInfoVariables.guarantor_cell_phone}
                      onValueChange={(e) => {
                        setGuaratorCellPhone(e);
                        setValueChanges(true);
                      }}
                      label={i18n.t("patientPages.patientInfo.labelCellPhone")}
                    />
                  </div>
                </div>
                
              </div>
            </div>
            </React.Fragment>
          )}
          <div  className="pr-pl-10">
          <div className="row">
            <div className="col-3 div-inline padding-top10" >
              <label className="margin-top-3px">
                {i18n.t("patientPages.patientInfo.labelEmergencyContact")}
              </label>
            </div>
            <div className="col-9 max-width-3" >
              <div className="form-group">
                <div className="custom-checkbox lh-0">
                  <input
                    type="checkbox"
                    id={"emergency_as_guarantor" + props.patientPK}
                    checked={patientInfoVariables.emergency_as_guarantor}
                    name="emergency_as_guarantor"
                    onChange={onHandlePatientInfoChange}
                  />
                  <label
                    className="checkbox top-14 mt-1"
                    htmlFor={"emergency_as_guarantor" + props.patientPK}
                  ></label>
                </div>
              </div>
            </div>
            <div className=" padding-top5 padding-left20" > 
              <label className="padding-top5 margin-top-3px">
                {i18n.t("patientPages.patientInfo.labelGuarantor")}
              </label>
            </div>
          </div>
          <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />

          <div className="row padding-top15"
           >
            <div className="col-3">
              <SelectInput
                data={relationShipList}
                name="emergency_relationship"
                id="emergency_relationship"
                value={patientInfoVariables.emergency_relationship}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t("patientPages.patientInfo.labelRelationShip")}
                selectOptionRemove={true}
              />
            </div>
            <div className="col-3 ">
              <SelectInput
                data={YesOrNo}
                name="authorized_contact"
                id="authorized_contact"
                value={patientInfoVariables.authorized_contact}
                onValueChange={onHandlePatientInfoChange}
                label={i18n.t(
                  "patientPages.patientInfo.labelAuthorizedContact"
                )}
                selectOptionRemove={true}
              />
            </div>
          </div>
          </div>
          
          {emergencyRelationShip &&
            !emergencySelf &&
            patientInfoVariables.emergency_relationship !== 1 && (
            <React.Fragment>
              <div className="pr-pl-10">
                <div className="row padding-top15">
                  <div className="col-3">
                    <div className="form-group">
                      <TextInput
                        type="text"
                        id="emergency_last_name"
                        name="emergency_last_name"
                        value={patientInfoVariables.emergency_last_name}
                        onValueChange={onHandlePatientInfoChange}
                        label={i18n.t("patientPages.patientInfo.labelLastName")}
                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group">
                      <TextInput
                        type="emergency_first_name"
                        id="emergency_first_name"
                        name="emergency_first_name"
                        value={patientInfoVariables.emergency_first_name}
                        onValueChange={onHandlePatientInfoChange}
                        label={i18n.t(
                          "patientPages.patientInfo.labelFirstName"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group">
                      <TextInput
                        type="text"
                        id="emergency_middle_name"
                        name="emergency_middle_name"
                        value={patientInfoVariables.emergency_middle_name}
                        onValueChange={onHandlePatientInfoChange}
                        label={i18n.t(
                          "patientPages.patientInfo.labelMiddleInitial"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group">
                      <TextInput
                        type="text"
                        id="emergency_suffix"
                        name="emergency_suffix"
                        value={patientInfoVariables.emergency_suffix}
                        onValueChange={onHandlePatientInfoChange}
                        label={i18n.t("patientPages.patientInfo.labelSuffix")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row padding-top15">
                  <div className="col-3">
                    <div className="form-group ">
                      <TextInput
                        type="text"
                        id="emergency-street1"
                        name="street1"
                        value={
                          patientInfoVariables.emergency_location_data.street1
                        }
                        onValueChange={onHandleEmergencyLocationData}
                        label={i18n.t("patientPages.patientInfo.labelStreet1")}
                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group ">
                      <TextInput
                        type="text"
                        id="emergency-street2"
                        name="street2"
                        value={
                          patientInfoVariables.emergency_location_data.street2
                        }
                        onValueChange={onHandleEmergencyLocationData}
                        label={i18n.t("patientPages.patientInfo.labelStreet2")}
                        helperText={streetValidity.emergencyStreet ? "Street 1 should not be empty." : ""}

                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group ">
                      <CityInput
                        type="text"
                        id="emergency_location_data_city"
                        name="city"
                        value={
                          patientInfoVariables.emergency_location_data.city
                        }
                        onValueChange={onHandleEmergencyLocationData}
                        label={i18n.t("patientPages.patientInfo.labelCity")}
                        zip={
                          patientInfoVariables.emergency_location_data.zip_code
                        }
                        setValue={setPatientInfoVariables}
                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group ">
                      <TextInput
                        type="text"
                        id="emergency-state"
                        name="state"
                        value={
                          patientInfoVariables.emergency_location_data.state
                        }
                        onValueChange={onHandleEmergencyLocationData}
                        label={i18n.t("patientPages.patientInfo.labelState")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row padding-top15">
                  <div className="col-3 ">
                    <div className="form-group ">
                      <GeoCodeSearch
                        id="emergencyZip"
                        name="emergencyZip"
                        label={i18n.t("patientPages.patientInfo.labelZip")}
                        setlocState={setEmergencyState}
                        setLocCountry={setEmergencyCountry}
                        setLocZipCode={setEmergencyZIP}
                        setLocCity={setEmergencyCity}
                        defaultValue={
                          patientInfoVariables.emergency_location_data.zip_code
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3 ">
                    <div className="form-group ">
                      <TextInput
                        type="text"
                        id="emergency-country"
                        name="country"
                        value={
                          patientInfoVariables.emergency_location_data.country
                        }
                        onValueChange={onHandleEmergencyLocationData}
                        label={i18n.t("patientPages.patientInfo.labelCountry")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <div className="form-group padding-top15">
                      <EmailInput
                        id="emergency_email"
                        name="emergency_email"
                        value={patientInfoVariables.emergency_email}
                        onValueChange={onHandlePatientInfoChange}
                        label={i18n.t("patientPages.patientInfo.labelEmail")}
                        className={
                          props.patientInfoValidEmergencyEmail
                            ? ""
                            : "input-error"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-3 mt-2">
                    <div className="form-group padding-top15">
                      <PhoneInputWithCode
                        name="emergency_home_phone"
                        value={patientInfoVariables.emergency_home_phone}
                        onValueChange={setEmergencyHomePhone}
                        label={i18n.t(
                          "patientPages.patientInfo.labelHomePhone"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-3 mt-2">
                    <div className="form-group padding-top15">
                      <PhoneInputWithCode
                        name="emergency_work_phone"
                        value={patientInfoVariables.emergency_work_phone}
                        onValueChange={setEmergencyWorkPhone}
                        label={i18n.t(
                          "patientPages.patientInfo.lableWorkPhone"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-3 mt-2">
                    <div className="form-group padding-top15">
                      <PhoneInputWithCode
                        name="emergency_cell_phone"
                        value={patientInfoVariables.emergency_cell_phone}
                        onValueChange={setEmergencyCellPhone}
                        label={i18n.t(
                          "patientPages.patientInfo.labelCellPhone"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
            )}
        </Form.Group>
      </Form>
    </div>
  );
};
export default PatientInfo;
