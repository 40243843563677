import { createSlice } from '@reduxjs/toolkit';
import { isValidFilterConfig } from '../../../utilities/commonUtilities';
import i18n from '../../../utilities/i18n';

let initialState = {
    tableData: [],
    loading: false,
    pageSize: 0,
    count: 0,
    totalPage: 0,
    sortField: null, // Current SortField
    orderType: null, // Current Sort Order Asc or Dsc
    filterConfig: [
        {
            fieldName: 'dateOfServiceFrom',
            label: i18n.t("reports.filters.dateOfServiceFrom"),
            type: 'dateField',
            columnWidth: 'col-3' // optional, report filter component will take col-3 as default
        },
        {
            fieldName: 'dateOfServiceTo',
            label: i18n.t("reports.filters.dateOfServiceTo"),
            type: 'dateField',
            minDateField: "dateOfServiceFrom"
        },
        {
            fieldName: 'transaction_from',
            label: i18n.t("reports.filters.transactionFrom"),
            type: 'dateField',
        },
        {
            fieldName: 'transaction_to',
            label: i18n.t("reports.filters.transactionTo"),
            type: 'dateField',
            minDateField: "transaction_from"
        },
        {
            fieldName: 'firstBilledDateFrom',
            label: i18n.t("reports.filters.firstBilledDateFrom"),
            type: 'dateField',
        },
        {
            fieldName: 'firstBilledDateTo',
            label: i18n.t("reports.filters.firstBilledDateTo"),
            type: 'dateField',
            minDateField: "firstBilledDateFrom"
        },
        {
            fieldName: 'lastBilledDateFrom',
            label: i18n.t("reports.filters.lastBilledDateFrom"),
            type: 'dateField',
        },
        {
            fieldName: 'lastBilledDateTo',
            label: i18n.t("reports.filters.lastBilledDateTo"),
            type: 'dateField',
            minDateField: "lastBilledDateFrom"
        },
        {
            fieldName: 'patientIds',
            label: i18n.t("reports.filters.patientId"),
            type: 'multiSearchSelect',
            dropDownOptionName: 'patientIdList',
        },
        {
            fieldName: 'payerType',
            label: i18n.t("reports.filters.payerType"),
            type: 'multiSelect',
            dropDownOptionName: 'payerTypes'
        },
        {
            fieldName: 'insurance_companies',
            label: i18n.t("reports.filters.insuranceCompanies"),
            type: 'multiSelect',
            dropDownOptionName: 'insuranceCompanyList'
        },
        {
            fieldName: 'claimStatus',
            label: i18n.t("reports.filters.claimStatus"),
            type: 'multiSelect',
            dropDownOptionName: 'claimStatusList'
        },
        {
            fieldName: 'claimSubStatus',
            label: i18n.t("reports.filters.claimSubStatus"),
            type: 'multiSelect',
            dropDownOptionName: 'claimSubStatusList'
        },
        {
            fieldName: 'renderingProvider',
            label: i18n.t("reports.filters.renderingProvider"),
            type: 'multiSelect',
            dropDownOptionName: 'renderingProviderList'
        },
        {
            fieldName: 'refferingProvider',
            label: i18n.t("reports.filters.refferingProvider"),
            type: 'multiSelect',
            dropDownOptionName: 'referringProviderList'
        },
        {
            fieldName: 'billingProvider',
            label: i18n.t("reports.filters.billingProvider"),
            type: 'multiSelect',
            dropDownOptionName: 'renderingProviderList'
        },
        {
            fieldName: 'serviceLocation',
            label: i18n.t("reports.filters.serviceLocation"),
            type: 'multiSelect',
            dropDownOptionName: 'serviceLocationList'
        },
        {
            fieldName: 'cptList',
            label: i18n.t("reports.filters.cptList"),
            type: 'multiSelect',
            dropDownOptionName: 'cptList'
        },
        {
            fieldName: 'ICD',
            label: i18n.t("reports.filters.ICD"),
            type: 'multiSelect',
            dropDownOptionName: 'icdList'
        },
        {
            fieldName: 'transaction_entered_by_user',
            label: i18n.t("reports.filters.transactionEnteredUser"),
            type: 'multiSelect',
            dropDownOptionName: 'praticeUserList'
        },
        {
            fieldName: 'transaction_type',
            label: i18n.t("reports.filters.transaction_type"),
            type: 'multiSelect',
            dropDownOptionName: 'transactionTypes'
        },
        {
            fieldName: 'aging',
            label: i18n.t("reports.filters.aging"),
            type: 'select',
            dropDownOptionName: 'agingList'
        },
        {
            fieldName: 'balanceAmount',
            label: i18n.t("reports.filters.balanceAmountTotals"),
            type: 'amountFilter',
            fieldPrefix: 'balanceAmountTotals',
        }

    ].map(item => {
        if (!isValidFilterConfig(item)) {
            throw new Error(`Invalid filter config for ${item.fieldName}`);
        }
        return item;
    }),
    filters: { // Filter input variables
        dateOfServiceFrom: "",
        dateOfServiceTo: "",
        dateOfPaymentEntry: "",
        dateOfEntryFrom: "",
        dateOfEntryTo: "",
        firstBilledDateFrom: "",
        firstBilledDateTo: "",
        lastBilledDateFrom: "",
        lastBilledDateTo: "",
        payerType: [],
        insurance_companies: [],
        claimStatus: [],
        claimSubStatus: [],
        renderingProvider: [],
        refferingProvider: [],
        billingProvider: [],
        serviceLocation: [],
        cptList: [],
        ICD: [],
        chargeEnteredUser: [],
        paymentEnteredUser: [],
        balanceAmountTotalsType: "",
        balanceAmountTotalsFrom: "",
        balanceAmountTotalsTo: "",
        aging: "",
        patientIds: [],
        transaction_type: [],
        transaction_from: "",
        transaction_to: "",
        transaction_entered_by_user: []
    },
    allSortFieldStates: {
        "custom_claim_id": true,
        "entered_date": true,
        "first_billed_date": true,
        "last_billed_date": true,
        "service_from_date": true,
        "service_to_date": true
    },
    isError: 'not-set'
}

export const TransactionReportSlice = createSlice({
    name: "transactionReport",
    initialState,
    reducers: {
        onResetData: () => initialState,
        updateTransactionReportData: (state, actions) => {
            if (actions.payload.type === "success") {
                state.count = actions.payload.data.data.count;
                state.pageSize = actions.payload.data.data.page_size;
                state.totalPage = Math.ceil(actions.payload.data.data.count / actions.payload.data.data.page_size)
                state.tableData = actions.payload.data.data.results;
            }
            if (actions.payload.type === "error") {
                state.isError = true
            }
        },
        updateSortField: (state, actions) => {
            // toggle all the sortfields to true except for the currently sorting field
            const newSortState = Object.keys(state.allSortFieldStates).reduce((acc, key) => {
                acc[key] = key === actions.payload.sortField ? false : true;
                return acc;
            }, {});
            return {
                ...state,
                sortField: actions.payload.sortField,
                orderType: actions.payload.sortOrder,
                allSortFieldStates: newSortState
            };
        },
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
    }
});

export const { onResetData, updateTransactionReportData, updateSortField, updateFieldValues } = TransactionReportSlice.actions

export default TransactionReportSlice.reducer;