import i18n from "../../../utilities/i18n";

const commonWidths = {
    paymentId: 'small',
    reference: 'medium',
    date: 'medium',
    amount: 'medium',
    type: 'large',
    insurance: 'medium',
    payment_mode: 'small'
};


const tableData = [
    {
        "head": {
            name: i18n.t("reports.payment_id"),
            type: "string",
            width: commonWidths.paymentId,
            sort: true,
            initialOrdering: true,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "custom_payment_id",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.paymentId,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.payment_reference_number"),
            type: "string",
            width: commonWidths.reference,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "reference_check_number",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.reference,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.era_document_name"),
            type: "string",
            width: commonWidths.reference,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "file_name",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.reference,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.filters.paymentCheckDate"),
            type: "string",
            width: commonWidths.date,
            sort: true,
            initialOrdering: true,
            hideValue: false,
    },
        "body": {
            id: "",
            name: "payment_check_date",
            value: "",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.date,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.payment_entered_date"),
            type: "string",
            width: commonWidths.date,
            sort: true,
            initialOrdering: true,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "payment_entered_date",
            value: "",
            type: "string",
            needDateFormat: "yes",
            align: "center",
            width: commonWidths.date,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.payment_level_adjustment"),
            type: "number",
            width: commonWidths.amount,
            sort: true,
            initialOrdering: true,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "level_adjustments",
            value: "",
            type: "currency",
            align: "center",
            width: commonWidths.amount,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.adjustment_type"),
            type: "string",
            width: commonWidths.type,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "adjustment_type_description",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.type,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.payt_from_insurance"),
            type: "string",
            width: commonWidths.insurance,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "payer_name",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.insurance,
            hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.payment_amount"),
            type: "number",
            width: commonWidths.amount,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "payment_amount",
            value: "",
            type: "currency",
            align: "center",
            width: commonWidths.amount,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.payment_mode"),
            type: "string",
            width: commonWidths.payment_mode,
            sort: false,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "payment_mode",
            value: "",
            type: "string",
            align: "center",
            width: commonWidths.payment_mode,
            hideValue: false,
        },
    },
    {
        "head": {
            name: i18n.t("reports.applied_amount"),
            type: "number",
            width: commonWidths.amount,
            sort: true,
            initialOrdering: true,
            hideValue: false,
        },
        "body": {
            id: "",
            name: "applied_amount",
            value: "",
            type: "currency",
            align: "center",
            width: commonWidths.amount,
        hideValue: false,
        }
    },
    {
        "head": {
            name: i18n.t("reports.unApplied_Amount"),
            type: "number",
            width: commonWidths.amount,
            sort: false,
            hideValue: false,
        },
        "body": {
        id: "",
            name: "unapplied_amount",
        value: "",
            type: "currency",
        align: "center",
            width: commonWidths.amount,
        hideValue: false,
        }
    },
]

export const ReportTableData = {
    name: "dynamicTable",
    isResize: false,
    isFixedHead: true,
    tableHeadings: tableData.map(item => item.head),
    tableBodyData: [tableData.map(item => item.body)],
};