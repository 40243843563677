
export const initialState = {
    claimStatus: [],
    claimSubStatus: [],
    responsibilitySubStatus:[],
    renderingProvider: [],
    referringProvider: [],
    serviceLocations: [],
    claimFrequencyList: [],
    relationList: [],
    ICDLIST: [],
    CPTLIST: [],
    posCodeList: [],
    tosCodeList: [],
    modifierList1: [],
    modifierList2: [],
    modifierList3: [],
    modifierList4: [],
    accidentTypeList: [],
    stateList: [],
    delayReasonCodeList: [],
    transportTypeList:[],
    otherAccidentNoId: "",
    AccidentNoId:"",
    isSelectInputDataLoaded: false,
    isErrorLoadingData: false,
}