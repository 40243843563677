// React and Hooks
import React, { useMemo, useState, useCallback } from 'react';

// Style Hook
import styled from '@emotion/styled';

// MUI Components
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Tooltip } from '@mui/material';

// Custom Components
import CommonDashboardTab from './CommonDashboardTab';
import Notify from '../commons/notify';


// Custom style for the indicator
const CustomIndicator = styled('span')(() => ({
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;



const DashboardComponent = () => {
    // Tab Configurations
    const DASHBOARD_CONFIG = useMemo(() => [
        {
            id: 'KPI',
            label: 'KPI',
            value: 'kpi',
            labelHelperText: 'Key Performance Indicators',
            component: props => <CommonDashboardTab {...props} tabId='kpi' />
        },
        {
            id: 'AR',
            label: 'AR',
            value: 'ar',
            labelHelperText: 'AR Insights',
            component: props => <CommonDashboardTab {...props} tabId='ar' />
        },
        {
            id: 'TAT',
            label: 'TAT',
            value: 'tat',
            labelHelperText: 'Turn Around Times',
            component: props => <CommonDashboardTab {...props} tabId='tat' />
        }
    ], []);

    // State Management
    const [currentDashTab, setCurrentDashTab] = useState("kpi");
    const [notifyState, setNotifyState] = useState({
        show: "hide",
        description: "",
        type: "success"
    });

    // Notification Handler
    const showNotifyWindow = useCallback((action, type, desc, age = 3000) => {
        if (action === "show") {
            setNotifyState({
                show: action,
                description: desc,
                type: type
            });

            setTimeout(() => {
                setNotifyState(prev => ({ ...prev, show: "hide" }));
            }, age);
        }
    }, []);

    // Tab Change Handler
    const handleTabChange = useCallback((_, newTab) => {
        setCurrentDashTab(newTab);
    }, []);

    return (
        <React.Fragment>
            <Notify
                showNotify={notifyState.show}
                setShowNotify={(show) => setNotifyState(prev => ({ ...prev, show }))}
                notifyDescription={notifyState.description}
                setNotifyDescription={(description) => setNotifyState(prev => ({ ...prev, description }))}
                notifyType={notifyState.type}
                setNotifyType={(type) => setNotifyState(prev => ({ ...prev, type }))}
            />

            <div className="col-md-12">
                <div className="pb-5 basic-info-padding">
                    <div className="box-head padding-left5">
                        <TabContext value={currentDashTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <StyledTabList
                                    aria-label="Dashboard Tabs"
                                    TabIndicatorProps={{
                                        children: <CustomIndicator />,
                                    }}
                                    onChange={handleTabChange}
                                >
                                    {DASHBOARD_CONFIG.map((tab) => (
                                        <Tooltip
                                            key={tab.id}
                                            title={tab.labelHelperText || ''}
                                            placement="top"
                                            arrow
                                            enterDelay={200}
                                            leaveDelay={0}
                                        >
                                            <Tab
                                                label={tab.label}
                                                value={tab.value}
                                                className={`tabMainMenu ${currentDashTab === tab.value ? 'Mui-selected' : ''}`}
                                            />
                                        </Tooltip>
                                    ))}
                                </StyledTabList>
                            </Box>

                            {DASHBOARD_CONFIG.map((tab) => (
                                <TabPanel
                                    key={tab.id}
                                    value={tab.value}
                                    className="bordering"
                                >
                                    {tab.component({ showNotifyWindow })}
                                </TabPanel>
                            ))}
                        </TabContext>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DashboardComponent;
