import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import format from 'date-fns/format';
import '../style.css'
import TextInput from "../../commons/input/input";
import SelectInput from '../../commons/input/SelectInput';
import i18n from "../../../utilities/i18n";
import { checkPermission, CommonDateFormat, currencyFormat, formatDateUS, YMD_TO_MDY } from '../../../utilities/commonUtilities';

import { DEFAULT_DATE_FORMAT, ERASTATUS } from '../../../utilities/staticConfigs';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import RemitanceCodeMultiSelect from '../RemitanceCodeMultiSelect';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import AsyncTypeInput from '../../commons/input/AsyncTypeHead/AsyncTypeInput';
import service from '../service'
import { getStorage } from '../../../utilities/browserStorage';
import Notify from '../../commons/notify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import CalendarInput from '../../commons/input/CalendarInput';
import { ResizableTable } from '../../commons/resizabletable/ResizableTable';
import LoadingContext from '../../../container/loadingContext';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import { permission_key_values_payments } from '../../../utilities/permissions';
import Label from '../../commons/Label';
import CommonButton from '../../commons/Buttons';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import { Tooltip as MUITooltip } from '@mui/material';
import { get } from 'idb-keyval';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InputLabel from '@mui/material/InputLabel';
import InfoIcon from '@mui/icons-material/Info';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { DateTime } from "luxon";

const labelStyle = {
    color: "var(--Grey-G600, #757575)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: '5px'
};

const typographyStyle = {
    overflow: "hidden",
    color: "var(--Grey-G900, #212121)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    textOverflow: "ellipsis",
    fontFamily: "Lato",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal"
};

const fields = [
    { label: 'Claim ID', key: 'claim_custom_id' },
    { label: 'Patient ID', key: 'patient_id' },
    { label: 'DOS', key: 'date_of_service' },
    { label: 'Procedure', key: 'cpt_code' },
    { label: 'Charges', key: 'charges' },
    { label: 'Reciepts', key: 'receipts' },
    { label: 'Openning Balance', key: 'opening_balance' },
];


function ERAApply({
    eraOriginalData,
    reviewData,
    reviewClaim,
    payAdjTypeArr,
    selectedPaymentPK,
    onPatientNameClick,
    onApplyPayment,
    isApplied,
    backToReview,
    adjustment_1_codes_list,
    onSearchAdjustmentReason_1_code,
    adjustment_2_codes_list,
    onSearchAdjustmentReason_2_code,
    showDetailsModal,
    setShowDetailsModal,
    saveEditProcedures,
    procedureData,
    setProcedureData,
    setReviewData,
    setManuallyAddedClaimIDs,
    // manuallyAddedClaimIDs,
    selectedClaimID,
    claimReviewedList,
    setClaimReviewedList,
    editedProcedureData,
    setEditedProcedureData,
    responsibilityTypes,
    eraAppliedStatus,
    isAllProcedureApplied,
    lastOpenedClaimRowItem,
    responsibilityList,
    originalResponsibility,
    claimStatusList,
    claimSubStatusList,
    MarkAsReviewed,
    isReviewAll,
    setIsReviewAll,
    setSelectedClaimID,
    isNewClaim
}) {
    const filter_Options = [{ "id": "claim_id", "name": "Claim ID" }, { "id": "patient_name", "name": "Patient Name" }, { "id": "patient_id", "name": "Patient ID" }, { "id": "cpt_payment", "name": "Paid Amount" }, { "id": "dos", "name": "DOS" }]
    const [eraFilterOption, setERAFilterOption] = useState({ name: filter_Options[0].name, value: filter_Options[0].id })
    const [eraData, setERAData] = useState([])
    const [filterQuery, setFilterQuery] = useState('')
    const [maxResults, setMaxResults] = useState(5);
    const [showAddClaimModal, setShowAddClaimModal] = useState();
    const [claimsListForModalDropdown, setClaimsListForModalDropdown] = useState([]);
    const [isAsyncTypeSearching, setIsAsyncTypeSearching] = useState(false);
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [openMuiDialogue, setOpenMuiDialogue] = useState(false);
    const [claimDataForEraManualAdd, setClaimDataForEraManualAdd] = useState({
        custom_claim_id: '', patient_name: '', dos_from: '', dos_to: '', payer_types: '', responsible_payer: ''
    });
    const [manuallyAddedClaims, setManuallyAddedClaims] = useState([]);
    const [loading, setLoading] = useState();
    const [checkBoxState, setCheckBoxState] = useState(true);
    const [openMuiDialogueForProcedure, setOpenMuiDialogueForProcedure] = useState(false);
    const [isManuallyAddClaimCloseConfirm, setIsManuallyAddClaimCloseConfirm] = useState(false);
    const [showPayerDetailsEditModal, setShowPayerDetailsEditModal] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [selectedClaimPk, setSelectedClaimPk] = useState("");
    const [selectedClaimId, setSelectedClaimId] = useState("");
    const [eraModalEdit, setEraModalEdit] = useState(eraOriginalData);
    const [eraModalSaveData, setEraModalSaveData] = useState([]);
    const [adjustmentChangeValues, setAdjustmentChangeValues] = useState({
        index: "",
        field: "",
        changed: false,
        action: ""
    })
    const tableHeading2 = [
        i18n.t('payments.eraPage.table2.status'),
        i18n.t('payments.eraPage.table2.claimId'),
        i18n.t('payments.eraPage.table2.patientName'),
        i18n.t('payments.eraPage.table2.patientId'),
        i18n.t('payments.eraPage.table2.dos'),
        i18n.t('payments.eraPage.table2.charges'),
        i18n.t('payments.eraPage.table2.opening_balance'),
        i18n.t('payments.eraPage.table2.allowedAmount'),
        i18n.t('payments.eraPage.table2.paidAmount'),
        i18n.t('payments.eraPage.table2.adjustment_1_amount'),
        i18n.t('payments.eraPage.table2.adjustment_2_amount'),
        i18n.t('payments.eraPage.table2.closingBalance'),
        i18n.t('payments.eraPage.table2.action')
    ];
    const manualAddClaimTableHead1 = [
        i18n.t('payments.eraPage.table2.claimId'),
        i18n.t("payments.eraPage.table3.patientName"),
        i18n.t('payments.eraPage.table2.patientId'),
        i18n.t('payments.eraPage.table2.dos'),
        i18n.t('payments.eraPage.table2.charges'),
        i18n.t('payments.eraPage.table2.opening_balance'),
        i18n.t('payments.eraPage.table2.allowedAmount'),
        i18n.t('payments.eraPage.table2.paidAmount'),
        i18n.t('payments.eraPage.table2.closingBalance'),
    ];
    const manualAddClaimTableHead2 = [
        i18n.t('payments.eraPage.table2.claimId'),
        i18n.t("payments.eraPage.table3.patientName"),
        i18n.t('payments.eraPage.table2.dos'),
        i18n.t('payments.eraPage.table2.charges'),
        i18n.t('commons.balance'),
        i18n.t('dictionaries.payers.PayerTypes'),
        i18n.t('searchClaims.responsiblePayer'),
    ];
    const setShowLoadingBar = useContext(LoadingContext);
    const [procedureRowErrors, setProcedureRowErrors] = useState([]);
    const [isTotalPaidAmntGreaterThanCheckAmt, setIsTotalPaidAmntGreaterThanCheckAmt] = useState(false);
    const [eramodalEditFlag, setEramodalEditFlag] = useState(false);
    const practicePK = getStorage("practice");
    const typeHeadAdjustment1Ref = useRef([]);
    const typeHeadAdjustment2Ref = useRef([]);
    useEffect(() => {
        setERAData([...reviewData])
        const filterManuallyAddedClaims = (reviewData.filter(obj => obj.is_manually_added));
        setManuallyAddedClaims([...filterManuallyAddedClaims]);
    }, [reviewData]);

    // reset the reviewedList on return
    useEffect(() => {
        return () => {
            setClaimReviewedList([]);
        };
    }, []);
    useEffect(() => {
        setEraModalEdit(eraOriginalData)
    }, [eraOriginalData])


    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
    }

    function handleFilterOption(e) { // For selecting filter conditions
        const selectedOption = filter_Options.find(option => option.id === e.target.value);
        setERAFilterOption({ name: selectedOption.name, value: e.target.value });
        setFilterQuery('');
    }

    function handleERA_Filter(e) { // applying filter
        let filterValue;
        let filterKey = eraFilterOption.value;
        let filterResult;

        // To accept only numbers
        const onlyNumberAllowed = ['claim_id', 'patient_id', 'cpt_payment'];
        if (onlyNumberAllowed.includes(eraFilterOption.value) && isNaN(e.target.value)) { // To accept only numbers
            return;
        }

        if (eraFilterOption.value !== 'dos') { // handle filter for all parameters except date
            filterValue = e.target.value;
            setFilterQuery(filterValue);
            if (filterValue === '') {
                setERAData([...reviewData]);
                return;
            }
            filterResult = reviewData
                .filter(item => String(item[filterKey]).toLowerCase().includes(filterValue.toLowerCase()));
        } else { // filter by dos
            if (e == 'Invalid Date' || e === null) {
                if (e === 'Invalid Date') {
                    return;
                } else {
                    setERAData([...reviewData]);
                    return;
                }
            }

            if (!e) {
                filterValue = "";
            } else if (e instanceof Date && !isNaN(e)) {
                filterValue = DateTime.fromJSDate(e).toFormat('yyyy-MM-dd');
            }
            
            setFilterQuery(filterValue);
            filterResult = reviewData
                .filter(item => String(item[filterKey]).toLowerCase().includes(filterValue.toLowerCase()));
        }
        setERAData([...filterResult]);
    }

    const clearFilterInput = () => {
        setFilterQuery('')
        setERAData([...reviewData]);
    };

    /**
     * Triggers when user clicks on the add claim btn
     * also handling necessary validations before allowing to adding a new claim to the era table
     */
    function onAddClaimBtnClick() {
        setShowAddClaimModal(true)
        const filterManuallyAddedClaims = (reviewData.filter(obj => obj.is_manually == true));
        setManuallyAddedClaims([...filterManuallyAddedClaims]);
    }

    function searchClaimByID(query) { // add claim modal - Claim search
        setIsAsyncTypeSearching(true);
        const result = service.ClaimsDropdownForERAManualAdd(query, getStorage('practice'));
        result.then((response) => {
            setClaimsListForModalDropdown(response.data);
            setIsAsyncTypeSearching(false);
        })
    }


    // function to select a claim from the asyncTypeHead in add to era dialogue box
    function selectClaimFromDropdown(e) {
        const isItemSelected = e.length;
        const claimHasNoProcedure = e.some(obj => obj.name.includes("(None)")); // if claim has no procedure throwing error
        if (claimHasNoProcedure || isItemSelected === 0) { // handle error when evoked the function on backspace key press
            if (isItemSelected === 0) {
                return;
            }
            showNotifyWindow("show", "error", i18n.t("errorMessages.claim_has_no_procedure"));
            return;
        }
        setLoading(true);
        getClaimDetails(e[0].name.match(/\d+/g)[0]); // Extract the claim ID from the name and evoking the getClaim details function
    }

    // Function to get claim details of a selected claim in add to ERA Dialogue box
    function getClaimDetails(query) {
        if (query == claimDataForEraManualAdd.custom_claim_id) { // remove duplicate api call when the requesting claim detail already in the state
            setOpenMuiDialogue(true);
            setLoading(false);
            return;
        }
        const result = service.GetClaimDataForEraManualAdd(query, getStorage('practice'));
        result.then((response) => {
            if (Array.isArray(response.data.results) && response.data.results.length === 0) {
                setOpenMuiDialogue(false);
                showNotifyWindow("show", "error", i18n.t("errorMessages.not_find_Claim"));
                return;
            }
            let data = response.data.results;
            setClaimDataForEraManualAdd({
                claim_id: data[0].id,
                custom_claim_id: data[0].custom_claim_id,
                patient_name: data[0].patient_name,
                dos_from: YMD_TO_MDY(data[0].dos_from),
                charges: data[0].charges,
                balance: data[0].balance,
                payer_types: data[0].payer_types.type,
                responsible_payer: data[0].responsible_payer
            });
            setLoading(false);
        });
        setOpenMuiDialogue(true);
    }

    const handleMuiDialogueClose = () => {
        setOpenMuiDialogue(false);
    }


    /**
     * Function to add a selected claim to the era table
     */
    async function addSelectedClaimToERA() {
        // Check if the claim already there in the table
        const claimAlreadyExists = reviewData.some(obj => obj.claim_id === claimDataForEraManualAdd.custom_claim_id);
        if (claimAlreadyExists) {
            showNotifyWindow("show", "error", i18n.t('errorMessages.era_manual_add_claim_already_in_era'));
            return;
        }
        // API Payload
        const data = {
            claim_id: claimDataForEraManualAdd.claim_id,
            practice_pk: getStorage('practice'),
            header_pk: selectedPaymentPK
        };

        try {
            setShowLoadingBar(true);
            const response = await service.addClaimToEraManually(data);

            if (response?.data?.status_code === 400) {
                showNotifyWindow("show", "error", response?.data?.message || i18n.t("errorMessages.add_claim_error"));
                setShowLoadingBar(false);
                return;
            }
            if (response.data?.code === 200) {
                // Once confirmation and required data is received from the server on adding the new claim
                // then, firstly add it to the era table state i,e reviewData
                let data = {
                    ...response.data.claim_data,
                }
                setReviewData(prevReviewData => [...prevReviewData, data]);
                // secondly, add the same to array of manyally added claim ids
                setManuallyAddedClaimIDs(prevIDs => [...prevIDs, Number(claimDataForEraManualAdd.claim_id)]);
                handleMuiDialogueClose();
                // finally, call the api function reviewClaim by passing the pk recieved from current api to get its procedure level items
                setShowAddClaimModal(false);
                setClaimsListForModalDropdown([]);
                setClaimDataForEraManualAdd({
                    custom_claim_id: '', patient_name: '', dos_from: '', dos_to: '', payer_types: '', responsible_payer: ''
                });
                setTimeout(() => {
                    reviewClaim(null, response.data?.claim_data?.claim_pk, true, true);
                }, 1000);
                showNotifyWindow("show", "success", i18n.t('successMessages.era_manual_add_claim_success'));
            } else {
                showNotifyWindow("show", "error", i18n.t("errorMessages.add_claim_error"));
                setShowLoadingBar(false);
            }
        } catch (error) {
            showNotifyWindow("show", "error", i18n.t("errorMessages.claim_error"));
            setShowLoadingBar(false);
        }
    }
    // remove a manually added claim from the era table
    function removeManuallyAddedClaim(itemToRemove) {
        const eraListAfterRemoval = reviewData.filter(obj => obj.claim_pk !== itemToRemove);
        setReviewData([...eraListAfterRemoval]);
        setEditedProcedureData((prevState) => {
            const itemsToRemove = Object.keys(prevState).filter(key => key.startsWith(itemToRemove));
            const newState = Object.entries(prevState)
                .filter(([key]) => !itemsToRemove.includes(key))
                .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
            return newState;
        });
    }

    // Reset ERA Posting values on procedure level.
    async function resetProcedurePostingInputs(index) {
        const claimData = await get(`${procedureData[index]?.claimId}`);
        setProcedureData(prevProcedureData => {
            const dataToUpdate = [...prevProcedureData];
            dataToUpdate[index] = claimData[index];
            return dataToUpdate;
        });

        setProcedureRowErrors(prevErrors => {
            const errorsToUpdate = [...prevErrors];
            errorsToUpdate[index] = {};
            return errorsToUpdate;
        });
    }

    // mark an era as reviewed on checkbox click
    function handleReviewCheckboxClick(claim_PK) {
        // Get the review data and the index of the current claim in the data
        const data = reviewData;
        const itemIndex = data.findIndex((item) => item.claim_pk === claim_PK);

        // Get the current item from the data
        const item = data[itemIndex];

        // Check if the item exists
        if (item) {
            // Toggle the isReviewed property of the item
            item.checked = !item.checked;

            // Add or remove the claim_PK to the claimReviewedList based on the isReviewed property
            let reviewedList = claimReviewedList;
            if (item.checked === true) {
                reviewedList.push(claim_PK);
            } else {
                reviewedList = reviewedList.filter(item => item !== claim_PK);
            }

            // Update the claimReviewedList state
            setClaimReviewedList(reviewedList);

            // Update the item in the data
            data[itemIndex] = item;
        }

        // Update the reviewData state
        setReviewData([...data]);

        //update the parent checkbox 
        const allChecked = data.every(item => item.checked);
        setIsReviewAll(allChecked);
    }

    // mark all era as reviewed on table header checkbox click
    function handleReviewAllCheckboxClick() {
        const data = reviewData.map(item => {
            return {
                ...item,
                checked: !isReviewAll
            };
        });
        setReviewData(data);
        setIsReviewAll(!isReviewAll);
        if (isReviewAll) {
            const claimReviewedList = reviewData.map(item => item.claim_pk);
            setClaimReviewedList(claimReviewedList);
            setShowLoadingBar(false);
        } else {
            setClaimReviewedList([]);
        }
    }


    /**
     * Handling user triggered value changes in the procedure level rows
     * @param {*} e e.target,value or e= value
     * @param {*} index index of the procedure row
     * @param {*} apply_type name of the field /column its being evoked
     */
    function onHandleApply(e, index, apply_type) {
        let temp = JSON.parse(JSON.stringify(procedureData));
        let value = String(e.target.value).trim().replace(/[^\-\d|\d(.\d+)]/g, '').substring(0, 9);

        // Convert to two decimal points when the input has more than 2 decimal points
        if (!isNaN(Number(value))) {
            let parts = String(value).split('.');
            if (parts.length > 1 && parts[1].length > 2) {
                value = parseFloat(value)?.toFixed(2);
            }
        }

        if (apply_type === 'responsibility_type' || apply_type === 'claim_status_id' || apply_type === 'claim_sub_status_id')
            value = parseInt(value);
        temp[index][apply_type] = value;

        //Conditionally clearing the states
        if (apply_type === 'responsibility_type') {
            temp[index].claim_status_id = "";
            temp[index].claim_sub_status_id = "";
        } else if (apply_type === 'claim_status_id') {
            temp[index].claim_sub_status_id = "";
        }

        if (isNaN(temp[index]['charges'] - temp[index]['allowed_amount'])) {
            setProcedureData(temp);
        }
        else {
            if (apply_type === 'allowed_amount') {
                temp[index]['adjustment_amount1'] = temp[index]['charges'] - temp[index]['allowed_amount'];
            }
            setProcedureData(temp);
        }
    }

    useEffect(() => {
        if (adjustmentChangeValues.changed) {
            onBlurApply(adjustmentChangeValues.field, adjustmentChangeValues.index)
        }
    }, [adjustmentChangeValues.changed])

    // Helper functions to reduce complexity
    function parseNumber(value) {
        return !isNaN(value) && value !== null && value !== "" ? Number(parseFloat(value).toFixed(2)) : 0;
    }

    function calculateAdjustmentSum(adjustmentData) {
        return adjustmentData?.reduce((sum, adjustmentItem) => {
            return sum + Number(adjustmentItem?.amount ?? 0);
        }, 0) || 0;
    }

    function updateErrorMessage(errors, index, field, message, adjustmentData) {
        errors[index] = {
            ...errors[index],
            [field]: adjustmentData?.reduce((fieldErrors, adjustmentItem) => {
                fieldErrors[adjustmentItem.id] = {
                    message,
                    label: adjustmentItem?.label
                };
                return fieldErrors;
            }, {})
        };

        setProcedureRowErrors(errors);
    }

    function removeErrorMessage(errors, index, field, adjustmentData) {
        if (errors[index]?.[field]) {
            if (adjustmentData.length === 0) {
                delete errors[index][field];
            }
            else {
                adjustmentData?.forEach(adjustmentItem => {
                    delete errors[index][field][adjustmentItem.id];
                });
            }
        }

        setProcedureRowErrors(errors)
    }

    function handleResponsibilityTypeUpdate(procedure, index, dataInLocalState, originalResponsibilityForPatient) {
        procedure[index]['claim_sub_status_id'] = null;
        procedure[index]['claim_status_id'] = null;
        const responsibilityData = dataInLocalState?.responsibilityList?.filter(item => item.name === 'Paid');
        if (responsibilityData?.length > 0) {
            procedure[index]["claim_status_id"] = null;
            procedure[index]["claim_sub_status_id"] = null;
            procedure[index]["responsibility_type"] = responsibilityData[0].id ?? procedure[index]['next_responsibility'];
        } else {
            procedure[index]["claim_status_id"] = null;
            procedure[index]["claim_sub_status_id"] = null;
            procedure[index]["responsibility_type"] = originalResponsibilityForPatient ?? procedure[index]['next_responsibility'];
        }
        setProcedureData(procedure);
    }

    function handleAdjustmentCode2Changes(procedure, index, dataInLocalState, originalResponsibilityForPatient, currentAllowed, currentPaid, currentResponsibilityType) {
        let matchingElements = procedure[index]?.adjustment_code2_data?.findIndex(element => {
            return element.label === "PR-1" || element.label === "PR-2" || element.label === "PR-3";
        });

        if (matchingElements !== -1) {
            if (matchingElements === 0 && dataInLocalState) {
                updateResponsibility(procedure, index, dataInLocalState, originalResponsibilityForPatient, currentResponsibilityType);
            } else {
                let responsibilityData = dataInLocalState?.responsibilityList?.filter(item => item.name === 'Primary');
                procedure[index]["responsibility_type"] = responsibilityData[0]?.id == undefined ? procedure[index]['next_responsibility'] : responsibilityData[0]?.id;
            }
        } else if (procedure[index]?.adjustment_code2_data?.length === 0) {

            if (currentAllowed === currentPaid && procedure[index]?.adjustment_code2_data.length == 0) {
                const responsibilityData = dataInLocalState?.responsibilityList?.filter(item => item.name === 'Primary');
                if (responsibilityData.length > 0) {
                    procedure[index]["responsibility_type"] = responsibilityData[0].id ?? procedure[index]['next_responsibility'];
                }
                return;
            }
            else if (currentAllowed === currentPaid) {
                const responsibilityData = dataInLocalState?.responsibilityList?.filter(item => item.name === 'Paid');
                if (responsibilityData.length > 0) {
                    procedure[index]["responsibility_type"] = responsibilityData[0].id ?? procedure[index]['next_responsibility'];
                    procedure[index]["claim_status_id"] = null;
                    procedure[index]["claim_sub_status_id"] = null;
                } else {
                    procedure[index]["responsibility_type"] = originalResponsibilityForPatient ?? procedure[index]['next_responsibility'];
                }
            }
            else {
                procedure[index]["claim_status_id"] = null;
                procedure[index]["claim_sub_status_id"] = null;
                procedure[index]["responsibility_type"] = procedure[index].current_responsibility ?? procedure[index]['next_responsibility'];
            }
        } else {
            if (procedure[index]?.adjustment_code2_data.length != 0) {
                procedure[index]["responsibility_type"] = procedure[index].current_responsibility ?? procedure[index]['next_responsibility'];
                const { status_id, sub_status_id } = checkForDenialStatus(procedure[index].current_responsibility, claimStatusList, procedure[index])
                procedure[index]["claim_status_id"] = status_id ? status_id : null;
                procedure[index]["claim_sub_status_id"] = sub_status_id ? sub_status_id : null;
            }
            else {
                let responsibilityData = dataInLocalState?.responsibilityList?.filter(item => item.name === 'Primary');
                procedure[index]["claim_status_id"] = null;
                procedure[index]["claim_sub_status_id"] = null;
                procedure[index]["responsibility_type"] = responsibilityData[0].id == undefined ? procedure[index]['next_responsibility'] : responsibilityData[0].id;
            }
        }

        setProcedureData(procedure);
    }


    const checkForDenialStatus = (responsibility, claimStatusList, procedure) => {
        let denialItem = "";
        let status_id = "";
        let sub_status_id = "";
        if (responsibility == 1) {
            denialItem = "Claim Denied at Primary".toLowerCase();
        } else if (responsibility == 2) {
            denialItem = "Claim Denied at Secondary".toLowerCase();
        } else {
            denialItem = "Claim Denied at Tertiary".toLowerCase();
        }
        let claim_status_id = claimStatusList.find(item => item.name.toLowerCase() === denialItem && item?.priorities?.includes(responsibility))?.id;
        if (claim_status_id) {
            status_id = claim_status_id;
        }

        const allowedLabels = ["PR-1", "PR-2", "PR-3"];
        let filteredItems = procedure.adjustment_code2_data.filter(item => !allowedLabels.includes(item.label));

        if (filteredItems.length === 0) return null;

        const lastItem = filteredItems[filteredItems.length - 1];

        let claim_sub_status_id = lastItem.claim_sub_status.length > 0
            ? lastItem.claim_sub_status[0].id
            : "";
        if (claim_sub_status_id) {
            sub_status_id = claim_sub_status_id;
        }
        return { status_id, sub_status_id }

    }

    function updateResponsibility(procedure, index, dataInLocalState, originalResponsibilityForPatient, currentResponsibilityType) {
        const currentResponsibility = procedure[index].current_responsibility;
        if (currentResponsibility === 1) {
            handleResponsibility(procedure, index, dataInLocalState, 'Secondary', 'Patient', originalResponsibilityForPatient);
        } else if (currentResponsibility === 2) {
            handleResponsibility(procedure, index, dataInLocalState, 'Tertiary', 'Patient', originalResponsibilityForPatient);
        } else if (currentResponsibility === 3) {
            handleResponsibility(procedure, index, dataInLocalState, 'Patient', '', originalResponsibilityForPatient);
        } else if (currentResponsibility === 5) {
            procedure[index]["responsibility_type"] = currentResponsibilityType ?? procedure[index]['next_responsibility'];
            procedure[index]["claim_status_id"] = null;
            procedure[index]["claim_sub_status_id"] = null;
        }
    }

    function handleResponsibility(procedure, index, dataInLocalState, primaryRole, fallbackRole, originalResponsibilityForPatient) {
        let responsibilityData = dataInLocalState?.responsibilityList?.filter(item => item.name === primaryRole);

        if (responsibilityData?.length > 0) {
            procedure[index]["responsibility_type"] = responsibilityData[0]?.id ?? procedure[index]['next_responsibility'];
            procedure[index]["claim_status_id"] = null;
            procedure[index]["claim_sub_status_id"] = null;
        } else {
            responsibilityData = dataInLocalState?.responsibilityList?.find(item => item.name === fallbackRole)?.id || originalResponsibilityForPatient;
            procedure[index]["responsibility_type"] = responsibilityData ?? procedure[index]['next_responsibility'];
            procedure[index]["claim_status_id"] = null;
            procedure[index]["claim_sub_status_id"] = null;
        }
        setProcedureData(procedure);
    }

    // Main function refactored
    function onBlurApply(field, index) {
        const updatedErrors = [...procedureRowErrors];
        const procedure = JSON.parse(JSON.stringify(procedureData));
        const currentProcessData = procedure[index];
        const currentAllowed_amt = parseNumber(currentProcessData.allowed_amount);
        const current_paid_amt = parseNumber(currentProcessData.paid_amount);
        const current_opening_balance = parseNumber(currentProcessData.opening_balance);
        const adjustment_amt1_sum = calculateAdjustmentSum(procedure[index].adjustment_code1_data);
        const adjustment_amt2_sum = calculateAdjustmentSum(procedure[index].adjustment_code2_data);
        const closingBalance = parseNumber((current_opening_balance) - (current_paid_amt) - (adjustment_amt1_sum));
        const isClosingBalanceZero = closingBalance <= 0;
        const patientPk = currentProcessData.patient_id;
        const chargeAmount = parseFloat((current_opening_balance - currentAllowed_amt).toFixed(2));
        const dataInLocalState = responsibilityList.find(item => item.patientPk == patientPk);
        const originalResponsibilityForPatient = originalResponsibility.find(item => item.patient_pk == patientPk)?.responsibility;
        if (field === "allowed_amount" || field === "adjustment_amount1") {
            if (currentAllowed_amt > current_opening_balance) {
                updatedErrors[index] = { ...updatedErrors[index], allowedAmount: 'Allowed amount cannot be greater than charges amount' };
            } else {
                if (updatedErrors[index]?.allowedAmount) {
                    delete updatedErrors[index]?.allowedAmount;
                }
                if (adjustment_amt1_sum !== 0 && adjustment_amt1_sum !== chargeAmount) {
                    updateErrorMessage(updatedErrors, index, 'adjustment_amount1', 'Total Adjustment 1 amount should equal to Charge amount - Allowed amount.', procedure[index].adjustment_code1_data);
                } else {
                    removeErrorMessage(updatedErrors, index, 'adjustment_amount1', procedure[index].adjustment_code1_data);
                }

                if (field === "adjustment_amount1") {
                    if (adjustment_amt2_sum !== 0 && (current_opening_balance - current_paid_amt - adjustment_amt1_sum) !== adjustment_amt2_sum) {
                        updateErrorMessage(updatedErrors, index, 'adjustment_amount2', 'Total Adjustment 2 amount should equal to Charge amount - Paid Amount - Total Adjustment 1 amount.', procedure[index].adjustment_code2_data);
                    } else {
                        removeErrorMessage(updatedErrors, index, 'adjustment_amount2', procedure[index].adjustment_code2_data);
                    }
                }
            }
        }

        if (field === "paid_amount" || field === "adjustment_amount2") {
            if (adjustment_amt2_sum !== 0 && parseNumber(current_opening_balance - current_paid_amt - adjustment_amt1_sum) !== adjustment_amt2_sum) {
                updateErrorMessage(updatedErrors, index, 'adjustment_amount2', 'Total Adjustment 2 amount should equal to Charge amount - Paid Amount - Total Adjustment 1 amount.', procedure[index].adjustment_code2_data);
            } else {
                removeErrorMessage(updatedErrors, index, 'adjustment_amount2', procedure[index].adjustment_code2_data);
            }
        }

        if (field === "adjustment_code1_data" && adjustmentChangeValues.action === "remove") {
            if (adjustment_amt1_sum !== 0 && adjustment_amt1_sum !== (current_opening_balance - currentAllowed_amt)) {
                updateErrorMessage(updatedErrors, index, 'adjustment_amount1', 'Total Adjustment 1 amount should equal to Charge amount - Allowed amount.', procedure[index].adjustment_code1_data);
            } else {
                removeErrorMessage(updatedErrors, index, 'adjustment_amount1', procedure[index].adjustment_code1_data);
            }

            if (adjustment_amt2_sum !== 0 && (current_opening_balance - current_paid_amt - adjustment_amt1_sum) !== adjustment_amt2_sum) {
                updateErrorMessage(updatedErrors, index, 'adjustment_amount2', 'Total Adjustment 2 amount should equal to Charge amount - Paid Amount - Total Adjustment 1 amount.', procedure[index].adjustment_code2_data);
            } else {
                removeErrorMessage(updatedErrors, index, 'adjustment_amount2', procedure[index].adjustment_code2_data);
            }
        }

        if (field === "adjustment_code2_data" && adjustmentChangeValues.action === "remove") {
            if (adjustment_amt2_sum !== 0 && (current_opening_balance - current_paid_amt - adjustment_amt1_sum) !== adjustment_amt2_sum) {
                updateErrorMessage(updatedErrors, index, 'adjustment_amount2', 'Total Adjustment 2 amount should equal to Charge amount - Paid Amount - Total Adjustment 1 amount.', procedure[index].adjustment_code2_data);
            } else {
                removeErrorMessage(updatedErrors, index, 'adjustment_amount2', procedure[index].adjustment_code2_data);
            }
        }

        if (isClosingBalanceZero && current_paid_amt !== 0) {
            handleResponsibilityTypeUpdate(procedure, index, dataInLocalState, originalResponsibilityForPatient);
        } else {
            handleAdjustmentCode2Changes(procedure, index, dataInLocalState, originalResponsibilityForPatient, currentAllowed_amt, current_paid_amt);
        }
        procedure[index]['closing_balance'] = current_opening_balance - current_paid_amt - adjustment_amt1_sum;
        setProcedureData(procedure);

        setAdjustmentChangeValues({ field: "", index: "", changed: false, action: "" });
        setProcedureRowErrors(updatedErrors);
    }

    function onHandleMultiSelectSearchForRemarkCodes(value, index) {
        let arrayId = [];
        let arrayCodes = [];
        value.map((item) => {
            arrayId.push(item.id);
            arrayCodes.push(item)
        });
        let temp = JSON.parse(JSON.stringify(procedureData));
        if (value) {
            temp[index]['remark_codes'] = arrayId;
            temp[index]['selectedRemarkCodes'] = arrayCodes;
        } else {
            temp[index]['remark_codes'] = [];
            temp[index]['selectedRemarkCodes'] = [];
        }

        setProcedureData(temp);
    }


    /**
     * @param {*} adjustment1or2 whether the typehead is from adjustment or 2 
     * @param {*} index the index from procedureRow
     */
    function clearTypeHeadSearchValue(adjustment1or2, index) {
        // Clear the input value from async type head when an item is selected by the user
        if (adjustment1or2 === 'adjustment_code1_data') {
            if (adjustment1or2 === 'adjustment_code1_data') {
                if (typeHeadAdjustment1Ref.current[index] && typeof typeHeadAdjustment1Ref.current[index].clear === 'function') {
                    typeHeadAdjustment1Ref.current[index].clear();
                }
            }
        } else if (adjustment1or2 === 'adjustment_code2_data') {
            if (adjustment1or2 === 'adjustment_code2_data') {
                if (typeHeadAdjustment2Ref.current[index] && typeof typeHeadAdjustment2Ref.current[index].clear === 'function') {
                    typeHeadAdjustment2Ref.current[index].clear();
                }
            }
        }
    }

    /**
     * @param {e} event native event from async type head on adjustment code add
     * @param {index} index index of the procedureRow that is being edited {parentIndex}
     * @param {indexToRemove} indexToRemove index of adjustmentCode within a procedureRow {childIndex}
     * @param {adjustment1or2} adjustment1or2 whether the edit is being triggered from adjustment 1 cell or adjustment 2 cell
      */
    function handleAdjustmentCodeChange(e, index, remove, indexToRemove, adjustment1or2) {
        if (e.length === 0 && remove !== 'remove') {
            return;
        }
        if (!remove) {
            // Validate if the requesting adjustment code already there in the entry
            const arrayOfAllCodes = [...procedureData[index]['adjustment_code1_data'], ...procedureData[index]['adjustment_code2_data']];
            if (arrayOfAllCodes.some((item) => item.id === e[0].id)) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.duplicate_entry"));
                clearTypeHeadSearchValue(adjustment1or2, index)
                return;
            }
            // Validate if the PR-1 PR-2 PR-3 are adding only to Adjustment Code 2 set
            if (
                (e[0].label === 'PR-1' || e[0].label === 'PR-2' || e[0].label === 'PR-3')
                && adjustment1or2 !== 'adjustment_code2_data'
            ) {
                showNotifyWindow("show", "error", i18n.t("errorMessages.PR_added"));
                clearTypeHeadSearchValue(adjustment1or2, index)
                return;
            }
        }
        // Update the state
        setProcedureData((prevState) => {
            const newProcedureData = [...prevState];
            if (remove === 'remove') {
                const newEraAdjustments1 = [...newProcedureData[index][adjustment1or2]];
                newEraAdjustments1.splice(indexToRemove, 1);
                newProcedureData[index][adjustment1or2] = newEraAdjustments1;
                newProcedureData[index].claim_status_id = null;
                newProcedureData[index].claim_sub_status_id = null;
                // When user removes PR-1,2,3 then reset the copay, coInsurance, and deductive values
                if (
                    (e.label === 'PR-1' || e.label === 'PR-2' || e.label === 'PR-3')
                ) {
                    switch (e.label) {
                        case 'PR-1':
                            newProcedureData[index].deductible = '';
                            break;
                        case 'PR-2':
                            newProcedureData[index].coInsurance = '';
                            break;
                        case 'PR-3':
                            newProcedureData[index].copay = '';
                            break;
                        default:
                            break;
                    }
                }
            } else {
                newProcedureData[index][adjustment1or2] = [...newProcedureData[index][adjustment1or2], e[0]];
            }
            return newProcedureData;
        })
        clearTypeHeadSearchValue(adjustment1or2, index)
        if (remove === "remove") {
            setAdjustmentChangeValues({
                field: adjustment1or2,
                action: remove,
                index: index,
                changed: true
            })
        }
    }

    /**
     * Function to update the adjustment code 1 & 2 amounts
     * @param {*} e // e.target.value i.e that amount
     * @param {*} adjstmntCode1 
     * @param {*} index // procedure row index
     * @param {*} adjustment1or2 
     */
    const handleAdjustmentAmountChange = (e, adjstmntCode1, index, adjustment1or2) => {
        let value = String(e.target.value).trim().replace(/[^\-\d|\d(.\d+)]/g, '').substring(0, 9);
        // Convert to two decimal points when the input has more than 2 decimal points
        if (!isNaN(Number(value))) {
            let parts = String(value).split('.');
            if (parts.length > 1 && parts[1].length > 2) {
                value = parseFloat(value)?.toFixed(2);
            }
        }

        setProcedureData((prevState) => {
            // Get the row that need edit
            let procedureRowToEdit = { ...prevState[index] }; // Create a new object
            // Find the index of the  adjustmentItem that need edit
            const adjustmentEntryToEdit = procedureRowToEdit[adjustment1or2]?.findIndex((code) => code.id === adjstmntCode1.id);
            if (adjustmentEntryToEdit !== -1) {
                procedureRowToEdit[adjustment1or2][adjustmentEntryToEdit].amount = value;
            }
            // If the amount has been added to PR-1,2,3 codes then copy that value to copay, co-insurance and deductive respectively
            if (
                adjstmntCode1.label === 'PR-1' || adjstmntCode1.label === 'PR-2' || adjstmntCode1.label === 'PR-3'
            ) {
                switch (adjstmntCode1.label) {
                    case 'PR-1':
                        procedureRowToEdit.deductible = value;
                        break;
                    case 'PR-2':
                        procedureRowToEdit.coInsurance = value;
                        break;
                    case 'PR-3':
                        procedureRowToEdit.copay = value;
                        break;
                    default:
                        break;
                }
            }
            if (procedureRowToEdit) {
                procedureRowToEdit.claim_status_id = null
                procedureRowToEdit.claim_sub_status_id = null
            }

            const newState = [...prevState]; // Create a new array
            newState[index] = procedureRowToEdit; // Update the new array
            return newState; // Return the new array
        });
    }


    // adjust table height when using Async-Type-Head-Dropdown
    const [tableHeight, setTableHeight] = useState('');
    useEffect(() => {
        if (procedureData && procedureData.length >= 1) {
            let div = document.getElementById("procedureDisplayTable");
            if (div) {
                setTableHeight(div.offsetHeight);
                div.style.transition = "all 0.5s ease-in-out";
            }
        }

        setProcedureRowErrors((prevState) => {
            let newErrors = [...prevState];
            for (let index = 0; index < procedureData?.length; index++) {
                newErrors[index] = {};
            }
            return newErrors;
        });

    }, [procedureData?.length]);


    const handleModalHeightOnAdjustmentInputBoxFocus = (isFocused, index) => {

        let div = document.getElementById("procedureDisplayTable");
        div.style.transition = "all 0.5s ease-in-out";

        let minHeight;
        if (isFocused && (procedureData.length <= 3 || index > procedureData.length - 3)) {
            minHeight = tableHeight + 150 + "px";
        } else {
            minHeight = "100px";
        }
        div.style.minHeight = minHeight;

    }

    const eraStructuring = (procedureData) => {
        let procData = [];
        procedureData.forEach(item => {
            let adjustment1 = [];
            let adjustment2 = [];
            if (item.adjustment_code1_data && item.adjustment_code1_data.length) {
                item.adjustment_code1_data.forEach(adjitem1 => {
                    // Check if the item already exists in adjustment1
                    if (!adjustment1.some(adj => adj.id === adjitem1.id)) {
                        adjustment1.push({
                            code: adjitem1.label,
                            amount: adjitem1.amount,
                            id: adjitem1.id
                        });
                    }
                });
            }

            if (item.adjustment_code2_data && item.adjustment_code2_data.length) {
                item.adjustment_code2_data.forEach(adjitem2 => {
                    // Check if the item already exists in adjustment2
                    if (!adjustment2.some(adj => adj.id === adjitem2.id)) {
                        adjustment2.push({
                            code: adjitem2.label,
                            amount: adjitem2.amount,
                            id: adjitem2.id
                        });
                    }
                });
            }

            let values = {
                "id": item.id ? item.id : null,
                "claim_id": item.claim_custom_id ? item.claim_custom_id : null,
                "date_of_service": item.date_of_service ? item.date_of_service : null,
                "cpt_payment": item.paid_amount ? item.paid_amount : 0,
                "is_manually_added": item.is_manually_added ? item.is_manually_added : false,
                "claim_pk": item.claim ? item.claim : null,
                "cpt_code": item.cpt_code ? item.cpt_code : null,
                "cpt_mod_code": item.cpt_mod_code ? item.cpt_mod_code : null,
                "cpt_mod_code2": item.cpt_mod_code2 ? item.cpt_mod_code2 : null,
                "cpt_mod_code3": item.cpt_mod_code3 ? item.cpt_mod_code3 : null,
                "cpt_mod_code4": item.cpt_mod_code4 ? item.cpt_mod_code4 : null,
                "remark_codes": item.remark_codes ? item.remark_codes : [],
                "allowed_amount": item.allowed_amount ? item.allowed_amount : 0,
                "copay": item.copay ? item.copay : 0,
                "coInsurance": item.coInsurance ? item.coInsurance : 0,
                "deductibles": item.deductible ? item.deductible : 0,
                "adjustments": {
                    "adjustment1": adjustment1 ? adjustment1 : [],
                    "adjustment2": adjustment2 ? adjustment2 : []
                },
                "responsibility_type": item.responsibility_type ? item.responsibility_type : "",
                "exists_in_sys": item.exists_in_sys ? item.exists_in_sys : false,
                "is_reviewed": true,
                "cpt_not_found": item.cpt_not_found ? item.cpt_not_found : false,
                "claim_status_id": item.claim_status_id ? item.claim_status_id : null,
                "claim_sub_status_id": item.claim_sub_status_id ? item.claim_sub_status_id : null
            }

            procData.push(values)

        })

        return procData;
    }

    //save function
    const handleEraProcedureSave = async () => {
        setShowLoadingBar(true);
        let procedure_data = eraStructuring(procedureData);
        procedure_data.map(item => item.claim_pk = selectedClaimID);
        let structuredData = {
            "header_pk": selectedPaymentPK,
            "procedures": procedure_data,
            "practice_pk": practicePK
        }
        const result = await service.EraModalSave(selectedClaimID, structuredData)
        if (result.status === 200) {

            setEraModalSaveData(result.data);
            const newClaimPk = eraModalSaveData[0]?.claim_pk;

            if (newClaimPk) {
                setSelectedClaimID(newClaimPk);
            }
            setShowLoadingBar(false);
            return { success: "success", data: result.data };
        } else {
            setShowLoadingBar(false);
            if (result.status == 404) {
                showNotifyWindow("show", "error", "Something went wrong please try again");
            }

            if (result.status == 400) {
                showNotifyWindow("show", "error", "No Claim IDs Provided");
            }
        }
        return "error";
    }

    /**
     * Function to handle when user hits save btn on the procedure row modal of the era
     * This checks if there's any changes made to the item,
     * if there's change then need further validations and after that is passed then it needs to be stored in the editedProcedure items state
     * to finally send to server on apply
     */
    const handleProcedureEditSubmit = async () => {

        const mandatoryFields = procedureData.some(item =>
            !item.claim_status_id ||
            !item.claim_sub_status_id ||
            !item.responsibility_type
        );

        if (mandatoryFields) {
            showNotifyWindow("show", "error", "Claim Status, Claim Sub Status, and Responsibility Type are required fields.");
            return;
        }
        setShowLoadingBar(true);
        let procedure_data = eraStructuring(procedureData);
        const fieldsToCheck = [
            'allowed_amount',
            'paid_amount',
            'responsibility_type',
            'adjustment_code2_data',
            'adjustment_code1_data',
            'remark_codes',
            'claim_status_id',
            "claim_sub_status_id"
        ];

        procedure_data.map(item => item.claim_pk = selectedClaimID);

        setShowLoadingBar(false);
        let originalState;
        try {
            originalState = await get(`${procedureData[0]?.claimId}`);
        } catch (error) {
            console.error('Failed to parse procedureDataCopy from indexedDb', error);
            return;
        }

        if (!originalState) {
            console.error('No procedureDataCopy found in indexedDb');
            return;
        }

        let isEdited = false;
        for (let item of procedureData) {
            for (let field of fieldsToCheck) {
                const index = procedureData.indexOf(item);
                if (field === 'allowed_amount' || field === 'paid_amount' || field === 'responsibility_type' || field === 'claim_status_id' || field === 'claim_sub_status_id') {
                    if (item[field] != originalState[index][field]) {
                        isEdited = true;
                        break;
                    }
                }
                if (field == 'adjustment_code2_data' || field == 'adjustment_code1_data') {
                    if (item[field]?.length !== originalState[index][field]?.length) {
                        isEdited = true;
                        break;
                    } else {
                        for (let adjustmentItem of item[field]) {
                            const adjustmentIndex = item[field].indexOf(adjustmentItem);
                            if (!originalState[index][field]?.some(item => item.id == adjustmentItem.id)) {
                                isEdited = true;
                                break;
                            }
                            if (adjustmentItem.amount !== originalState[index][field][adjustmentIndex]?.amount) {
                                isEdited = true;
                                break;
                            }
                        }
                    }
                }
                if (field === 'remark_codes') {
                    if (item[field]?.length !== originalState[index][field]?.length) {
                        isEdited = true;
                        break;
                    } else {
                        for (let code of item[field]) {
                            if (!originalState[index][field]?.includes(code)) {
                                isEdited = true;
                                break;
                            }
                        }
                    }
                }
                if (isEdited) break;
            }
            if (isEdited) break;
        }

        if (!isEdited) {
            if (reviewData.some(item => item.is_manually_added === true && item.claim_pk === selectedClaimID && isNewClaim)) {
                setIsManuallyAddClaimCloseConfirm(true);
                return;
            }
            // Check any of the entries were previously edited but now user has reset it,
            // in this case remove that item from the editedProcedureData
            if (Object.keys(editedProcedureData).length !== 0) {

                const itemsToRemove = [];
                const editedItems = Object.keys(editedProcedureData);

                procedureData.forEach((rowItem) => {
                    if (editedItems.includes(`${rowItem.claimId}|${rowItem.cpt_code}`)) {
                        itemsToRemove.push(`${rowItem.claimId}|${rowItem.cpt_code}`);
                    }
                });

                if (itemsToRemove.length) {
                    setEditedProcedureData((prevState) => {
                        // copy of prevState;
                        let newState = { ...prevState };
                        itemsToRemove.forEach((item) => {
                            delete newState[item];
                        });

                        return newState;
                    });

                    setReviewData((prevState) => {
                        return prevState.map((item) => {
                            if (item.claimId == procedureData[0]?.claimId) {
                                return {
                                    ...item,
                                    isModified: false,
                                    updatedTotals: null,
                                };
                            } else return item;
                        });
                    });
                } else {
                    let { success, data } = await handleEraProcedureSave();
                    if (success == "success") {
                        saveEditProcedures(data);
                    }
                }
                setShowDetailsModal(false);
                setProcedureRowErrors([]);
                return;
            }
            else {
                let { success, data } = await handleEraProcedureSave();

                if (success == "success" && data.length > 0) {
                    setReviewData((prevState) => {
                        return prevState.map((item) => {
                            const filteredData = data?.find(data => data.claim_pk === item.claim_pk)
                            if (filteredData) {
                                return {
                                    exists_in_sys: filteredData.exists_in_sys ? filteredData.exists_in_sys : false,
                                    opening_balance: filteredData.opening_balance ? filteredData.opening_balance : 0,
                                    closing_balance: filteredData.closing_balance ? filteredData.closing_balance : 0,
                                    allowed_amount: filteredData.allowed_amount ?? item.allowed_amount,
                                    paid_amount: filteredData.paid_amount ? filteredData.paid_amount : 0,
                                    adj_amt_1: filteredData.adj_amt_1 ? filteredData.adj_amt_1 : 0,
                                    adj_amt_2: filteredData.adj_amt_2 ? filteredData.adj_amt_2 : 0,
                                    cpt_charge: filteredData.cpt_charge ? filteredData.cpt_charge : 0,
                                    cpt_payment: filteredData.cpt_payment ? filteredData.cpt_payment : 0,
                                    claim_id: filteredData.claim_id ? filteredData.claim_id : null,
                                    patient_name: filteredData.patient_name ? filteredData.patient_name : null,
                                    patient_id: filteredData.patient_id ? filteredData.patient_id : null,
                                    dos: filteredData.dos ? filteredData.dos : null,
                                    warning: filteredData.warning ? filteredData.warning : false,
                                    insurance_payment_pk: filteredData.insurance_payment_pk ? filteredData.insurance_payment_pk : "",
                                    warnings: filteredData.warnings ? filteredData.warnings : [],
                                    is_manually_added: filteredData.is_manually_added ? filteredData.is_manually_added : false,
                                    is_reviewed: filteredData.is_reviewed ? filteredData.is_reviewed : false,
                                    is_applied: filteredData.is_applied ? filteredData.is_applied : false,
                                    co_253_amount: filteredData.co_253_amount ? filteredData.co_253_amount : 0,
                                    checked: true,
                                    claim_pk: filteredData.claim_pk ? filteredData.claim_pk : null
                                };
                            } else return item;
                        });
                    })
                    setShowDetailsModal(false);
                    setProcedureRowErrors([]);
                    return;
                }

            }

        }
        else {
            const editedClaimIds = [];
            for (const key in editedProcedureData) {
                if (Object.prototype.hasOwnProperty.call(editedProcedureData, key)) {
                    const claimId = editedProcedureData[key].claimId;
                    if (!editedClaimIds.includes(claimId)) {
                        editedClaimIds.push(claimId);
                    }
                }
            }

            // Get the total of currently editing items total paid amouont
            const totalPaidOfEdited = procedureData.reduce((total, item) => total + (parseFloat(item.paid_amount) || 0), 0);

            let setIsTotalPaidAmntGreaterThanCheckAmtFlag = false
            if ((parseFloat(totalPaidOfEdited.toFixed(2))) > parseFloat(Number(eraModalEdit.total_check_amount)?.toFixed(2))) {
                setIsTotalPaidAmntGreaterThanCheckAmtFlag = true;
                setIsTotalPaidAmntGreaterThanCheckAmt(true);
            } else {
                setIsTotalPaidAmntGreaterThanCheckAmt(false);
            }

            let hasErrors = false;
            for (let i = 0; i < procedureRowErrors.length; i++) {
                const item = procedureRowErrors[i];
                for (const key in item) {
                    const value = item[key];
                    if (typeof value === 'string' && value.trim() !== '') {
                        hasErrors = true;
                        break;
                    }
                    if (typeof value === 'object' && value !== null) {
                        for (const subKey in value) {
                            const subValue = value[subKey];
                            if (subValue.message && subValue.message.trim() !== '') {
                                hasErrors = true;
                                break;
                            }
                        }
                    }
                }
                if (hasErrors) {
                    break;
                }
            }
            // When the data is modified but no errors and warnings then call the save function
            if (!setIsTotalPaidAmntGreaterThanCheckAmtFlag && !hasErrors) {
                let { success, data } = await handleEraProcedureSave();

                if (success == "success") {
                    saveEditProcedures(data);
                }
            } else {
                // When there are errors or warning then call open the warnings modal
                setOpenMuiDialogueForProcedure(true);
            }
        }
    }

    const handleEraRemove = (e, claimPK, claim_id) => {
        setShowDeleteModalWindow(true);
        setSelectedClaimPk(claimPK);
        setSelectedClaimId(claim_id);
    }

    const onDeleteAction = async () => {
        try {
            setShowLoadingBar(true);
            let result = await service.RemoveEraFromList(selectedClaimPk);
            if (result.status === 200) {
                let updatedData = reviewData.filter(item => item.claim_pk !== selectedClaimPk);
                //updating the state
                setReviewData([...updatedData]);
                setShowDeleteModalWindow(false);
                showNotifyWindow('show', 'success', DELETE_SUCCESS);
            } else {
                showNotifyWindow('show', 'error', 'Error deleting ERA. Please try again.');
            }

        } catch (err) {
            console.error("Error while deleting era data:", err);
            showNotifyWindow('show', 'error', 'Error deleting ERA. Please try again.');
        } finally {
            setShowLoadingBar(false);
            setShowDeleteModalWindow(false);
        }
    }

    const onDeleteHide = () => {
        setShowDeleteModalWindow(false);
    }

    const handleProcedureEditSubmitFromErrorModal = async (action) => {
        switch (action) {
            case 'continueEdit': {
                setOpenMuiDialogueForProcedure(false);
                return;
            }
            case 'saveWithOverRides': {
                setOpenMuiDialogueForProcedure(false);
                let { success, data } = await handleEraProcedureSave();

                if (success === "success") {
                    saveEditProcedures(data);
                }
                return;
            }
            default:
                break;
        }
    };


    const handleProcedureEditWindowClose = () => {
        if (isApplied) {
            setShowDetailsModal(false);
            setProcedureRowErrors([]);
            return;
        }
        if (reviewData.some(item => item.is_manually_added === true && item.claim_pk === selectedClaimID && isNewClaim)) {
            setIsManuallyAddClaimCloseConfirm(true)
        } else {
            setShowDetailsModal(false);
            setProcedureRowErrors([]);
        }
    }

    const handleIsManuallyAddClaimCloseConfirm = (isClaimShouldRemove) => {
        if (isClaimShouldRemove) {
            removeManuallyAddedClaim(selectedClaimID);
            setIsManuallyAddClaimCloseConfirm(false);
            setTimeout(() => {
                setShowDetailsModal(false);
                setProcedureRowErrors([]);
            }, 500);
        }
    }

    const handleAddClaimModalClose = () => {
        setShowAddClaimModal(false);
        setClaimsListForModalDropdown([]);
        setClaimDataForEraManualAdd({
            custom_claim_id: '', patient_name: '', dos_from: '', dos_to: '', payer_types: '', responsible_payer: ''
        });
    }

    function getRowClassName(rowItem) {
        let classNames = '';
        if (rowItem.isModified || rowItem.is_manually_added) {
            classNames += 'procedureEditedTableRow ';
        }
        if (rowItem.isReviewed) {
            classNames += 'eraItemReviewed ';
        }
        if (rowItem.claimId === lastOpenedClaimRowItem) {
            classNames += 'row-bg-change-last-opened-indication ';
        }

        return classNames.trim();
    }

    const onHandleEditModal = () => {
        if (!checkPermission(permission_key_values_payments.era_posting_edit)) {
            showNotifyWindow("show", "error", i18n.t("errorMessages.permission_error"))
            return;
        }
        setShowPayerDetailsEditModal(true);
        setEramodalEditFlag(true);
    }

    const onCancel = () => {
        setEramodalEditFlag(false);
        setEraModalEdit(eraOriginalData)
        setShowPayerDetailsEditModal(false);
    }

    const handleChangeEditEra = (e) => {
        const { name, value } = e.target;

        setEraModalEdit((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const onSaveEraModalFormData = async (e) => {
        e.preventDefault();

        try {
            if (!eraModalEdit?.era_received_date) {
                showNotifyWindow("show", "error", "Invalid Production Date");
                return;
            }

            if (!eraModalEdit?.payment_date) {
                showNotifyWindow("show", "error", "Invalid Payment Date");
                return;
            }

            setShowLoadingBar(true);

            const result = await service.UpdateERA(eraModalEdit?.payer_info?.id, {
                ...eraModalEdit,
                "era_received_date": formatDateUS(eraModalEdit.era_received_date),
                "payment_date": formatDateUS(eraModalEdit.payment_date),
            });

            if (result.status && result.status === 200) {
                showNotifyWindow("show", "success", "Updated Successfully");
                setShowPayerDetailsEditModal(false);
            } else {
                showNotifyWindow("show", "error", "Failed to update. Please try again.");
            }

        } catch (error) {
            showNotifyWindow("show", "error", "An error occurred while updating.");
            console.error("Error updating ERA:", error);
        } finally {
            setShowLoadingBar(false);
        }
    };

    const handleEraDate = (selected, name) => {
        if (selected && selected instanceof Date && !isNaN(selected)) {
            setEraModalEdit((prevState) => ({
                ...prevState,
                [name]: selected
            }));
        } else {
            setEraModalEdit((prevState) => ({
                ...prevState,
                [name]: ""
            }));
        }
    }

    return (
        <>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div style={{}}>
                <div id='fixedHeader' style={{ backgroundColor: 'white', width: '100%', marginTop: '3px' }}>
                    {/* ERA Basic details */}
                    <div className='row' style={{ marginTop: '5px', zIndex: 10000, marginLeft: 20, marginRight: 20 }}>
                        {/**making table format to show payer payee details */}
                        <table className="eraPaymentTable">
                            <tr>
                                <td>
                                    <span className='paymentTextHeading'>Payer Details</span>
                                </td>
                                <td>
                                    <span className='paymentTextHeading'>Payee Details</span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>

                                    </span>
                                </td>
                                <td>
                                    <span>

                                    </span>
                                </td>
                                <td>
                                    <span className='paymentTextGeneral'>{i18n.t('payments.eraPage.applied')}:</span>
                                    <span className='applied'> {currencyFormat(eraModalEdit && eraModalEdit.applied ? eraModalEdit.applied : '')}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='paymentTextGeneral'>
                                        {eraModalEdit && eraModalEdit.payer_info.payer_name ? eraModalEdit.payer_info.payer_name.toUpperCase() : ''}
                                    </span>
                                </td>
                                <td>
                                    <span className='paymentTextGeneral'>{eraModalEdit && eraModalEdit.payee_info.payee_name ? eraModalEdit.payee_info.payee_name.toUpperCase() : ''}</span>
                                </td>
                                <td>
                                    <span className='paymentTextGeneral'>
                                        {i18n.t('payments.eraPage.payment_level_adjustment')} :
                                    </span>
                                    <span className='adjustments' style={{ marginLeft: '23px' }}>{eraModalEdit && eraModalEdit.era_level_adjustments ? currencyFormat(eraModalEdit.era_level_adjustments) : "-"}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='paymentTextGeneral'>
                                        {eraModalEdit && eraModalEdit.payer_info.payer_address ? eraModalEdit.payer_info.payer_address.toUpperCase() : ''}
                                    </span>
                                </td>
                                <td>
                                    <span className='paymentTextGeneral'>
                                        {eraModalEdit && eraModalEdit.payee_info.payee_address
                                            ? eraModalEdit.payee_info.payee_address.toUpperCase() + ' '
                                            : ''}
                                        {eraModalEdit && eraModalEdit.payee_info.payee_address2
                                            ? eraModalEdit.payee_info.payee_address2.toUpperCase() + ' '
                                            : ''}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='paymentTextGeneral'>
                                        {eraModalEdit && eraModalEdit.payer_info.payer_address2 ? eraModalEdit.payer_info.payer_address2.toUpperCase() : ''}
                                    </span>
                                </td>
                                <td>
                                    <span></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='paymentTextGeneral'>{eraModalEdit && eraModalEdit.payer_info.payer_zip ? eraModalEdit.payer_info.payer_zip.toUpperCase() : ''}</span>
                                </td>
                                <td className='paymentTextGeneral'>
                                    <span> {eraModalEdit && eraModalEdit.payee_zip
                                        ? eraModalEdit.payee_zip.toUpperCase() + ' '
                                        : ''}</span>
                                </td>
                                <td>
                                    <span className='paymentTextGeneral'>
                                        {i18n.t('payments.eraPage.unapplied')}:
                                    </span>
                                    <span className='unapplied'>
                                        {currencyFormat(eraModalEdit && eraModalEdit.unapplied ? eraModalEdit.unapplied : '')}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td>
                                    <span className='paymentTextGeneral'>{i18n.t('payments.eraPage.adjustment_type')} : {payAdjTypeArr.length > 0 ? payAdjTypeArr.join(',') : ''}</span>
                                </td>
                            </tr>

                            <tr>

                                <td>
                                    <span className='greyText'>
                                        {i18n.t('payments.eraPage.productionDate')}:
                                    </span>
                                    <span className='production_date'>
                                        {eraModalEdit && eraModalEdit.era_received_date ? format(new Date(eraModalEdit.era_received_date), DEFAULT_DATE_FORMAT) : '-'}
                                    </span>
                                </td>
                                <td>
                                    <span className='greyText'>{i18n.t('payments.eraPage.ein')}: </span>
                                    <span className='ein_eft'>
                                        {eraModalEdit && eraModalEdit.ein ? eraModalEdit.ein : '-'}
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <span className='greyText'>{i18n.t('payments.eraPage.checkDate')}: </span>
                                    <span className='checkDate'>
                                        {eraModalEdit && eraModalEdit.payment_date ? format(new Date(eraModalEdit.payment_date), DEFAULT_DATE_FORMAT) : '-'}
                                    </span>
                                </td>
                                <td>
                                    <span className='greyText'>{i18n.t('payments.eraPage.npi')}: </span>
                                    <span className='ein_eft'>
                                        {eraModalEdit && eraModalEdit.npi ? eraModalEdit.npi : '-'}
                                    </span>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <span className='greyText'>
                                        {i18n.t('payments.eraPage.checkAmount')}:
                                    </span>
                                    <span className='checkAmount'>
                                        {currencyFormat(eraModalEdit && eraModalEdit.total_check_amount ? eraModalEdit.total_check_amount : '-')}
                                    </span>
                                </td>
                                <td></td>
                                <td>
                                    <span className='applied-status-label'>
                                        {'Status'}:
                                    </span>
                                    <span className='applied-status'>
                                        {eraModalEdit && eraModalEdit?.applied_status === 1 ?
                                            'Unapplied' : eraModalEdit?.applied_status === 2 ? 'Applied' : eraModalEdit?.applied_status === 3 ? "Partially Applied" : ""}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='greyText'>{i18n.t('payments.eraPage.eft')}:</span>
                                    <span className='ein_eft' style={{ marginLeft: '109px' }}>
                                        {eraModalEdit && eraModalEdit.payment_reference_num ? eraModalEdit.payment_reference_num : '-'}
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='mt-2 align-right mr-3'>
                        <CommonButton
                            variant="contained"
                            onClick={onHandleEditModal}
                            label={i18n.t("commons.edit")}
                        />
                    </div>
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <div className='margin-top10 margin-left20 margin-right20'>
                        <div className='row'>
                            <div className='col-8' >
                                <div style={{ marginTop: '35px' }} className='font-bold'>{i18n.t('payments.eraPage.reviewEra')}</div>
                            </div>
                            <div className='col-2 justify-right addNewClaim' style={{ paddingLeft: "30px" }}>
                                <div>

                                </div>
                                <SelectInput
                                    data={filter_Options}
                                    name="Filter Option"
                                    value={eraFilterOption?.value}
                                    onValueChange={handleFilterOption}
                                    label={'Filter by'}
                                    defaultValue=""
                                    selectOptionRemove={true}
                                    id="filter_by"
                                />
                            </div>
                            <div className='col-2 justify-content-start  filterIcon'>
                                {
                                    eraFilterOption.value !== 'dos' ?
                                        <>
                                            {/* filter Option except DOS */}
                                            <div className='row justify-content-start filterIcon' >
                                                <div className='col-12'>
                                                    <TextInput
                                                        name="era_Filter"
                                                        type='text'
                                                        value={filterQuery}
                                                        onValueChange={(e) => handleERA_Filter(e)}
                                                        label={`${eraFilterOption?.name ? eraFilterOption.name : 'Filter by'}`}
                                                        clearButton={true}
                                                        clearInput={clearFilterInput}
                                                        placeholder={`${eraFilterOption?.name ? eraFilterOption.name : 'Filter by'}`}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {/* Filter by DOS */}
                                            <div className='row justify-content-start filterIcon' >
                                                <div style={{ paddingRight: '4px' }} className='col-10'>
                                                    <CalendarInput
                                                        name="filterByDOS"
                                                        id="filterByDOS"
                                                        label={`${eraFilterOption?.name ? eraFilterOption.name.toUpperCase() : 'Filter by '}`}
                                                        selected={filterQuery}
                                                        onValueChange={(selected) => handleERA_Filter(selected)}
                                                    />
                                                </div>
                                                <div style={{ paddingRight: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center', }} className='col-2'>
                                                   {filterQuery.length ? <IconButton aria-label="filter-table" onClick={clearFilterInput} className="filter-icons">
                                                        <ClearIcon />
                                                    </IconButton> : ''
                                                   } 
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id='tableDiv'
                style={{
                    overflowX: "auto",
                    width: "100%",
                    maxWidth: "auto",
                    maxHeight: "auto",
                }}
            >
                <ResizableTable
                    className="table-fixed_head-zindex"
                    id="overflow_auto"
                    tableHeadings={

                        <tr style={{ border: '1px solid #dee2e6', zIndex: 6 }}>
                            <th className='checkbox'>
                                <div className="form-groupp lh-1">
                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{ show: 200 }}
                                        trigger='hover'
                                        overlay={
                                            <Tooltip id='tooltip' style={{ opacity: 0.7, zIndex: 10000 }}>
                                                Mark all as reviewed!
                                            </Tooltip>
                                        }
                                    >
                                        <div className="custom-checkbox">

                                            <input type="checkbox" id='checkBoxAll' name="gridCheckBox" checked={reviewData.length ? isReviewAll : false} onChange={() => handleReviewAllCheckboxClick(true)} disabled={isReviewAll} />
                                            <label className="checkbox" htmlFor='checkBoxAll'></label>

                                        </div>
                                    </OverlayTrigger>
                                </div>
                            </th>
                            {tableHeading2.map((item, index) => {
                                return (
                                    <th style={{ zIndex: 6 }} key={index}>{item}</th>
                                )
                            })}
                        </tr>
                    }
                    tableRows={

                        eraData.map((item, index) => {
                            let td_class = '';
                            td_class = item.exists_in_sys ? 'cursor-pointer' : ''
                            const uniqueArray = Array.from(new Set(item.warnings));
                            let warning = item.exists_in_sys && item.warning && !item.isHovered ? (
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ show: 200 }}
                                    overlay={
                                        <Tooltip id={`tooltip-${index}`} style={{ opacity: 0.7, zIndex: 10000 }}>
                                            {uniqueArray.map((warning, i) => <div key={i}>{warning}</div>)}
                                        </Tooltip>
                                    }
                                >
                                    <i
                                        className="fa fa-info-circle text-red"
                                        aria-hidden={true}
                                        onMouseEnter={() => {
                                            item.isHovered = true;
                                        }}
                                        onMouseLeave={() => {
                                            item.isHovered = false;
                                        }}
                                    ></i>
                                </OverlayTrigger>
                            ) : item.is_applied ? <OverlayTrigger
                                placement={'top'}
                                delay={{ show: 200 }}
                                overlay={
                                    <Tooltip id={`tooltip-${index}`} style={{ opacity: 0.7, zIndex: 10000 }}>
                                        <div>{'Already applied'}</div>
                                    </Tooltip>
                                }
                            >
                                <i
                                    className="fa fa-info-circle text-green"
                                    aria-hidden={true}
                                    onMouseEnter={() => {
                                        item.isHovered = true;
                                    }}
                                    onMouseLeave={() => {
                                        item.isHovered = false;
                                    }}
                                ></i>
                            </OverlayTrigger> : null;

                            return (
                                <tr key={index} className={getRowClassName(item)}>
                                    {/* CHECKBOX & REMOVE ICON BTN */}
                                    <td className='checkbox'>
                                        <div className="form-groupp lh-1">
                                            <OverlayTrigger
                                                placement={'top'}
                                                delay={{ show: 200 }}
                                                trigger='hover'
                                                overlay={
                                                    <Tooltip id='tooltip' style={{ opacity: 0.7, zIndex: 10000 }}>
                                                        {item.isReviewed ? 'Remove Review Indication.' : 'Mark as reviewed!'}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="custom-checkbox">
                                                    <input type="checkbox" id={`checkBox-${index}`} name="gridCheckBox" checked={item.checked ? item.checked : false} onChange={() => handleReviewCheckboxClick(item.claim_pk)} disabled={item.is_reviewed} />
                                                    <label className="checkbox" htmlFor={`checkBox-${index}`}></label>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </td>
                                    <td align='center' disabled={!item.exists_in_sys}>
                                        {item.is_reviewed ?
                                            <Chip label="Reviewed" sx={{ color: '#ffffff', backgroundColor: "#2cb83a", height: 22, fontFamily: "lato" }} /> :
                                            <Chip label="Not Reviewed" sx={{ color: '#ffffff', backgroundColor: "#fa343c", height: 22, fontFamily: "lato" }} />
                                        }
                                    </td>
                                    <td align='center' onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false, item?.is_applied) : null} className={!item.exists_in_sys ? "text-red" + " " + td_class : td_class} disabled={!item.exists_in_sys}>{`${item.claim_id}   `}{warning}
                                        <div className={'small-red-text' + " " + td_class}>{item.exists_in_sys ? '' : i18n.t('payments.eraPage.notFound')}</div>
                                    </td>
                                    <td align='center' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}> {item.patient_name.toUpperCase()} </td>
                                    <td align='center' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>{item.patient_id}</td>
                                    <td align='center' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>{YMD_TO_MDY(item.dos)}</td>
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>{currencyFormat(item.cpt_charge)}</td>
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>{currencyFormat(item.opening_balance)}</td>
                                    {/* Total Allowed */}
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>
                                        {item.isModified ?
                                            <>
                                                <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                                                    {currencyFormat(item.allowed_amount)}
                                                </span>
                                                {' '}
                                                <span>
                                                    {currencyFormat(item?.updatedTotals?.allowed_amount)}
                                                </span>
                                            </>
                                            :
                                            currencyFormat(item.allowed_amount)
                                        }
                                    </td>
                                    {/* Totatl Paid */}
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>
                                        {item.isModified ?
                                            <>
                                                <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                                                    {currencyFormat(item.cpt_payment)}
                                                </span>
                                                {' '}
                                                <span>
                                                    {currencyFormat(item?.updatedTotals?.paid_amount)}
                                                </span>
                                            </>
                                            :
                                            currencyFormat(item.cpt_payment)
                                        }
                                    </td>
                                    {/* Adjustment 1 Total */}
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>
                                        {item.isModified ?
                                            <>
                                                <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                                                    {currencyFormat(item.adj_amt_1)}
                                                </span>
                                                {' '}
                                                <span>
                                                    {currencyFormat(item?.updatedTotals?.adjustment_code1_data)}
                                                </span>
                                            </>
                                            :
                                            currencyFormat(item.adj_amt_1)
                                        }
                                    </td>
                                    {/* Adjustment 2 Total */}
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>
                                        {item.isModified ?
                                            <>
                                                <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                                                    {currencyFormat(item.adj_amt_2)}
                                                </span>
                                                {' '}
                                                <span>
                                                    {currencyFormat(item?.updatedTotals?.adjustment_code2_data)}
                                                </span>
                                            </>
                                            :
                                            currencyFormat(item.adj_amt_2)
                                        }
                                    </td>
                                    {/* Closing Balance */}
                                    <td align='right' className={td_class} onClick={item.exists_in_sys ? () => reviewClaim(selectedPaymentPK, item.claim_pk, item.is_manually_added, false) : null} disabled={!item.exists_in_sys}>
                                        {item.isModified ?
                                            <>
                                                <span style={{ textDecoration: 'line-through', fontSize: 'smaller' }}>
                                                    {currencyFormat(item.closing_balance)}
                                                </span>
                                                {' '}
                                                <span>
                                                    {currencyFormat(item?.updatedTotals?.closing_balance)}
                                                </span>
                                            </>
                                            :
                                            currencyFormat(item.closing_balance)
                                        }
                                    </td>
                                    <td>
                                        <IconButton
                                            disabled={eraAppliedStatus === 2}
                                            className="float-right"
                                            style={{ marginTop: 8, marginRight: 8 }}
                                            onClick={(e) => handleEraRemove(e, item.claim_pk, item.claim_id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>

                                    </td>
                                </tr>
                            )
                        })
                    }
                />
            </div>

            <div className='margin-top20 padding-bottom20 justify-right margin-right40'>
                {
                    checkPermission(permission_key_values_payments.payments_era_posting_add) &&
                    <div className='row'>
                        <div className='padding-left15'>
                            <CommonButton variant='outlined' onClick={backToReview} label={i18n.t('payments.eraPage.cancel')} />
                        </div>
                        <div className='padding-left15'>
                            {/* Add Claims manually to ERA */}
                            <CommonButton variant='contained' disabled={eraAppliedStatus === 2} onClick={() => MarkAsReviewed()} label={i18n.t('payments.eraPage.mark_as_reveiwed')} />
                        </div>
                        <div className='padding-left15'>
                            {/* Add Claims manually to ERA */}
                            <CommonButton variant='contained' disabled={eraAppliedStatus === 2} onClick={onAddClaimBtnClick} label={i18n.t('payments.eraPage.add_claim')} />
                        </div>
                        <div className='padding-left15'>
                            <CommonButton variant='contained' disabled={eraAppliedStatus === 2} onClick={onApplyPayment} label={isApplied ? i18n.t('commons.save') : i18n.t('payments.eraPage.apply')} />
                        </div>
                    </div>
                }
            </div>


            {/* Modal to list the Procedure within each ERA Claim */}
            <CustomizedDialogs
                modalId={'claimDetailModal'} submit={false} modalBodyId={'claimDetailBody'}
                onSaveData={() => handleProcedureEditSubmit()}
                showModal={showDetailsModal} header={i18n.t("payments.eraPage.claimDetails")}
                type={isAllProcedureApplied ? "isAlert" : !checkPermission(permission_key_values_payments.payments_view_payments_modify) ? "" : "save"} setShowModalWindow={() => { handleProcedureEditWindowClose() }}
                checkBox={claimReviewedList?.includes(selectedClaimID)} checkBoxState={checkBoxState} setCheckBoxState={setCheckBoxState}
            >
                <div className='margin-top10' id='procedureDisplayTable'>
                    <table className='table' id='overflow_auto'>
                        <tbody>
                            {procedureData?.map((item, index) => {

                                let isDisabled = (item.is_applied || item?.cpt_not_found);
                                return (
                                    <tr key={item.id ?? index}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={2}
                                        >
                                            <Grid item xs={12}>
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                >
                                                    {fields.map((field, index) => (
                                                        <Stack
                                                            key={item.id ?? index}
                                                            direction="column"
                                                            justifyContent="space-between"
                                                            alignItems="flex-start"
                                                            spacing={1}
                                                            sx={{
                                                                padding: '10px',
                                                                width: index < 6 ? '10%' : '20%'
                                                            }}
                                                        >
                                                            <InputLabel style={labelStyle}>{field.label}</InputLabel>
                                                            <Box style={{ flexGrow: 1 }} />
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Horizontal container */}
                                                                <Typography style={typographyStyle}>
                                                                    {field.key === 'date_of_service' ? CommonDateFormat(item[field.key]) : item[field.key]}
                                                                </Typography>
                                                                {/* Conditionally render InfoIcon */}
                                                                {field.key === 'cpt_code' && item.cpt_not_found && (
                                                                    <MUITooltip arrow title="Procedure not found in the system">
                                                                        <InfoIcon sx={{ color: 'red', cursor: 'help', marginLeft: '4px' }} />
                                                                    </MUITooltip>
                                                                )}
                                                            </Box>
                                                        </Stack>
                                                    ))}
                                                    <Stack
                                                        key={item.id ?? index}
                                                        direction="column"
                                                        justifyContent="space-between"
                                                        alignItems="flex-start"
                                                        spacing={1}
                                                        sx={{
                                                            padding: '10px',
                                                            width: '20%'
                                                        }}
                                                    >
                                                        <InputLabel style={labelStyle}>Allowed Amount</InputLabel>
                                                        <div style={{ border: `0.5px solid ${procedureRowErrors[index]?.allowedAmount ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                                            <Input
                                                                id="allowed-amount"
                                                                type="text"
                                                                inputMode="numeric"
                                                                pattern="[0-9]*"
                                                                step="any"
                                                                sx={{ border: 'none !important' }}
                                                                className='table-input-era-posting'
                                                                name='allowed_amount'
                                                                value={item.allowed_amount}
                                                                onChange={(e) => onHandleApply(e, index, 'allowed_amount')}
                                                                onBlur={() => onBlurApply("allowed_amount", index)}
                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                endAdornment={
                                                                    procedureRowErrors[index]?.allowedAmount ?
                                                                        <InputAdornment position="end">
                                                                            <MUITooltip title={procedureRowErrors[index]?.allowedAmount} placement="top">
                                                                                <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                                            </MUITooltip>
                                                                        </InputAdornment>
                                                                        : null
                                                                }
                                                                disabled={isDisabled}
                                                            />
                                                        </div>
                                                    </Stack>


                                                    <Stack
                                                        key={item.id ?? index}
                                                        direction="column"
                                                        justifyContent="space-between"
                                                        alignItems="flex-start"
                                                        spacing={1}
                                                        sx={{
                                                            padding: '10px',
                                                            width: '20%'
                                                        }}
                                                    >
                                                        <InputLabel style={labelStyle}>Paid Amount</InputLabel>
                                                        <div style={{ border: `0.5px solid ${procedureRowErrors[index]?.paidAmount ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                                            <Input
                                                                id="paid_amount"
                                                                type="text"
                                                                inputMode="numeric"
                                                                pattern="[0-9]*"
                                                                step="any"
                                                                sx={{ border: 'none !important' }}
                                                                className='table-input-era-posting'
                                                                name='paid_amount'
                                                                value={item.paid_amount}
                                                                onChange={(e) => onHandleApply(e, index, 'paid_amount')}
                                                                onBlur={() => onBlurApply("paid_amount", index)}
                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                endAdornment={
                                                                    procedureRowErrors[index]?.paidAmount ?
                                                                        <InputAdornment position="end">
                                                                            <MUITooltip title={procedureRowErrors[index]?.paidAmount} placement="top">
                                                                                <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                                            </MUITooltip>
                                                                        </InputAdornment>
                                                                        : null
                                                                }
                                                                disabled={isDisabled}
                                                            />
                                                        </div>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="horizontal" variant="middle" />


                                        {/* ADJUSTMENT 1 CODE SET */}
                                        <Grid item xs={12}>
                                            <Stack
                                                key={item.id ?? index}
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={1}
                                            >
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Adjustment-1 Codes</InputLabel>
                                                    <Stack key={item.id ?? index} direction="column" spacing={.5}>
                                                        {item?.adjustment_code1_data?.map((item1, index2) => {
                                                            return (
                                                                <Stack key={index2} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px', width: '100%' }}>
                                                                    <Chip
                                                                        label={item1.label ?? item1.name.substring(0, 7)}
                                                                        onDelete={() => {
                                                                            handleAdjustmentCodeChange('', index, 'remove', index2, 'adjustment_code1_data');
                                                                        }}
                                                                        disabled={isDisabled}
                                                                        sx={{
                                                                            padding: '3px',
                                                                            fontSize: '1rem',
                                                                            borderRadius: '7px',
                                                                            border: '0.5px solid #ced4da',
                                                                            height: '16px',
                                                                            width: '100%',
                                                                            '& .MuiChip-deleteIcon': {
                                                                                fontSize: '1.2rem', // Set the font size of the delete icon to 80% of the chip font size
                                                                                '&:hover': {
                                                                                    color: '#1074B9' // Set the color of the delete icon when hovering over it
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            )
                                                        })}
                                                    </Stack>
                                                    <Stack key={item.id ?? index} sx={{ width: '100%', marginTop: item?.adjustment_code1_data?.length ? '8px' : '0 !important' }}>
                                                        <div style={{ width: '100%' }}>
                                                            <AsyncTypeahead
                                                                id="search_adjustment_1_codes"
                                                                ref={(el) => typeHeadAdjustment1Ref.current[index] = el}
                                                                labelKey="name"
                                                                minLength={0}
                                                                options={adjustment_1_codes_list}
                                                                onSearch={onSearchAdjustmentReason_1_code}
                                                                name="selectClaim"
                                                                onFocus={() => {
                                                                    handleModalHeightOnAdjustmentInputBoxFocus("onFocus", index);
                                                                }}
                                                                onBlur={() => {
                                                                    handleModalHeightOnAdjustmentInputBoxFocus();
                                                                }}
                                                                // className={item.adjustment_amount1 && parseFloat(item.adjustment_amount1) > 0 && item.adjustment_code1_data.length === 0 ? "input-error" : ""}
                                                                maxResults={maxResults}
                                                                onPaginate={() => setMaxResults(maxResults + 5)}
                                                                paginationText={"Load More"}
                                                                clearButton={true}
                                                                onChange={(e) => { handleAdjustmentCodeChange(e, index, null, null, 'adjustment_code1_data'); }}
                                                                disabled={isDisabled}
                                                                renderMenuItemChildren={(option) => {
                                                                    let description;
                                                                    let isDescriptionTooLong;
                                                                    if (Object.prototype.hasOwnProperty.call(option, "description")) {
                                                                        description = option.description;
                                                                        isDescriptionTooLong = description.length > 40;
                                                                    }
                                                                    return (
                                                                        <div style={{ zIndex: 10000, overflowY: 'visible !important' }}>
                                                                            {description ? (
                                                                                <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                                                    <Tooltip id={`tooltip-right`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                                                        {description}
                                                                                    </Tooltip>
                                                                                } >
                                                                                    <div value={option.label} >
                                                                                        {option.label && <strong>{`${option.label}  `}</strong>}
                                                                                        <span>
                                                                                            {isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`}
                                                                                        </span>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                <div value={option.label} >
                                                                                    {option.label && <strong>{`${option.label}  `}</strong>}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </Stack>
                                                </Stack>
                                                {/* ADJUSTMENT 1 CODE AMOUNTS */}
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Adjustment-1 Amt</InputLabel>
                                                    <Stack key={item.id ?? index} sx={{ width: '100%' }}>
                                                        <Stack key={item.id ?? index} direction="column" spacing={.5} alignItems="flex-start" >
                                                            {item?.adjustment_code1_data?.map((adjstmntCode1) => {
                                                                return (
                                                                    <Stack key={adjstmntCode1.id} sx={{ border: `0.5px solid ${procedureRowErrors[index]?.adjustment_amount1?.[adjstmntCode1.id] ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', padding: '2px' }}>
                                                                        <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                                            <Tooltip id={`tooltip-index`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                                                {adjstmntCode1.label}
                                                                            </Tooltip>
                                                                        } >
                                                                            <Input
                                                                                sx={{
                                                                                    padding: '3px',
                                                                                    fontSize: '1rem',
                                                                                    height: '16px',
                                                                                    width: '100%',
                                                                                }}
                                                                                name='adjustment_amount1'
                                                                                id="standard-adornment-amount"
                                                                                type="text"
                                                                                inputMode="numeric"
                                                                                pattern="[0-9]*"
                                                                                step="any"
                                                                                value={adjstmntCode1.amount}
                                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                onChange={(e) => { handleAdjustmentAmountChange(e, adjstmntCode1, index, 'adjustment_code1_data') }}
                                                                                onBlur={() => onBlurApply("adjustment_amount1", index, adjstmntCode1.id)}
                                                                                endAdornment={
                                                                                    procedureRowErrors[index]?.adjustment_amount1?.[adjstmntCode1.id] ?
                                                                                        <InputAdornment position="end">
                                                                                            <MUITooltip title={procedureRowErrors[index]?.adjustment_amount1?.[adjstmntCode1.id].message} placement="top">
                                                                                                <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                                                            </MUITooltip>
                                                                                        </InputAdornment>
                                                                                        : null
                                                                                }
                                                                                disabled={isDisabled}
                                                                            />
                                                                        </OverlayTrigger>
                                                                    </Stack>
                                                                )
                                                            })}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>

                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Adjustment-2 Codes</InputLabel>
                                                    <Stack key={item.id ?? index} direction="column" spacing={.5}>
                                                        {item?.adjustment_code2_data?.map((item1, code2Index) => {
                                                            return (
                                                                <Stack key={code2Index} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px' }}>
                                                                    <Chip
                                                                        label={item1.label ?? item1.name.substring(0, 7)}
                                                                        onDelete={() => {
                                                                            handleAdjustmentCodeChange(item1, index, 'remove', code2Index, 'adjustment_code2_data');
                                                                        }}
                                                                        disabled={isDisabled}
                                                                        sx={{
                                                                            padding: '3px',
                                                                            fontSize: '1rem',
                                                                            borderRadius: '7px',
                                                                            border: '0.5px solid #ced4da',
                                                                            height: '16px',
                                                                            width: '100%',
                                                                            '& .MuiChip-deleteIcon': {
                                                                                fontSize: '1.2rem', // Set the font size of the delete icon to 80% of the chip font size
                                                                                '&:hover': {
                                                                                    color: '#1074B9' // Set the color of the delete icon when hovering over it
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            )
                                                        })}
                                                    </Stack>
                                                    <Stack key={item.id ?? index} sx={{ width: '100%', marginTop: item?.adjustment_code2_data?.length ? '8px' : '0 !important' }}>
                                                        <div style={{ width: '100%' }}>
                                                            <AsyncTypeahead
                                                                id="search_adjustment_2_codes"
                                                                ref={(el) => typeHeadAdjustment2Ref.current[index] = el}
                                                                labelKey="name"
                                                                minLength={0}
                                                                options={adjustment_2_codes_list}
                                                                onSearch={onSearchAdjustmentReason_2_code}
                                                                name="search_adjustment_2_codes"
                                                                onFocus={() => {
                                                                    handleModalHeightOnAdjustmentInputBoxFocus("onFocus", index);
                                                                }}
                                                                style={{ backgroundColor: 'transparent' }}
                                                                onBlur={() => {
                                                                    handleModalHeightOnAdjustmentInputBoxFocus();
                                                                }}
                                                                // className={item.adjustment_amount1 && parseFloat(item.adjustment_amount1) > 0 && item.adjustment_code1_data.length === 0 ? "input-error" : ""}
                                                                maxResults={maxResults}
                                                                onPaginate={() => setMaxResults(maxResults + 5)}
                                                                paginationText={"Load More"}
                                                                clearButton={true}
                                                                onChange={(e) => { handleAdjustmentCodeChange(e, index, null, null, 'adjustment_code2_data'); }}
                                                                renderMenuItemChildren={(option) => {
                                                                    let description;
                                                                    let isDescriptionTooLong;
                                                                    if (Object.prototype.hasOwnProperty.call(option, "description")) {
                                                                        description = option.description;
                                                                        isDescriptionTooLong = description.length > 40;
                                                                    }
                                                                    return (
                                                                        <div style={{ zIndex: 10000, overflowY: 'visible !important' }}>
                                                                            {description ? (
                                                                                <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                                                    <Tooltip id={`tooltip-right`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                                                        {description}
                                                                                    </Tooltip>
                                                                                } >
                                                                                    <div value={option.label} >
                                                                                        {option.label && <strong>{`${option.label}  `}</strong>}
                                                                                        <span>
                                                                                            {isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`}
                                                                                        </span>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                <div value={option.label} >
                                                                                    {option.label && <strong>{`${option.label}  `}</strong>}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }}
                                                                disabled={isDisabled}
                                                            />
                                                        </div>
                                                    </Stack>
                                                </Stack>
                                                {/* ADJUSTMENT 1 CODE AMOUNTS */}
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Adjustment-2 Amt</InputLabel>
                                                    <Stack key={item.id ?? index} sx={{ width: '100%' }}>
                                                        <Stack key={item.id ?? index} direction="column" spacing={.5} alignItems="flex-start" >
                                                            {item?.adjustment_code2_data?.map((adjstmntCode2) => {
                                                                return (
                                                                    <Stack key={adjstmntCode2.id} sx={{ border: `0.5px solid ${procedureRowErrors[index]?.adjustment_amount2?.[adjstmntCode2.id] ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', padding: '2px' }}>
                                                                        <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                                            <Tooltip id={`tooltip-index`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                                                {adjstmntCode2.label}
                                                                            </Tooltip>
                                                                        } >
                                                                            <Input
                                                                                sx={{
                                                                                    padding: '3px',
                                                                                    fontSize: '1rem',
                                                                                    height: '16px',
                                                                                    width: '100%',
                                                                                }}
                                                                                id="standard-adornment-amount"
                                                                                type="text"
                                                                                inputMode="numeric"
                                                                                name='adjustment_amount2'
                                                                                pattern="[0-9]*"
                                                                                step="any"
                                                                                value={adjstmntCode2.amount}
                                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                                onChange={(e) => { handleAdjustmentAmountChange(e, adjstmntCode2, index, 'adjustment_code2_data') }}
                                                                                onBlur={() => onBlurApply("adjustment_amount2", index, adjstmntCode2.id)}
                                                                                endAdornment={
                                                                                    procedureRowErrors[index]?.adjustment_amount2?.[adjstmntCode2.id] ?
                                                                                        <InputAdornment position="end">
                                                                                            <MUITooltip title={procedureRowErrors[index]?.adjustment_amount2[adjstmntCode2.id]?.message || procedureRowErrors[index]?.adjustment_amount2?.message} placement="top">
                                                                                                <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                                                            </MUITooltip>
                                                                                        </InputAdornment>
                                                                                        : null
                                                                                }
                                                                                disabled={isDisabled}
                                                                            />
                                                                        </OverlayTrigger>
                                                                    </Stack>
                                                                )
                                                            })}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Copay</InputLabel>
                                                    <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                                        <Input
                                                            id="copay"
                                                            type="text"
                                                            inputMode="numeric"
                                                            pattern="[0-9]*"
                                                            step="any"
                                                            sx={{ border: 'none !important' }}
                                                            className='table-input-era-posting'
                                                            name='copay'
                                                            value={
                                                                procedureData &&
                                                                    Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                                                    procedureData[index].copay !== undefined
                                                                    ? procedureData[index].copay
                                                                    : "0.00"
                                                            }
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            disabled={isDisabled}
                                                        />
                                                    </div>
                                                </Stack>

                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%' // 20% width for the last two stacks
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Co-Insurance</InputLabel>
                                                    <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                                        <Input
                                                            id="coInsurance"
                                                            type="text"
                                                            inputMode="numeric"
                                                            pattern="[0-9]*"
                                                            step="any"
                                                            sx={{ border: 'none !important' }}
                                                            className='table-input-era-posting'
                                                            name="co_insurance"
                                                            value={
                                                                procedureData &&
                                                                    Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                                                    procedureData[index].coInsurance !== undefined
                                                                    ? procedureData[index].coInsurance
                                                                    : "0.00"
                                                            }
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            disabled={isDisabled}
                                                        />
                                                    </div>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Divider orientation="horizontal" variant="middle" />


                                        <Grid item xs={12}>
                                            <Stack
                                                key={item.id ?? index}
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={1}
                                            >
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%' // 20% width for the last two stacks
                                                    }}
                                                >
                                                    {/* DEDUCTIBLE */}
                                                    <InputLabel style={labelStyle}>Deductible</InputLabel>
                                                    <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center', width: "130px" }}>
                                                        <Input
                                                            id="deductible"
                                                            type="text"
                                                            inputMode="numeric"
                                                            pattern="[0-9]*"
                                                            step="any"
                                                            sx={{ border: 'none !important' }}
                                                            className='table-input-era-posting'
                                                            name="deductibles"
                                                            value={
                                                                procedureData &&
                                                                    Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                                                    procedureData[index].deductible !== undefined
                                                                    ? procedureData[index].deductible
                                                                    : "0.00"
                                                            }
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            disabled={isDisabled}
                                                        />
                                                    </div>
                                                </Stack>

                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%' // 20% width for the last two stacks
                                                    }}
                                                >
                                                    {/* REMARK-CODES */}
                                                    <InputLabel style={labelStyle}>Remark Codes</InputLabel>
                                                    <div style={{ marginTop: '3px' }}>
                                                        <RemitanceCodeMultiSelect
                                                            key={procedureData[index]?.id}
                                                            name={"labelRemarkCodes"}
                                                            minWidth={'100px'}
                                                            onValueChange={(e) => onHandleMultiSelectSearchForRemarkCodes(e, index)}
                                                            selectedValues={procedureData[index]?.selectedRemarkCodes || []}
                                                            disabled={isDisabled}
                                                        />
                                                    </div>
                                                </Stack>

                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%' // Conditional width based on index
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Closing Balance</InputLabel>
                                                    <Box style={{ flexGrow: 1 }} />
                                                    <Typography style={typographyStyle}>{parseFloat(item.closing_balance).toFixed(2)}</Typography>
                                                </Stack>

                                                {/* RESPONSIBILITY */}
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%' // 20% width for the last two stacks
                                                    }}
                                                >

                                                    <InputLabel style={labelStyle}>Responsibility</InputLabel>
                                                    <div style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                        <SelectInput background={'transparent !important'}
                                                            required={true}
                                                            disabled={isDisabled}
                                                            data={responsibilityTypes}
                                                            value={item.responsibility_type}
                                                            onValueChange={(e) => onHandleApply(e, index, 'responsibility_type')}
                                                        />
                                                    </div>
                                                </Stack>

                                                {/* CLAIM STATUS */}
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'  //width for the last two stacks
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Claim Status</InputLabel>
                                                    <div style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                        <SelectInput background={'transparent !important'}
                                                            className={!item.claim_status_id ? "input-error" : ''}
                                                            disabled={isDisabled}
                                                            required={true}
                                                            data={claimStatusList?.filter(data => data.priorities?.includes(item?.responsibility_type))}
                                                            name={'claim_status_id'}
                                                            value={item.claim_status_id}
                                                            onValueChange={(e) => onHandleApply(e, index, 'claim_status_id')}
                                                        />
                                                    </div>
                                                </Stack>

                                                {/* CLAIM SUB STATUS */}
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '20%'  // 20% width for the last two stacks
                                                    }}
                                                >
                                                    <InputLabel style={labelStyle}>Claim Sub Status</InputLabel>
                                                    <div style={{ minWidth: '120px', maxWidth: '120px' }}>
                                                        <SelectInput background={'transparent !important'}
                                                            disabled={isDisabled}
                                                            required={true}
                                                            className={claimSubStatusList.filter(data => data.parent_claim_status_ids.includes(item.claim_status_id)).length && !item.claim_sub_status_id ? "input-error" : ''}
                                                            data={claimSubStatusList.filter(data => data.parent_claim_status_ids.includes(item.claim_status_id))}
                                                            name={'claim_sub_status_id'}
                                                            value={item.claim_sub_status_id}
                                                            onValueChange={(e) => onHandleApply(e, index, 'claim_sub_status_id')}
                                                        />
                                                    </div>
                                                </Stack>

                                                {/* RESET */}
                                                <Stack
                                                    key={item.id ?? index}
                                                    direction="column"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    spacing={1}
                                                    sx={{
                                                        padding: '10px',
                                                        width: '40%',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <Box flexGrow={2} />
                                                    <button className='advancedSearch' onClick={() => resetProcedurePostingInputs(index)} style={{ border: 'none', backgroundColor: 'transparent', marginTop: 'auto' }}>{i18n.t('payments.eraPage.reset')}</button>

                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                {/* Modal to show errors & warnings */}
                <Dialog open={openMuiDialogueForProcedure} onClose={() => setOpenMuiDialogueForProcedure(false)} PaperProps={{ sx: { width: "100%" } }} BackdropProps={{ style: { display: 'none' } }} fullWidth maxWidth="md">
                    <DialogTitle sx={{ backgroundColor: '#1479BB', color: 'white' }}>
                        <div style={{ padding: '5px', }}>
                            Warnings & Errors
                        </div>
                    </DialogTitle>
                    <DialogContent>

                        <div style={{ padding: '15px' }}>
                            <div style={{
                                display: 'flex',
                                padding: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                borderRadius: '4px 4px 0px 0px',
                                background: '#FFD6CA',
                            }}>
                                <ErrorOutlineIcon />
                                <Typography variant='h5'>Errors</Typography>
                            </div>
                            {isTotalPaidAmntGreaterThanCheckAmt &&
                                <div style={{
                                    display: 'flex',
                                    padding: '10px',
                                    gap: '10px',
                                    borderRadius: '4px 4px 0px 0px',
                                    background: '#f2d2c9'
                                }}>
                                    <FiberManualRecordIcon />
                                    <Typography variant='subtitle1'>Total Paid amount is greater than check amount. Please resolve the error to continue.</Typography>
                                </div>
                            }

                            <div style={{
                                display: 'flex',
                                padding: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                borderRadius: '4px 4px 0px 0px',
                                background: '#FFF2CA',
                                marginTop: '10px'
                            }}>
                                <WarningAmberRoundedIcon />
                                <Typography variant='h5'>Warnings</Typography>
                            </div>
                            {
                                Array.isArray(procedureRowErrors) && procedureRowErrors.length > 0 &&
                                procedureRowErrors.map((rowItem, index) => {
                                    if (rowItem && Object.keys(rowItem).length !== 0) {
                                        return (
                                            <div key={`${index}`}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: '10px',
                                                    justifyContent: 'center',
                                                    alignItems: 'start',
                                                    gap: '10px',
                                                    borderRadius: '4px 4px 0px 0px',
                                                    background: '#FFF6DC',
                                                    borderBottom: '1px solid #F6E8BE'
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    fontWeight={'bold'}
                                                >
                                                    {procedureData?.[index]?.cpt_code}
                                                </Typography>
                                                {
                                                    rowItem && Object.keys(rowItem).map((key, errorIndex) => {
                                                        const error = rowItem[key];
                                                        if (typeof error === 'string') {
                                                            return <Typography
                                                                sx={{ marginLeft: '5px' }}
                                                                key={`${key}${errorIndex}`}
                                                                variant={'body1'}
                                                            > {error}
                                                            </Typography>
                                                        } else if (error) {
                                                            return Object.keys(error).map((subKey, subIndex) => {
                                                                return <Typography
                                                                    sx={{ marginLeft: '5px' }}
                                                                    key={`${subKey}${subIndex}`}
                                                                    variant={'body1'}
                                                                > {`${error[subKey]?.label}: ${error[subKey]?.message}`}
                                                                </Typography>
                                                            });
                                                        }
                                                    })
                                                }
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </div>
                    </DialogContent>
                    <div style={{ width: "100%", height: "1px", margin: "10px 0", backgroundColor: "#ccc" }} />
                    <DialogActions>
                        {
                            isTotalPaidAmntGreaterThanCheckAmt &&
                            <button onClick={() => { handleProcedureEditSubmitFromErrorModal('continueEdit') }} className="btn btn-secondary px-4 float-right btn-common-height35 margin-right10 ">Edit & Fix Error</button>
                        }
                        {
                            !isTotalPaidAmntGreaterThanCheckAmt && (
                                <>
                                    <button onClick={() => { handleProcedureEditSubmitFromErrorModal('continueEdit') }} className="btn btn-secondary px-4 float-right btn-common-height35 margin-right10 ">Edit & Fix Warnings</button>
                                    <button onClick={() => { handleProcedureEditSubmitFromErrorModal('saveWithOverRides') }} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">Save with Overrides</button>
                                </>
                            )
                        }
                    </DialogActions>
                </Dialog>

                {/* Modal to confirm manually added claim close */}
                <Dialog
                    open={isManuallyAddClaimCloseConfirm}
                    onClose={() => { setIsManuallyAddClaimCloseConfirm(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Important Message"}</DialogTitle>
                    <DialogContent>
                        {!isNewClaim ?
                            <DialogContentText id="alert-dialog-description">
                                {`Please clarify if you want to continue or not`}
                            </DialogContentText>
                            :
                            <DialogContentText id="alert-dialog-description">
                                {`Please ensure you've made necessary changes before closing. If you don't need this claim, you may also remove it from the ERA. Thank you!`}
                            </DialogContentText>
                        }
                    </DialogContent>
                    {!isNewClaim ?
                        <DialogActions>
                            <>
                                <button onClick={() => { setIsManuallyAddClaimCloseConfirm(false) }} className="btn btn-secondary px-4 float-right btn-common-height35 margin-right10 ">Cancel</button>
                                <button onClick={() => { setIsManuallyAddClaimCloseConfirm(false) }} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">Continue Edit</button>
                            </>
                        </DialogActions> :
                        <DialogActions>
                            <>
                                <button onClick={() => { handleIsManuallyAddClaimCloseConfirm('removeClaim') }} className="btn btn-secondary px-4 float-right btn-common-height35 margin-right10 ">Remove Added Claim</button>
                                <button onClick={() => { setIsManuallyAddClaimCloseConfirm(false) }} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">Continue Edit</button>
                            </>
                        </DialogActions>}
                </Dialog>
            </CustomizedDialogs>


            {/* Modal to Add and list Claims manually to ERA Table */}
            <CustomizedDialogs isNoSaveCancel={true} header={'Add Claims to ERA'} type="save" showModal={showAddClaimModal} setShowModalWindow={() => { handleAddClaimModalClose() }}>

                <div className='row' style={{ height: '80px', borderRadius: '5px', margin: '5px', border: '1px solid #dddd', backgroundColor: '#fbfbfb' }}>
                    <div className="col-md-3" style={{ marginTop: 7 }}>
                        <Label label={'Search Claim by ID'} />
                        <AsyncTypeInput
                            id="searchByClaimId"
                            labelKey="name"
                            minLength={0}
                            options={claimsListForModalDropdown}
                            onSearch={searchClaimByID}
                            name="searchByClaimId"
                            onValueChange={selectClaimFromDropdown}
                            isLoading={isAsyncTypeSearching}
                        />
                    </div>
                    <div className="col-md-9">
                    </div>
                </div>

                <div className='row' style={{ minHeight: '180px', padding: '15px' }}>
                    <div className="col-md-12">
                        <table style={{ marginTop: '5px' }}>
                            <thead>
                                <tr>
                                    {manualAddClaimTableHead1.map((item, index) => (
                                        <th key={index}>{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {manuallyAddedClaims.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td align='center' >{item.claim_id}
                                            </td>
                                            <td align='center'>
                                                <Link to='#' className='advancedSearch' onClick={() => onPatientNameClick(item.patient_pk)}>{item.patient_name}</Link></td>
                                            <td align='center'><Link to='#' className='advancedSearch' onClick={() => onPatientNameClick(item.patient_pk)}>{item.patient_id}</Link></td>
                                            <td align='center'> {YMD_TO_MDY(item.dos)} </td>
                                            <td align='right' > {currencyFormat(item.cpt_charge)}</td>
                                            <td align='right' >{currencyFormat(item.opening_balance)}</td>
                                            <td align='right' >{currencyFormat(item.allowed_amount)}</td>
                                            <td align='right' >{currencyFormat(item.cpt_payment)}</td>
                                            <td align='right' >{currencyFormat(item.closing_balance)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Dialog open={openMuiDialogue} onClose={handleMuiDialogueClose} PaperProps={{ sx: { width: "150%" } }} BackdropProps={{ style: { display: 'none' } }} fullWidth maxWidth="md">
                    <DialogTitle sx={{ backgroundColor: '#1C3664', color: 'white' }}>
                        <div style={{ height: '25px', padding: '5px', }}>
                            {i18n.t('payments.eraPage.addClaimConfirmation')}
                        </div>
                    </DialogTitle>
                    <DialogContent>

                        <table style={{ marginTop: '15px' }}>
                            <thead>
                                <tr>
                                    {manualAddClaimTableHead2.map((item, index) => (
                                        <th key={index}>{item}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    {loading ? ( // loading skeleton
                                        <>
                                            {[...Array(7)].map((_, i) => (
                                                <td key={i} style={{ textAlign: "center", padding: "4px" }}>
                                                    <Skeleton variant="h3" />
                                                </td>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <td style={{ textAlign: "center", padding: '4px' }}>
                                                {claimDataForEraManualAdd?.custom_claim_id}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {claimDataForEraManualAdd?.patient_name?.toUpperCase() || ''}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {claimDataForEraManualAdd.dos_from.slice(-10)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {currencyFormat(claimDataForEraManualAdd.charges)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {currencyFormat(claimDataForEraManualAdd.balance)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {claimDataForEraManualAdd?.payer_types?.toUpperCase() || ''}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {(claimDataForEraManualAdd?.responsible_payer?.name || claimDataForEraManualAdd?.responsible_payer)?.toUpperCase() || ''}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            </tbody>
                        </table>

                    </DialogContent>
                    <div style={{ width: "100%", height: "1px", margin: "10px 0", backgroundColor: "#ccc" }} />
                    <DialogActions>
                        <button onClick={handleMuiDialogueClose} className="btn btn-secondary px-4 float-right btn-common-height35 margin-right10 ">{i18n.t('buttons.cancel')}</button>
                        <button onClick={addSelectedClaimToERA} className="btn btn-primary-blue float-right btn-common-height35 margin-right10">{i18n.t('buttons.add')}</button>
                    </DialogActions>
                </Dialog>
            </CustomizedDialogs>

            <CustomizedDialogs
                header={"Edit payer details"}
                type="save"
                showModal={showPayerDetailsEditModal}
                setShowModalWindow={setShowPayerDetailsEditModal}
                onHide={onCancel}
            >
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={onSaveEraModalFormData}
                >

                    <table className="eraPaymentTable">
                        {/* 1st row */}
                        <tr>
                            <td>
                                <span className='paymentTextHeading'>Payer Details</span>
                            </td>
                            <td>
                                <span className='paymentTextHeading'>Payee Details</span>
                            </td>
                            <td>
                                <span></span>
                            </td>
                        </tr>
                        {/* 2nd row */}
                        <tr>
                            <td >
                                <div className='mt-3'>
                                    {(eraModalEdit && eraModalEdit.payer_info?.payer_name) ? eraModalEdit.payer_info?.payer_name.toUpperCase() : ''}
                                </div>
                            </td>
                            <td>
                                <div className='mt-3'>{eraModalEdit && eraModalEdit.payee_info?.payee_name ? eraModalEdit.payee_info?.payee_name.toUpperCase() : ''}</div>
                            </td>
                            <td><span></span></td>
                        </tr>
                        {/* 3rd row */}
                        <tr>
                            <td>
                                <span>
                                    {eraModalEdit && eraModalEdit?.payer_info.payer_address ? eraModalEdit?.payer_info.payer_address.toUpperCase() : ''}
                                </span>
                            </td>
                            <td>
                                <span className='paymentTextGeneral'>
                                    {eraModalEdit && eraModalEdit.
                                        payee_info.payee_address
                                        ? eraModalEdit.
                                            payee_info.payee_address.toUpperCase() + ' '
                                        : ''}
                                    {eraModalEdit && eraModalEdit.
                                        payee_info.payee_address2
                                        ? eraModalEdit.
                                            payee_info.payee_address2.toUpperCase() + ' '
                                        : ''}
                                </span>
                            </td>
                        </tr>
                        {/* 4th */}
                        <tr>
                            <td>
                                <span className='paymentTextGeneral'>{eraModalEdit && eraModalEdit?.payer_info
                                    .payer_city ? eraModalEdit?.payer_info
                                        ?.payer_city.toUpperCase() : ''}</span>
                            </td>
                            <td className='paymentTextGeneral'>
                                <span> {eraModalEdit && eraModalEdit?.
                                    payee_info.payee_city

                                    ? eraModalEdit.
                                        payee_info
                                        ?.payee_city.toUpperCase() + ' '
                                    : ''}</span>
                            </td>
                            <td><span></span></td>
                        </tr>
                        {/* 5th */}
                        <tr>
                            <td>
                                <span className='paymentTextGeneral'>
                                    {eraModalEdit && eraModalEdit?.payer_info?.payer_address2 ? eraModalEdit?.payer_info?.payer_address2.toUpperCase() : ''}
                                </span>
                            </td>
                            <td>
                                <span></span>
                            </td>
                        </tr>
                        {/* 6th */}
                        <tr>
                            <td>
                                <div className='mt-2'>
                                    <span className='greyText'>{i18n.t('payments.eraPage.productionDate')}: </span>
                                    <span >
                                        <CalendarInput required={true} name="era_received_date" selected={eraModalEdit?.era_received_date} onValueChange={(selected) => handleEraDate(selected, "era_received_date")} />
                                    </span>
                                </div>
                            </td>
                            <td style={{ padding: "10px" }}>
                                <span className='greyText'>{i18n.t('payments.eraPage.ein')}: </span>
                                <span className='ein_eft'>
                                    <TextInput type="text" name="ein" id="ein" value={eraModalEdit?.ein} onValueChange={handleChangeEditEra} maxLength={10} disabled={eramodalEditFlag} />
                                </span>
                            </td>
                            <td>
                                <span className='greyText'>
                                    {i18n.t('payments.eraPage.unapplied')}:
                                </span>
                                <span className='unapplied'>
                                    <TextInput type="text" name="unapplied" id="unapplied" value={eraModalEdit?.unapplied} onValueChange={handleChangeEditEra} disabled={eramodalEditFlag} />
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <div className='mt-2'>
                                    <span className='greyText'>{i18n.t('payments.eraPage.checkDate')}: </span>
                                    <span className='checkDate'>
                                        <CalendarInput required={true} name="payment_date" selected={formatDateUS(eraModalEdit?.payment_date)} onValueChange={(selected) => handleEraDate(selected, "payment_date")} />
                                    </span>
                                </div>
                            </td>
                            <td style={{ padding: "10px" }}>
                                <span className='greyText'>{i18n.t('payments.eraPage.npi')}: </span>
                                <span className='ein_eft'>
                                    <TextInput type="text" name="npi" id="npi" value={eraModalEdit?.npi} onValueChange={handleChangeEditEra} disabled={eramodalEditFlag} />
                                </span>
                            </td>
                            <td>
                                <span className='greyText'>{i18n.t('payments.eraPage.adjustment_type')} : </span>
                                <span className='paymentTextGeneral'>
                                    <TextInput type="text" name="adjustment_type" id="adjustment_type" value={eraModalEdit?.era_adjustment_type} onValueChange={handleChangeEditEra} disabled={eramodalEditFlag} />
                                </span>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <span className='greyText'>
                                    {i18n.t('payments.eraPage.checkAmount')}:
                                </span>
                                <span className='checkAmount'>
                                    <TextInput required={true} type="text" name="total_check_amount" id="total_check_amount" value={eraModalEdit?.total_check_amount}
                                        onValueChange={handleChangeEditEra} />
                                </span>
                            </td>

                            <td style={{ padding: "10px" }}>
                                <span className='greyText'>{i18n.t('payments.eraPage.applied')}:</span>
                                <span className='applied'>
                                    <TextInput type="text" name="applied" id="applied" value={eraModalEdit?.applied ?? 0} onValueChange={handleChangeEditEra} disabled={eramodalEditFlag} />
                                </span>
                            </td>
                            <td>
                                <span className='greyText'>
                                    {'Status'}:
                                </span>
                                <span className='applied-status'>
                                    <SelectInput name="applied_status" data={ERASTATUS} value={eraModalEdit?.applied_status || ''} onValueChange={handleChangeEditEra} disabled={eramodalEditFlag} />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className='greyText'>{i18n.t('payments.eraPage.eft')}:</span>
                                <span className='ein_eft' >
                                    <TextInput required={true} type="text" name="payment_reference_num" id="payment_reference_num" value={eraModalEdit?.payment_reference_num} onValueChange={handleChangeEditEra} />
                                </span>
                            </td>
                            <td style={{ padding: "10px" }}>
                                <span className='greyText'>
                                    {i18n.t('payments.eraPage.payment_level_adjustment')} :
                                </span>
                                <span className='adjustments'>
                                    <span>
                                        <TextInput type="text" name="era_level_adjustments" id="era_level_adjustments" value={eraModalEdit?.era_level_adjustments ?? 0} onValueChange={handleChangeEditEra} disabled={eramodalEditFlag} />
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </table>
                </Form >
            </CustomizedDialogs >

            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete"
                deleteItem={onDeleteAction}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t('payments.eraPage.eraDeleteConfirmation') + "   " + selectedClaimId + '?'}
            </CustomizedSmallDialogs>

        </>
    )
}

export default ERAApply